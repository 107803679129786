<template>
  <div>
    <v-dialog
      v-model="dialoginterest"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click.stop="SearchClose()">
            <v-icon>{{ $vars.V("icon_back") }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ dialog_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="AddData">
            Add
            <v-icon>{{ $vars.V("icon_add") }}</v-icon>
          </v-btn>
          &nbsp;
          <v-btn text @click="ConfirmData">
            Save
            <v-icon>{{ $vars.V("icon_save") }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-main grid-list-md class="pa-10">
          <v-form
            enctype="multipart/form-data"
            ref="form_policydeductible"
            lazy-validation
          >
            <v-layout row wrap>
              <!-- Interest -->

              <v-col cols="12" sm="6" md="6">
                <app-cb
                  cb_type="deductible"
                  cb_url="apiListDeductible"
                  :cb_url_parameter="`deductible_actived = 'Y'`"
                  cb_title="Deductible"
                  cb_id="policydeductible_deductibleid"
                  cb_desc="policydeductible_deductibledesc"
                  cb_rules="Please fill Deductible"
                  cb_desc_readonly="readonly"
                  cb_items_id="deductible_id"
                  cb_items_desc="deductible_remarkseng"
                  :cb_value_id="register.policydeductible_deductibleid"
                  :cb_value_desc="register.policydeductible_deductibledesc"
                  @clicked="GetDeductible"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <app-cb
                  cb_type="riskcov"
                  cb_url="apiListRiskcov"
                  cb_url_parameter="riskcov_actived=Y"
                  cb_title="Risk Coverage"
                  cb_id="policydeductible_riskcovid"
                  cb_desc="policydeductible_riskcovdesc"
                  cb_rules="Please fill Riskcov"
                  cb_desc_readonly="readonly"
                  cb_items_id="riskcov_id"
                  cb_items_desc="riskcov_desc"
                  :cb_value_id="register.policydeductible_riskcovid"
                  :cb_value_desc="register.policydeductible_riskcovdesc"
                  @clicked="GetRiskCov"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="mt-n3">
                <v-text-field
                  v-model="register.policydeductible_remarks"
                  required
                  :maxlength="maxinput.desc"
                  label="Description *"
                  placeholder="Description"
                  id="policydeductible_remarks"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  cb_title="CCY"
                  cb_id="policydeductible_ccy"
                  cb_desc="policydeductible_ccydesc"
                  cb_rules="Please fill Currency"
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="register.policydeductible_ccy"
                  cb_single="Y"
                  @clicked="GetCurrency"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <vuetify-money
                  v-model="register.policydeductible_pcttsi"
                  label="TSI (%)"
                  placeholder="TSI (%)"
                  backgroundColor="numeric"
                  :options="format_rate"
                  :properties="properties"
                  valueWhenIsEmpty="0"
                >
                </vuetify-money>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <vuetify-money
                  v-model="register.policydeductible_pctclaim"
                  label="Claim (%)"
                  placeholder="Claim (%)"
                  backgroundColor="numeric"
                  :options="format_rate"
                  :properties="properties"
                  valueWhenIsEmpty="0"
                >
                </vuetify-money>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <vuetify-money
                  v-model="register.policydeductible_fixedmin"
                  label="Fixed (MIN)"
                  placeholder="Fixed (MIN)"
                  backgroundColor="numeric"
                  :options="format_rate"
                  :properties="properties"
                  valueWhenIsEmpty="0"
                >
                </vuetify-money>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <vuetify-money
                  v-model="register.policydeductible_fixedmax"
                  label="Fixed (MAX)"
                  placeholder="Fixed (MAX)"
                  backgroundColor="numeric"
                  :options="format_rate"
                  :properties="properties"
                  valueWhenIsEmpty="0"
                >
                </vuetify-money>
              </v-col>
            </v-layout>
          </v-form>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              @click:append="ListData"
            ></v-text-field>
            <v-data-table
              :headers="header['interest']"
              :items="items"
              :loading="loading.data"
              :search="search"
              loading-text="Loading... Please wait"
              dense
              item-key="dataid"
              class="elevation-1 row-pointer"
            >
              <template slot="headerCell" slot-scope="props">
                <v-tooltip bottom>
                  <span slot="activator">
                    {{ props.header.text }}
                  </span>
                  <span>
                    {{ props.header.text }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:no-data> No Data Available </template>
              <template v-slot:item.policydeductible_fixedmin="{ item }">
                {{ $functions.NewFormatNumber(item.policydeductible_fixedmin) }}
              </template>
              <template v-slot:item.policydeductible_fixedmax="{ item }">
                {{ $functions.NewFormatNumber(item.policydeductible_fixedmax) }}
              </template>

              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="EditData(item.policydeductible_policyrow)"
                >
                  {{ $vars.V("icon_edit") }}
                </v-icon>
                <v-icon
                  small
                  @click="
                    Delete(
                      item.policydeductible_policyrow,
                      item.policydeductible_intid
                    )
                  "
                >
                  {{ $vars.V("icon_delete") }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-main>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="primary"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :value="snackbar.dialog"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    dialog_title: String,
    dialog_id: String,
    dialog_param: String,
  },
  data: () => ({
    url_type: "add",
    limit: "1000",
    search: "",
    header: {
      interest: [
        { text: "Actions", value: "action", sortable: false },
        {
          text: "Deductible",
          value: "policydeductible_deductibleid",
          align: "left",
        },
        {
          text: "Risk Coverage",
          value: "policydeductible_riskcovid",
          align: "left",
        },
        { text: "Description", value: "policydeductible_remarks" },
        {
          text: "CCY",
          value: "policydeductible_ccy",
          align: "right",
        },
        { text: "TSI (%)", value: "policydeductible_pcttsi" },
        { text: "Claim (%)", value: "policydeductible_pctclaim" },
        { text: "Fixed (Min)", value: "policydeductible_fixedmin" },
        { text: "Fixed (Max)", value: "policydeductible_fixedmax" },
      ],
    },
    items: [],
    loading: {
      data: false,
    },
    register: {
      policydeductible_policyrow: "",
      policydeductible_deductibleid: "",
      policydeductible_deductibledesc: "",
      policydeductible_riskcovid: "",
      policydeductible_riskcovdesc: "",
      policydeductible_remarks: "",
      policydeductible_pcttsi: 0,
      policydeductible_pctclaim: 0,
      policydeductible_fixedmin: 0,
      policydeductible_fixedmax: 0,
      policydeductible_ccy: "",
      policydeductible_ccydesc: "",
      deductible_id: "",
      deductible_remarkseng: "",
    },
    maxinput: {
      desc: 255,
    },
    snackbar: {
      dialog: false,
      color: "primary",
      text: "",
      timeout: 3000,
    },
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 4,
    },
    // Format Properties
    properties: {
      reverse: true,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
  }),
  computed: {
    dialoginterest: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    dialog: function (newdata) {
      if (newdata !== "") {
        this.ListData();
      }
    },
  },
  methods: {
    SearchClose() {
      this.dialoginterest = false;
    },
    AddData() {
      this.url_type = "add";
      this.register.policydeductible_policyrow = "";
      this.register.policydeductible_deductibleid = "";
      this.register.policydeductible_row = "";
      this.register.policydeductible_riskcovid = "";
      this.register.policydeductible_remarks = "";
      this.register.policydeductible_pcttsi = 0;
      this.register.policydeductible_pctclaim = 0;
      this.register.policydeductible_fixedmin = 0;
      this.register.policydeductible_fixedmax = 0;
      this.register.policydeductible_ccy = "";
    },
    EditData(id) {
      // this.$set(this.policy_ccy, 0, '')
      this.loading.data = true;
      let formdata = {
        policydeductible_policyrow: String(id),
        order_by: "policydeductible_policyrow",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListPolicyDeductible"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.loading.data = false;
              this.url_type = "edit";
              this.register.policydeductible_policyrow =
                feedback[0].policydeductible_policyrow;
              this.register.policydeductible_deductibleid =
                feedback[0].policydeductible_deductibleid;
              this.register.policydeductible_deductibledesc =
                feedback[0].policydeductible_deductibledesc;
              this.register.policydeductible_riskcovid =
                feedback[0].policydeductible_riskcovid;
              this.register.policydeductible_riskcovdesc =
                feedback[0].policydeductible_riskcovdesc;
              this.register.policydeductible_remarks =
                feedback[0].policydeductible_remarks;
              this.register.policydeductible_pcttsi =
                feedback[0].policydeductible_pcttsi;
              this.register.policydeductible_pctclaim =
                feedback[0].policydeductible_pctclaim;
              this.register.policydeductible_fixedmin =
                feedback[0].policydeductible_fixedmin;
              this.register.policydeductible_fixedmax =
                feedback[0].policydeductible_fixedmax;
              this.register.policydeductible_ccy =
                feedback[0].policydeductible_ccy;
            } else {
              this.loading.data = false;
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.loading.data = false;
            this.SnackBar(true, "error", "No Data Available", 0);
          }
        });
    },
    ListData() {
      this.items["interest"] = [];
      this.loading.data = true;
      let formdata = {
        policydeductible_policyrow: this.dialog_id,
        order_by: "policydeductible_policyrow",
        order_type: "DESC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListPolicyDeductible"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log(feedback);
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.loading.data = false;
              this.items = feedback;
            } else {
              this.loading.data = false;
              this.items = feedback;
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.loading.data = false;
            // this.SnackBar(true, "error", 'No Data Available', 0)
          }
        });
    },
    Delete(id) {
      this.url_type = "delete";
      this.register.policydeductible_policyrow = id;
      this.ConfirmData();
    },
    ConfirmData() {
      if (this.url_type === "add" || this.url_type === "edit") {
        if (this.$refs.form_policydeductible.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Confirm";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (this.url_type === "delete") {
        this.confirm.dialog = true;
        this.confirm.title = "Delete";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.SaveData();
      } else if (this.url_type === "delete") {
        this.DeleteData();
      }
    },
    GetCurrency(value) {
      let split = value.split("|");
      this.register.policydeductible_ccy = split[0];
    },
    CalcGuarantee() {},
    SaveData() {
      this.loading.data = true;
      let formdata = {
        url_type: this.url_type,
        policydeductible_policyrow: this.dialog_id,
        policydeductible_deductibleid:
          this.register.policydeductible_deductibleid,
        policydeductible_riskcovid: this.register.policydeductible_riskcovid,
        policydeductible_remarks: this.register.policydeductible_remarks,
        policydeductible_pcttsi: this.register.policydeductible_pcttsi,
        policydeductible_pctclaim: this.register.policydeductible_pctclaim,
        policydeductible_fixedmin: this.register.policydeductible_fixedmin,
        policydeductible_fixedmax: this.register.policydeductible_fixedmax,
        policydeductible_ccy: this.register.policydeductible_ccy,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWritePolicyDeductible"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.loading.data = false;
              this.items[this.dialog_title] = feedback;
              this.SnackBar(true, "primary", "Save Successfully", 0);
              this.ListData();
            } else {
              this.loading.data = false;
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.loading.data = false;
            this.SnackBar(true, "error", "No Data Available", 0);
          }
        });
    },
    DeleteData() {
      this.loading.data = true;
      let formdata = {
        url_type: this.url_type,
        policydeductible_policyrow: this.register.policydeductible_policyrow,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWritePolicyDeductible"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.loading.data = false;
              this.items[this.dialog_title] = feedback;
              this.SnackBar(true, "primary", "Delete Successfully", 0);
              this.ListData();
            } else {
              this.loading.data = false;
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.loading.data = false;
            this.SnackBar(true, "error", "No Data Available", 0);
          }
        });
    },
    GetRiskCov(value) {
      let split = value.split("|");
      this.register.policydeductible_riskcovid = split[0];
      this.register.policydeductible_riskcovdesc = split[1];
    },
    GetDeductible(value) {
      let split = value.split("|");
      this.register.policydeductible_deductibleid = split[0];
      this.register.policydeductible_deductibledesc = split[1];
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      this.confirm.dialog = false;
      this.confirm.text = "Ok";
    },
  },
};
</script>
