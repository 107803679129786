// src/plugins/vuetify/theme.js

import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: '#2196F3',
    darken1: colors.purple.darken2,
  },
  secondary: '#2196F3',
  background: {
    base: '#f2f2f2',
  },
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: '#2196F3',
  fourth: '#2196F3',
  readonly: '#ECEFF1',
  date: '#FFFFFF',
  time: '#FFFFFF',
  numeric: '#FFFFFF',
  combo: '#FFFFFF',
  more:'#00ff00',
}