<template>
  <div>
    <app-drawer_menu drawer_menu_title="User Type"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs
      background-color="primary"
      dark
      color="fourth"
      v-if="access.read === 1"
    >
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
        {{ $vars.V("txt_form") }}
      </v-tab>
      <v-tab href="#tab-list" @click="List">
        {{ $vars.V("txt_list") }}
      </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_userstype"
          lazy-validation
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{ $vars.V("icon_add") }}</v-icon
                >{{ $vars.V("txt_add") }}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
                >{{ $vars.V("txt_save") }}
              </v-btn>
            </v-col>
            <!-- ID -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="form.userstype_id"
                required
                :rules="form.userstype_id_rules"
                :readonly="form.userstype_id_readonly"
                :background-color="form.userstype_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="userstype_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Desc -->
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="form.userstype_desc"
                required
                :rules="form.userstype_desc_rules"
                :maxlength="maxinput.desc"
                label="Description *"
                placeholder="Description"
                id="userstype_desc"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Limit In -->
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model.lazy="form.userstype_limit_in"
                v-money="format_money"
                required
                :rules="form.userstype_limit_in_rules"
                label="Limit In"
                placeholder="Limit In"
                id="userstype_limit_in"
                ref="userstype_limit_in"
                @keyup.enter="Confirm('write', '')"
                reverse
              >
              </v-text-field>
            </v-col>
            <!-- Limit Out -->
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model.lazy="form.userstype_limit_out"
                v-money="format_money"
                required
                :rules="form.userstype_limit_out_rules"
                label="Limit Out"
                placeholder="Limit Out"
                id="userstype_limit_out"
                ref="userstype_limit_out"
                @keyup.enter="Confirm('write', '')"
                reverse
              >
              </v-text-field>
            </v-col>
            <!-- Default Page -->
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="form.userstype_default_page"
                required
                :rules="form.userstype_default_page_rules"
                label="Default Page *"
                placeholder="Default Page"
                id="userstype_default_page"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.userstype_actived"
                color="primary"
                id="form.userstype_actived"
                :label="form.userstype_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn-toggle v-if="url_type !== 'add'">
                <v-btn small @click="Dialog('AM')">API Modul</v-btn>
                <v-btn small @click="Dialog('MA')">Menu Access</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.userstype_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.userstype_cuser)">{{form.userstype_cuser}}</span>
Last Update Date : {{form.userstype_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.userstype_luser)">{{form.userstype_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{ $vars.V("txt_select_all_page") }}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{ $vars.V("icon_search") }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="userstype_id"
            show-select
            class="elevation-1"
            :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.userstype_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse </v-icon
              >|
              <v-icon
                small
                @click="Confirm('delete', item.userstype_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
            <template v-slot:item.userstype_limit_in="{ item }">
              {{ FormatNumber(item.userstype_limit_in, 0) }}
            </template>
            <template v-slot:item.userstype_limit_out="{ item }">
              {{ FormatNumber(item.userstype_limit_out, 0) }}
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>
    <!-- Dialog API Modul Access -->
    <v-dialog
      v-model="dialog.modul"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490"
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.modul"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.modul = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>API Modul - {{ form.userstype_id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="CheckModul()" class="mr-2" small icon dark
            ><v-icon>done_all</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm('apimodul', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingModul">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr
                    v-for="item in apimodul"
                    :key="item.apimodultype_apimodulid"
                  >
                    <td>
                      <input
                        type="checkbox"
                        dense
                        :id="`id${item.apimodultype_apimodulid}`"
                        :checked="
                          item.apimodultype_userstype != '' ? true : false
                        "
                      /><label
                        class="cursor"
                        :for="`id${item.apimodultype_apimodulid}`"
                      >
                        {{ item.apimodultype_apimodulid }}</label
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog API Menu Access -->
    <v-dialog
      v-model="dialog.menu"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="690"
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.menu"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.menu = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title
            >Menu Access - {{ form.userstype_id }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Confirm('menu', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingMenu">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Menu</th>
                    <th>
                      Read [<span @click="CheckMenu('read')" class="cursor"
                        ><u>check</u></span
                      >]
                    </th>
                    <th>
                      Add [<span @click="CheckMenu('add')" class="cursor"
                        ><u>check</u></span
                      >]
                    </th>
                    <th>
                      Edit [<span @click="CheckMenu('edit')" class="cursor"
                        ><u>check</u></span
                      >]
                    </th>
                    <th>
                      Delete [<span @click="CheckMenu('delete')" class="cursor"
                        ><u>check</u></span
                      >]
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="items in menu" :key="items.usersmenu_menuid">
                    <td>
                      <label
                        class="cursor"
                        :for="`id${items.usersmenu_userstype}`"
                      >
                        {{ items.usersmenu_menuid }} -
                        {{ items.usersmenu_menudesc }}</label
                      >
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        dense
                        :id="`idread${items.usersmenu_menuid}`"
                        :checked="items.usersmenu_read == 1 ? true : false"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        dense
                        :id="`idadd${items.usersmenu_menuid}`"
                        :checked="items.usersmenu_add == 1 ? true : false"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        dense
                        :id="`idedit${items.usersmenu_menuid}`"
                        :checked="items.usersmenu_edit == 1 ? true : false"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        dense
                        :id="`iddelete${items.usersmenu_menuid}`"
                        :checked="items.usersmenu_delete == 1 ? true : false"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      desc: 150,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    dialog: {
      modul: false,
      menu: false,
      users: false,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "userstype_id",
      },
      { text: "Description", value: "userstype_desc" },
      { text: "Default Page", value: "userstype_default_page" },
      { text: "Limit IN", value: "userstype_limit_in", align: "right" },
      { text: "Limit OUT", value: "userstype_limit_out", align: "right" },
      { text: "Actived", value: "userstype_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    apimodul: [],
    menu: [],
    loading: {
      page: true,
      list: false,
      modul: false,
      menu: false,
    },
    btn_search: false,
    form: {
      userstype_id: "",
      userstype_id_rules: [(v) => !!v || "Please fill ID"],
      userstype_id_readonly: false,
      userstype_desc: "",
      userstype_desc_rules: [(v) => !!v || "Please fill Description"],
      userstype_default_page: "",
      userstype_default_page_rules: [(v) => !!v || "Please fill Default Page"],
      userstype_limit_in: 0,
      userstype_limit_in_rules: [(v) => !!v || "Please fill Limit IN"],
      userstype_limit_out: 0,
      userstype_limit_out_rules: [(v) => !!v || "Please fill Limit OUT"],
      userstype_actived: true,
      display_users: "",
    },
    format_money: {
      decimal: ".",
      thousands: ",",
      precision: 0,
    },
    selectedapi: [],
    detail: {
      checkmodul: false,
      checkmenu_read: false,
      checkmenu_add: false,
      checkmenu_edit: false,
      checkmenu_delete: false,
    },
  }),
  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "userstype";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = priv[0].feedback;
            } else {
              this.access.read = priv[0].usersmenu_read;
              this.access.add = priv[0].usersmenu_add;
              this.access.edit = priv[0].usersmenu_edit;
              this.access.delete = priv[0].usersmenu_delete;
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                );
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    //Function for get all data from table Code with API in mod_users.go
    List() {
      this.loading.list = true;
      let formdata = {
        userstype_id: "",
        userstype_desc: "",
        order_by: "userstype_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListUsersType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id) {
      if (flag === "write") {
        if (this.$refs.form_userstype.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.userstype_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "apimodul") {
        this.url_type = "apimodul";
        this.confirm.dialog = true;
        this.confirm.title =
          "Save Access API Modul for `" + this.form.userstype_id + "`";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "menu") {
        this.url_type = "menu";
        this.confirm.dialog = true;
        this.confirm.title =
          "Save Access Menu for `" + this.form.userstype_id + "`";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },

    //Function to request insert data to table Code 'The API will validating your data, include the user who process the data' (go to APIWriteCode with url_type = "add")
    Add() {
      this.url_type = "add";
      this.form.userstype_id = "";
      this.form.userstype_desc = "";
      this.form.userstype_default_page = "";
      this.form.userstype_limit_in = 0;
      this.$refs.userstype_limit_in.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.form.userstype_limit_out = 0;
      this.$refs.userstype_limit_out.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.form.userstype_actived = true;
      this.form.userstype_id_readonly = false;
      setTimeout(function () {
        document.getElementById("userstype_id").focus();
      }, 500);
    },
    //Function to request update data to table Code 'The API will validating your data, include the user who process the data' (go to APIWriteCode with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        userstype_id: id,
        userstype_desc: "",
        order_by: "userstype_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListUsersType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();
              this.url_type = "edit";
              this.form.userstype_id = feedback[0].userstype_id;
              this.form.userstype_desc = feedback[0].userstype_desc;
              this.form.userstype_limit_in = feedback[0].userstype_limit_in;
              this.$refs.userstype_limit_in.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].userstype_limit_in;
              this.form.userstype_limit_out = feedback[0].userstype_limit_out;
              this.$refs.userstype_limit_out.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].userstype_limit_out;
              this.form.userstype_default_page =
                feedback[0].userstype_default_page;
              this.form.userstype_actived = this.$functions.TrueOrFalse(
                feedback[0].userstype_actived
              );
              this.form.userstype_cdate = feedback[0].userstype_cdate;
              this.form.userstype_cuser = feedback[0].userstype_cuser;
              this.form.userstype_ldate = feedback[0].userstype_ldate;
              this.form.userstype_luser = feedback[0].userstype_luser;
              this.form.userstype_id_readonly = true;
              setTimeout(function () {
                document.getElementById("userstype_desc").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        userstype_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsersType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsersType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.userstype_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (this.url_type === "apimodul") {
        this.SaveModul();
      } else if (this.url_type === "menu") {
        this.SaveMenu();
      }
    },
    Write() {
      let formdata = {
        url_type: this.url_type,
        userstype_id: this.form.userstype_id,
        userstype_desc: this.form.userstype_desc,
        userstype_default_page: this.form.userstype_default_page,
        userstype_limit_in: this.form.userstype_limit_in,
        userstype_limit_out: this.form.userstype_limit_out,
        userstype_actived: this.$functions.ActivedFlag(
          this.form.userstype_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsersType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.userstype_cuser = feedback[0].feedback_users_id;
                this.form.userstype_cdate = feedback[0].feedback_users_date;
              }
              this.form.userstype_luser = feedback[0].feedback_users_id;
              this.form.userstype_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    FormatNumber(value, fixed) {
      return this.$functions.FormatNumber(value, fixed);
    },
    Dialog(flag) {
      if (flag === "AM") {
        this.OpenModul();
      } else if (flag === "MA") {
        this.OpenMenu();
      }
    },
    OpenModul() {
      this.dialog.modul = true;
      this.LoadingModul();
    },
    LoadingModul() {
      this.loading.modul = true;
      let formdata = {
        apimodultype_userstype: this.form.userstype_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListApiModulType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.apimodul = feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.modul = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.modul = false;
        });
    },
    CheckModul() {
      this.detail.checkmodul = !this.detail.checkmodul;
      let checked = "";
      if (this.detail.checkmodul === true) {
        checked = this.form.userstype_id;
      }
      for (var i = 0; i < this.apimodul.length; i++) {
        this.apimodul[i].apimodultype_userstype = checked;
      }
    },
    SaveModul() {
      this.selectedapi = [];
      for (let i = 0; i < this.apimodul.length; i++) {
        let menutype = this.apimodul[i].apimodultype_apimodulid;
        if (document.getElementById("id" + menutype).checked === true) {
          this.selectedapi.push({ apimodultype_apimodulid: menutype });
        }
      }
      let multiData = JSON.stringify(this.selectedapi);
      let formdata = {
        url_type: "add",
        apimodultype_userstype: this.form.userstype_id,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteApiModulType"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    OpenMenu() {
      this.dialog.menu = true;
      this.LoadingMenu();
    },
    LoadingMenu() {
      this.loading.menu = true;
      let formdata = {
        usersmenu_userstype: this.form.userstype_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListUsersMenu"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.menu = feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.menu = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.menu = false;
        });
    },
    CheckMenu(flag) {
      if (flag === "read") {
        this.detail.checkmenu_read = !this.detail.checkmenu_read;
        let checked = 0;
        if (this.detail.checkmenu_read === true) {
          checked = 1;
        }
        for (var r = 0; r < this.menu.length; r++) {
          this.menu[r].usersmenu_read = checked;
        }
      } else if (flag === "add") {
        this.detail.checkmenu_add = !this.detail.checkmenu_add;
        let checked = 0;
        if (this.detail.checkmenu_add === true) {
          checked = 1;
        }
        for (var a = 0; a < this.menu.length; a++) {
          this.menu[a].usersmenu_add = checked;
        }
      } else if (flag === "edit") {
        this.detail.checkmenu_edit = !this.detail.checkmenu_edit;
        let checked = 0;
        if (this.detail.checkmenu_edit === true) {
          checked = 1;
        }
        for (var e = 0; e < this.menu.length; e++) {
          this.menu[e].usersmenu_edit = checked;
        }
      } else if (flag === "delete") {
        this.detail.checkmenu_delete = !this.detail.checkmenu_delete;
        let checked = 0;
        if (this.detail.checkmenu_delete === true) {
          checked = 1;
        }
        for (var d = 0; d < this.menu.length; d++) {
          this.menu[d].usersmenu_delete = checked;
        }
      }
    },
    SaveMenu() {
      for (let i = 0; i < this.menu.length; i++) {
        let menutype = this.menu[i].usersmenu_menuid;
        this.menu[i].usersmenu_read = this.$functions.Checked(
          document.getElementById("idread" + menutype).checked
        );
        this.menu[i].usersmenu_add = this.$functions.Checked(
          document.getElementById("idadd" + menutype).checked
        );
        this.menu[i].usersmenu_edit = this.$functions.Checked(
          document.getElementById("idedit" + menutype).checked
        );
        this.menu[i].usersmenu_delete = this.$functions.Checked(
          document.getElementById("iddelete" + menutype).checked
        );
      }
      let multiData = JSON.stringify(this.menu);
      let formdata = {
        url_type: "add",
        usersmenu_userstype: this.form.userstype_id,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteUsersMenu"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    ShowUsers(id) {
      this.dialog.users = true;
      this.form.display_users = id;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>
