<template>
  <div id="app-reinsuranse-detail">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{ $vars.V("txt_no_access") }}
    </div>
    <div v-else>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          @click:append="btn_search = false"
          label="Search"
          single-line
          hide-details
          class="ml-3"
          v-if="btn_search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="reinstrans_profileid"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        dense
        :loading="loading.detail"
        hide-default-header
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.reinstrans_ricomm="{ item }">
          {{ $functions.NewFormatNumber(item.reinstrans_ricomm, 15) }}
        </template>
        <template v-slot:item.reinstrans_finalshare="{ item }">
          {{ $functions.NewFormatNumber(item.reinstrans_finalshare, 15) }}
        </template>
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
                class="secondary white--text"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
        </template>
      </v-data-table>
    </div>
    <!-- Start Form -->
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.v-data-table-header {
  background-color: #ffeb3b !important; /* Yellow */
}
</style>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
    subclass: String,
    headerColor: {
      type: String,
      default: "#edf7ed", // Default color
    },
  },
  computed: {
    headerClass() {
      return {
        backgroundColor: this.headerColor ? this.headerColor : "#2196f3",
      };
    },
  },
  data: () => ({
    url_type: "add",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },

    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },

    loading: {
      page: true,
      detail: true,
    },

    properties: {
      reverse: true,
    },
    selected: [],
    items: [],
    btn_search: true,
    search: "",
    headers: [
      { text: "Docno", value: "reinstrans_docno" },
      { text: "Name", value: "reinstrans_profiledesc" },
      {
        text: "Estimate Share",
        value: "reinstrans_share",
        align: "right",
      },
      {
        text: "RI COM",
        value: "reinstrans_ricomm",
        align: "right",
      },
      {
        text: "Final Share",
        value: "reinstrans_finalshare",
        align: "right",
      },
      {
        text: "Reminder Days",
        value: "reinstrans_reminderdays",
        align: "right",
      },
      {
        text: "Status",
        value: "reinstrans_status",
        align: "center",
      },
    ],
    form: {
      reinstrans_docno: "",
      reinstrans_profileold: "",
      reinstrans_profileid: "",
      reinstrans_profiledesc: "",
      reinstrans_type: "",
      reinstrans_share: 0,
      reinstrans_ricomm: 0,
      reinstrans_finalshare: 0,
      reinstrans_reminderdays: 0,
      reinstrans_status: "",
      reinstrans_actived: "",
      reinstrans_pic_marketing: "",
      reinstrans_pic_marketing_cc: "",
      reinstrans_quo_lang: "",
      reinstrans_pic_claim: "",
      reinstrans_pic_finance: "",
      reinstrans_template: "",
    },
  }),
  watch: {
    id: function (newdata) {
      if (newdata !== "") {
        this.id = newdata;
        this.List(newdata);
      } else {
        this.id = "";
        this.List(newdata);
      }
    },
  },
  created() {
    this.List(this.id);
  },
  methods: {
    Access() {
      let modul = "profile";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    List(id) {
      this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        this.form.reinstrans_docno = id;
        let formdata = {
          reinstrans_docno: id,
          order_by: "reinstrans_docno",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        console.log(param);
        this.$axios
          .post(this.$functions.UrlPOST("apiListReinsTrans"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; /* Sesuaikan ukuran sesuai kebutuhan */
  height: 20px; /* Sesuaikan ukuran sesuai kebutuhan */
  background-color: red;
  border-radius: 100%;
  padding: 2px;
}
.icon-wrapper:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.custom-svg {
  width: 15px;
  height: 20px;
  fill: rgb(255, 255, 255);
}
</style>
