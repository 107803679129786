<template>
  <div>
    <app-drawer_menu drawer_menu_title="Acceptance"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="white" color="fourth" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad"> Form </v-tab>
      <v-tab href="#tab-list" @click="List"> List </v-tab>
      <v-tab-item value="tab-form">
        <v-form enctype="multipart/form-data" ref="form_ticket" lazy-validation>
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <!-- <v-btn
                color		  ="tertiary"
                class			="white--text"
                :disabled ="access.add === 0 ? true:false" 
                rounded
                @click 	  ="Add" 
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn> &nbsp;-->
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm(url_type, '')"
                small
                v-if="url_type !== 'add' ? true : false"
              >
                <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
                >{{ $vars.V("txt_save") }}
              </v-btn>
              &nbsp;
              <v-btn
                color="secondary"
                class="white--text"
                :disabled="
                  access.add === 0 ||
                  access.edit === 0 ||
                  form.ticket_status === 'L'
                    ? true
                    : false
                "
                rounded
                small
                @click="Solution"
                :loading="loading.underwrite"
                v-if="url_type !== 'add' ? true : false"
              >
                <v-icon dark>checklist</v-icon>Underwrite
              </v-btn>
              &nbsp;
              <v-btn
                color="fourth"
                class="white--text ml-1"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Print"
                small
                v-if="url_type !== 'add' ? true : false"
              >
                <v-icon dark>print</v-icon>Print
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mt-n3">
              <p class="caption">Source</p>
              <v-btn-toggle
                v-model="toggle.source"
                class="mt-n3"
                :disabled="true"
              >
                <v-btn title="Email">
                  <v-icon>mdi-email-outline</v-icon>
                </v-btn>
                <v-btn title="Telegram">
                  <v-icon>mdi-send-variant</v-icon>
                </v-btn>
                <v-btn title="Whatsapp">
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <app-cb
                cb_type="campaign"
                cb_url="apiListCampaign"
                cb_url_parameter="campaign_actived=Y"
                cb_title="Campaign"
                cb_id="ticket_campaignid"
                cb_desc="ticket_campaigndesc"
                cb_rules="Please fill Campaign"
                :cb_id_readonly="true"
                cb_desc_readonly="readonly"
                cb_items_id="campaign_id"
                cb_items_desc="campaign_desc"
                :cb_value_id="form.ticket_campaignid"
                :cb_value_desc="form.ticket_campaigndesc"
              >
              </app-cb>
            </v-col>

            <!-- ID -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model="form.ticket_id"
                required
                :rules="form.ticket_id_rules"
                :readonly="form.ticket_id_readonly"
                :background-color="form.ticket_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="ticket_id"
                @keyup.enter="Confirm(url_type, '')"
              >
              </v-text-field>
            </v-col>
            <!-- SubClass -->
            <v-col cols="12" sm="5" md="5" class="mt-n3">
              <v-text-field
                v-model="form.ticket_subclass"
                required
                :readonly="true"
                background-color="readonly"
                label="Sub Class *"
                placeholder="Sub Class"
                id="ticket_subclass"
              >
              </v-text-field>
            </v-col>
            <!-- Subject -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model="form.ticket_subject"
                required
                :rules="form.ticket_subject_rules"
                :readonly="form.ticket_subject_readonly"
                :background-color="
                  form.ticket_subject_readonly ? 'readonly' : ''
                "
                :maxlength="maxinput.desc"
                label="Subject *"
                placeholder="Subject"
                id="ticket_subject"
                @keyup.enter="Confirm(url_type, '')"
              >
              </v-text-field>
            </v-col>
            <!-- Principal -->
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model="form.ticket_name"
                required
                :rules="form.ticket_name_rules"
                :maxlength="maxinput.desc"
                label="Principal"
                placeholder="Principal"
                id="ticket_name"
                @keyup.enter="Confirm(url_type, '')"
              >
              </v-text-field>
            </v-col>
            <!-- Date -->
            <v-col cols="7" sm="3" md="2" class="mt-n3">
              <v-dialog
                ref="ticket_date"
                v-model="modal.ticket_date"
                :return-value.sync="form.ticket_date"
                persistent
                width="290px"
                :disabled="form.ticket_date_readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.ticket_date"
                    label="Date"
                    prepend-inner-icon="event"
                    :readonly="form.ticket_date_readonly"
                    :background-color="
                      form.ticket_date_readonly ? 'readonly' : 'date'
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.ticket_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.ticket_date = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.ticket_date.save(form.ticket_date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Target Date -->
            <v-col cols="7" sm="3" md="2" class="mt-n3">
              <v-dialog
                ref="ticket_targetdate"
                v-model="modal.ticket_targetdate"
                :return-value.sync="form.ticket_targetdate"
                persistent
                width="290px"
                :disabled="form.ticket_targetdate_readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.ticket_targetdate"
                    label="Target Date"
                    prepend-inner-icon="event"
                    :readonly="form.ticket_targetdate_readonly"
                    :background-color="
                      form.ticket_targetdate_readonly ? 'readonly' : 'date'
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.ticket_targetdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.date = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.ticket_targetdate.save(form.ticket_targetdate)
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Source Desc -->
            <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model 	  	="form.ticket_sourcedesc"
                required
                :rules              ="form.ticket_sourcedesc_rules"
                :readonly           ="form.ticket_sourcedesc_readonly"
                :background-color   ="form.ticket_sourcedesc_readonly ? 'readonly' : ''"
                :maxlength 	  ="maxinput.desc"
                label       	="Source"
                placeholder 	="Source"
                id 			      ="ticket_sourcedesc"
                @keyup.enter  ="Confirm(url_type,'')"
              >
              </v-text-field>
            </v-col> -->

            <!-- PIC -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model="form.ticket_pic"
                required
                :rules="form.ticket_pic_rules"
                :readonly="form.ticket_pic_readonly"
                :background-color="form.ticket_pic_readonly ? 'readonly' : ''"
                :maxlength="maxinput.desc"
                label="Underwriter"
                placeholder="Underwriter"
                id="ticket_pic"
                @keyup.enter="Confirm(url_type, '')"
              >
              </v-text-field>
            </v-col>
            <!-- PIC -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model="form.ticket_mo"
                required
                :rules="form.ticket_mo_rules"
                :readonly="form.ticket_mo_readonly"
                :background-color="form.ticket_mo_readonly ? 'readonly' : ''"
                :maxlength="maxinput.desc"
                label="Marketing Officer"
                placeholder="Marketing Officer"
                id="ticket_mo"
                @keyup.enter="Confirm(url_type, '')"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-2" v-if="url_type !== 'add' ? true : false">
            <v-col cols="12" sm="4" md="4">
              <v-alert border="left" color="secondary" dark dense>
                {{ form.ticket_keydesc }} - {{ form.ticket_statusdesc }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.ticket_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.ticket_cuser)">{{form.ticket_cuser}}</span>
Last Update Date : {{form.ticket_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.ticket_luser)">{{form.ticket_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
          <v-tabs
            background-color="white"
            color="secondary"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            v-show="url_type !== 'add' ? true : false"
            class="mb-5"
          >
            <v-tab id="tablog" href="#tab-log" @click="ListLog(form.ticket_id)">
              Log
            </v-tab>
            <v-tab
              id="tabattachment"
              href="#tab-attachment"
              @click="Attachment(form.ticket_id)"
            >
              Attachment
            </v-tab>
            <v-tab
              id="tabriskloss"
              ref="tabriskloss"
              href="#tab-riskloss"
              @click="ListScoring(form.ticket_id, '5C01')"
            >
              Condition
            </v-tab>
            <v-tab
              id="taborganization"
              ref="taborganization"
              href="#tab-organization"
              @click="ListOrganization(form.ticket_profileid)"
            >
              Character
            </v-tab>
            <v-tab
              id="tabportfolio"
              ref="tabportfolio"
              href="#tab-portfolio"
              @click="ListPorto(form.ticket_profileid)"
            >
              Capacity
            </v-tab>
            <v-tab
              id="tabfinancialreport"
              ref="tabfinancialreport"
              href="#tab-financialreport"
              @click="ListFinancialReport(form.ticket_profileid)"
            >
              Capital
            </v-tab>
            <!-- <v-tab
              id      ="tabaccumulationrisk"
              href    ="#tab-accumulationrisk"
            >
              Accumulation Risk
            </v-tab> -->
            <v-tab
              id="tabcollateral"
              href="#tab-collateral"
              @click="ListCollateral(form.ticket_id)"
            >
              Collateral
              <span v-if="items_collateral.length"
                >({{ items_collateral.length }})</span
              >
            </v-tab>
            <v-tab
              id="tabscoring"
              href="#tab-scoring"
              @click="ListScoringData(form.ticket_id)"
            >
              Scoring
            </v-tab>
            <v-tab id="tabbusinessresult" href="#tab-businessresult">
              Business Result
            </v-tab>
            <v-tab-item value="tab-log">
              <template>
                <div class="mt-2">
                  <template>
                    <v-timeline>
                      <v-timeline-item
                        v-for="row in items_log"
                        :key="row.ticketlog_id"
                        large
                      >
                        <template v-slot:icon>
                          <v-avatar>
                            <img :src="row.ticketlog_users_avatarurl" />
                          </v-avatar>
                        </template>
                        <template v-slot:opposite>
                          <span> {{ row.ticketlog_sender }}</span>
                        </template>
                        <v-card class="elevation-2">
                          <v-card-title class="body-2 font-weight-bold">
                            {{ row.ticketlog_subject }}
                          </v-card-title>
                          <v-card-text
                            ><div v-html="row.ticketlog_body"></div
                          ></v-card-text>
                          <v-card-actions class="text-caption font-weight-bold"
                            >{{ row.ticketlog_keydesc }} -
                            {{ row.ticketlog_title }}<v-spacer></v-spacer
                            >{{
                              $functions.FormatDateTime(row.ticketlog_cdate)
                            }}</v-card-actions
                          >
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </template>
                  <!-- <v-data-table
                      :value="selected_log"
                      :headers="headers_log"
                      :items="items_log"
                      :search="search_log"
                      @input="CheckScope($event)"
                      item-key="ticketscope_id"
                      class="elevation-1"
                       :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'first_page',
                        lastIcon: 'last_page',
                        prevIcon: 'keyboard_arrow_left',
                        nextIcon: 'keyboard_arrow_right'
                      }"
                      dense
                      :loading 	= "loading.log"
                      loading-text="Loading... Please wait"
                      >
                      <template v-slot:item.ticketlog_cdate="{ item }">
                        {{$functions.FormatDateTime(item.ticketlog_cdate)}}
                      </template>
                      <template v-slot:item.ticketlog_cuser="{ item }">
                       <v-avatar><img
                            :src="item.ticketlog_users_avatarurl"
                            :alt="item.ticketlog_cuser"
                          ></v-avatar>
                      </template>
                      <template v-slot:item.ticketlog_keydesc="{ item }">
                          {{item.ticketlog_keydesc}} - {{item.ticketlog_title}}
                      </template>
                      <template v-slot:item.ticketlog_body="{ item }">
                        <div v-html="item.ticketlog_body"></div>
                      </template>
                      </v-data-table> -->
                </div>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-riskloss" :eager="true">
              <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.riskloss"
              ></v-progress-linear>
              <v-alert border="left" color="primary" dark dense icon="folder">
                General Data
              </v-alert>
              <v-row class="mx-2">
                <!-- Project Name -->
                <v-col cols="12" sm="5" md="5" class="mb-n5">
                  <v-text-field
                    v-model="form.ticket_projectname"
                    required
                    label="Project Name"
                    placeholder="Project Name"
                    id="ticket_projectname"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5" class="mb-n5">
                  <v-text-field
                    v-model="form.ticket_projectbasedon"
                    required
                    label="Project Based On(No/Contract Date/SPK)"
                    placeholder="Project Based On(No/Contract Date/SPK)"
                    id="ticket_projectbasedon"
                  >
                  </v-text-field>
                </v-col>
                <!-- Project Date -->
                <v-col cols="6" sm="2" md="2">
                  <v-dialog
                    ref="ticket_projectdate"
                    v-model="modal.ticket_projectdate"
                    :return-value.sync="form.ticket_projectdate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.ticket_projectdate"
                        label="Underlying Date"
                        prepend-inner-icon="event"
                        background-color="date"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.ticket_projectdate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.ticket_projectdate = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.ticket_projectdate.save(form.ticket_projectdate)
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- Inception Date -->
                <v-col cols="6" sm="2" md="2" class="mt-n3">
                  <v-dialog
                    ref="ticket_inception"
                    v-model="modal.ticket_inception"
                    :return-value.sync="form.ticket_inception"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.ticket_inception"
                        label="Inception Date"
                        prepend-inner-icon="event"
                        background-color="date"
                        v-on="on"
                        @change="IEDMOnChange('inception')"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.ticket_inception" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.ticket_inception = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.ticket_inception.save(form.ticket_inception),
                            IEDMOnChange('inception')
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- Expiry Date -->
                <v-col cols="6" sm="2" md="2" class="mt-n3">
                  <v-dialog
                    ref="ticket_expiry"
                    v-model="modal.ticket_expiry"
                    :return-value.sync="form.ticket_expiry"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.ticket_expiry"
                        label="Expiry Date"
                        prepend-inner-icon="event"
                        background-color="date"
                        v-on="on"
                        @change="IEDMOnChange('expiry')"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.ticket_expiry"
                      scrollable
                      :min="form.ticket_inception"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.ticket_expiry = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.ticket_expiry.save(form.ticket_expiry),
                            IEDMOnChange('expiry')
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- Day -->
                <v-col cols="6" sm="1" md="1" class="mt-n3">
                  <v-text-field
                    v-model="form.ticket_durationday"
                    label="Day"
                    background-color="numeric"
                    append-icon="event"
                    reverse
                    @change="IEDMOnChange('day')"
                  >
                  </v-text-field>
                </v-col>
                <!-- Day -->
                <v-col cols="6" sm="1" md="1" class="mt-n3">
                  <v-text-field
                    v-model="form.ticket_durationmonth"
                    label="Month"
                    background-color="numeric"
                    append-icon="event"
                    reverse
                    @change="IEDMOnChange('month')"
                  >
                  </v-text-field>
                </v-col>
                <!-- Day -->
                <v-col cols="6" sm="1" md="1" class="mt-n3">
                  <v-text-field
                    v-model="form.ticket_extendday"
                    label="Extend Day"
                    background-color="numeric"
                    append-icon="event"
                    reverse
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mx-2">
                <!-- Channel -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="channel"
                    cb_url="apiListChannel"
                    cb_url_parameter="channel_actived=Y"
                    cb_title="Channel"
                    cb_id="ticket_channel"
                    cb_desc="ticket_channeldesc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="channel_id"
                    cb_items_desc="channel_desc"
                    :cb_value_id="form.ticket_channel"
                    :cb_value_desc="form.ticket_channeldesc"
                  >
                  </app-cb>
                </v-col>
                <!-- Insurance Type -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="instype"
                    cb_url="apiListInsType"
                    cb_url_parameter="instype_actived=Y"
                    cb_title="Co Guarantee Type"
                    cb_id="ticket_instype"
                    cb_desc="ticket_instypedesc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="instype_id"
                    cb_items_desc="instype_desc"
                    :cb_value_id="form.ticket_instype"
                    :cb_value_desc="form.ticket_instypedesc"
                  >
                  </app-cb>
                </v-col>
                <!-- Policy Type -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="policytype"
                    cb_url="apiListPolicyType"
                    cb_url_parameter="policytype_actived=Y"
                    cb_title="Certificate Type"
                    cb_id="ticket_policytype"
                    cb_desc="ticket_policytypedesc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="policytype_id"
                    cb_items_desc="policytype_desc"
                    :cb_value_id="form.ticket_policytype"
                    :cb_value_desc="form.ticket_policytypedesc"
                  >
                  </app-cb>
                </v-col>
                <!-- Risk Coverage -->
                <v-col cols="12" sm="6" md="6" class="mt-n3">
                  <app-cb
                    cb_type="riskcov"
                    cb_url="apiListRiskcov"
                    cb_url_parameter="riskcov_actived=Y"
                    cb_title="Risk Coverage"
                    cb_id="ticket_riskcov"
                    cb_desc="ticket_riskcovdesc"
                    cb_rules="Please fill Risk Coverage"
                    cb_desc_readonly="readonly"
                    cb_items_id="riskcov_id"
                    cb_items_desc="riskcov_desc"
                    :cb_value_id="form.ticket_riskcov"
                    :cb_value_desc="form.ticket_riskcovdesc"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n3">
                  <app-cb
                    cb_type="interest"
                    cb_url="apiListInterest"
                    cb_url_parameter="interest_actived=Y"
                    cb_title="Interest"
                    cb_id="ticket_interest"
                    cb_desc="ticket_interestdesc"
                    cb_rules="Please fill Interest"
                    cb_desc_readonly="readonly"
                    cb_items_id="interest_id"
                    cb_items_desc="interest_desc"
                    :cb_value_id="form.ticket_interest"
                    :cb_value_desc="form.ticket_interestdesc"
                  >
                  </app-cb>
                </v-col>
                <!-- <v-col cols="12" sm="2" md="2">
                    <app-cb
                      cb_type           ="code"
                      cb_url            ="apiListCode"
                      cb_url_parameter  ="code_group=SCOPEOFWOKR&code_actived=Y"
                      cb_title          ="Scope of Work"
                      cb_id             ="ticket_scopeofwork"
                      cb_desc           ="ticket_scopeofworkdesc"
                      cb_rules          ="Please fill Policy Holder"
                      cb_desc_readonly  ="readonly"
                      cb_items_id       ="code_id"
                      cb_items_desc     ="code_desc"
                      :cb_value_id		  ="this.form.ticket_scopeofwork"
                      :cb_value_desc	  ="this.form.ticket_scopeofworkdesc"
                    >
                    </app-cb>
                  </v-col> -->
                <!-- Policy Holder -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_lob=01&profile_actived=Y"
                    cb_title="Obligee"
                    cb_id="ticket_pholderid"
                    cb_desc="ticket_pholderdesc"
                    cb_rules="Please fill Obligee"
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_fullname"
                    cb_items_additional_1="profile_address"
                    :cb_value_id="this.form.ticket_pholderid"
                    :cb_value_desc="this.form.ticket_pholderdesc"
                    @clicked="GetPholder"
                  >
                  </app-cb>
                </v-col>
                <!-- Address -->
                <v-col cols="12" sm="8" md="8" class="mt-n6">
                  <v-text-field
                    v-model="form.ticket_pholderaddress"
                    required
                    label="Obligee Address"
                    placeholder="Obligee Address"
                    id="ticket_pholderaddress"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
                <!-- Principal -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_lob=02&profile_actived=Y"
                    cb_title="Principal"
                    cb_id="ticket_profileid"
                    cb_desc="ticket_profiledesc"
                    cb_rules="Please fill Principal"
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_fullname"
                    cb_items_additional_1="profile_address"
                    cb_items_additional_2="profile_birth_date"
                    :cb_value_id="form.ticket_profileid"
                    :cb_value_desc="form.ticket_profiledesc"
                    @clicked="GetProfile"
                  >
                  </app-cb>
                </v-col>
                <!-- Address -->
                <v-col cols="12" sm="6" md="6" class="mt-n6">
                  <v-text-field
                    v-model="form.ticket_profileaddress"
                    required
                    label="Principal Address"
                    placeholder="Principal Address"
                    id="ticket_profileaddress"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="mt-n6">
                  <v-text-field
                    v-model="form.ticket_profilebirthdate"
                    required
                    label="Principal Birthdate"
                    placeholder="Principal Birthdate"
                    id="ticket_profilebirthdate"
                    prepend-inner-icon="event"
                    background-color="date"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
                <!-- Bank -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_lob=03&profile_actived=Y"
                    cb_title="Bank"
                    cb_id="ticket_bank"
                    cb_desc="ticket_bankdesc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_fullname"
                    cb_items_additional_1="profile_address"
                    :cb_value_id="form.ticket_bank"
                    :cb_value_desc="form.ticket_bankdesc"
                    @clicked="GetBank"
                  >
                  </app-cb>
                </v-col>
                <!-- Address -->
                <v-col cols="12" sm="8" md="8" class="mt-n6">
                  <v-text-field
                    v-model="form.ticket_bankaddress"
                    required
                    label="Bank Address"
                    placeholder="Bank Address"
                    id="ticket_bankaddress"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-alert
                border="left"
                color="secondary"
                dark
                dense
                icon="analytics"
              >
                Scoring: {{ countscoring.condition_point }}
              </v-alert>

              <v-row class="mx-2">
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-for="(data, index) in scoring.condition"
                  :key="`${data.ticketscoring_subtype}`"
                >
                  <v-radio-group column>
                    {{ index + 1 }}. {{ data.ticketscoring_subtype }}
                    <v-radio
                      v-for="row in data.items"
                      :value="row.ticketscoring_title"
                      :key="row.ticketscoring_title"
                      :label="`${row.ticketscoring_title}`"
                      :off-icon="
                        row.ticketscoring_score === row.ticketscoring_point
                          ? '$radioOn'
                          : '$radioOff'
                      "
                      @click="
                        SendScore(
                          row.ticketscoring_type,
                          row.ticketscoring_subtype,
                          row.ticketscoring_title,
                          row.ticketscoring_score
                        )
                      "
                      color="red"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-organization">
              <template>
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      selected_organization.length === items_organization.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllPageOrganization"
                    class="mr-2"
                    title="select all page"
                    small
                    >{{ $vars.V("txt_select_all_page") }}
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="ConfirmOrganization('multidelete', '')"
                    class="mr-2"
                    v-if="selected_organization.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn
                    @click="AddNewOrganization"
                    class="mr-2"
                    title="Add New"
                    small
                    >Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="btn_search_organization === false"
                    @click="btn_search_organization = true"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search_organization"
                    append-icon="search"
                    @click:append="btn_search_organization = false"
                    label="Search"
                    single-line
                    hide-details
                    v-if="btn_search_organization"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :value="selected_organization"
                  :headers="headers_organization"
                  :items="items_organization"
                  :search="search_organization"
                  @input="CheckOrganization($event)"
                  item-key="profileorganization_id"
                  show-select
                  class="elevation-1"
                  :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.organization"
                  loading-text="Loading... Please wait"
                >
                  <template
                    v-slot:item.profileorganization_capitalamount="{ item }"
                  >
                    {{
                      $functions.NewFormatNumber(
                        item.profileorganization_capitalamount
                      )
                    }}
                  </template>
                  <!-- <template v-slot:item.action="{ item }">
                  <v-icon
                  small
                  @click	  ="ConfirmScope('delete',item.profileorganization_id)"
                  :disabled = "access.delete === 0 ? true:false"
                  title 	  = "delete"
                  >
                  delete
                  </v-icon>
                </template> -->
                </v-data-table>
              </template>
              <v-alert
                border="left"
                color="secondary"
                dark
                dense
                icon="analytics"
              >
                Scoring: {{ countscoring.character_point }}
              </v-alert>
              <v-row class="mx-2">
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-for="(data, index) in scoring.character"
                  :key="`${data.ticketscoring_subtype}`"
                >
                  <v-radio-group column>
                    {{ index + 1 }}. {{ data.ticketscoring_subtype }}
                    <v-radio
                      v-for="row in data.items"
                      :value="row.ticketscoring_title"
                      :key="row.ticketscoring_title"
                      :label="`${row.ticketscoring_title}`"
                      :off-icon="
                        row.ticketscoring_score === row.ticketscoring_point
                          ? '$radioOn'
                          : '$radioOff'
                      "
                      @click="
                        SendScore(
                          row.ticketscoring_type,
                          row.ticketscoring_subtype,
                          row.ticketscoring_title,
                          row.ticketscoring_score
                        )
                      "
                      color="red"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-accumulationrisk">
              <v-row class="mx-2">
                <!-- Member Commission -->
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="form.pp_campaign_no"
                    label="Member Commission"
                    placeholder="Member Commission"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Member Commission Pct -->
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="form.pp_campaign_no"
                    label="%"
                    placeholder="%"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-col>
                <!-- Handling Fee -->
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="form.pp_campaign_no"
                    label="Handling Fee"
                    placeholder="Handling Fee"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Handling Fee Pct -->
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="form.pp_campaign_no"
                    label="%"
                    placeholder="%"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Payor Discount -->
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="form.pp_campaign_no"
                    label="Payor Discount"
                    placeholder="Payor Discount"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Payor Discount Pct -->
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="form.pp_campaign_no"
                    label="%"
                    placeholder="%"
                    readonly="readonly"
                    background-color="readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Risk Location -->
                <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                    required
                    :maxlength="maxinput.desc"
                    label="Risk Location *"
                    placeholder="Risk Location"
                    id="ticket_subject"
                    @keyup.enter="Confirm(url_type, '')"
                  >
                  </v-text-field>
                </v-col>

                <!-- Zip Code -->
                <v-col cols="12" sm="1" md="1" class="mt-n3">
                  <v-text-field
                    required
                    :maxlength="maxinput.desc"
                    label="Zip Code *"
                    placeholder="Zip Code"
                    id="ticket_subject"
                    @keyup.enter="Confirm(url_type, '')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-collateral">
              <template>
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      selected_collateral.length === items_collateral.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllPageCollateral"
                    class="mr-2"
                    title="select all page"
                    small
                    >{{ $vars.V("txt_select_all_page") }}
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="ConfirmCollateral('multidelete', '')"
                    class="mr-2"
                    v-if="selected_collateral.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn
                    @click="AddNewCollateral"
                    class="mr-2"
                    title="Add New"
                    small
                    >Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="btn_search_collateral === false"
                    @click="btn_search_collateral = true"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search_collateral"
                    append-icon="search"
                    @click:append="btn_search_collateral = false"
                    label="Search"
                    single-line
                    hide-details
                    v-if="btn_search_collateral"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :value="selected_collateral"
                  :headers="headers_collateral"
                  :items="items_collateral"
                  :search="search_collateral"
                  @input="CheckCollateral($event)"
                  item-key="ticketcollateral_id"
                  show-select
                  class="elevation-1"
                  :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.collateral"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.ticketcollateral_amount="{ item }">
                    {{
                      $functions.NewFormatNumber(item.ticketcollateral_amount)
                    }}
                  </template>
                  <!-- <template v-slot:item.action="{ item }">
                  <v-icon
                  small
                  @click	  ="ConfirmScope('delete',item.ticketcollateral_id)"
                  :disabled = "access.delete === 0 ? true:false"
                  title 	  = "delete"
                  >
                  delete
                  </v-icon>
                </template> -->
                </v-data-table>
                <v-alert
                  border="left"
                  color="secondary"
                  dark
                  dense
                  icon="analytics"
                >
                  Scoring: {{ countscoring.collateral_point }}
                </v-alert>
                <v-row class="mx-2">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    v-for="(data, index) in scoring.collateral"
                    :key="`${data.ticketscoring_subtype}`"
                  >
                    <v-radio-group column>
                      {{ index + 1 }}. {{ data.ticketscoring_subtype }}
                      <v-radio
                        v-for="row in data.items"
                        :value="row.ticketscoring_score"
                        :key="row.ticketscoring_title"
                        :label="`${row.ticketscoring_title}`"
                        :off-icon="
                          row.ticketscoring_score === row.ticketscoring_point
                            ? '$radioOn'
                            : '$radioOff'
                        "
                        @click="
                          SendScore(
                            row.ticketscoring_type,
                            row.ticketscoring_subtype,
                            row.ticketscoring_title,
                            row.ticketscoring_score
                          )
                        "
                        color="red"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-scoring">
              <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.scoring"
              ></v-progress-linear>
              <v-row class="mx-4">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="data in datascoring"
                  :key="data.ticketscoring_type"
                >
                  <v-card
                    align="center"
                    color="#F9FAFC"
                    class="rounded-circle border pt-10"
                    width="180"
                    height="180"
                    flat
                  >
                    <v-icon
                      size="60"
                      color="primary"
                      @click="OpenScoring(data.ticketscoring_type)"
                    >
                      credit_score
                    </v-icon>

                    <v-card-text class="grey--text text-lg-h6">
                      {{ data.ticketscoring_typedesc }}
                    </v-card-text>

                    <v-btn
                      absolute
                      color="primary"
                      class="white--text"
                      fab
                      left
                      top
                    >
                      <h2>{{ data.ticketscoring_point }}</h2>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-scopework">
              <template>
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      selected_scope.length === items_scope.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllPageScope"
                    class="mr-2"
                    title="select all page"
                    small
                    >{{ $vars.V("txt_select_all_page") }}
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="ConfirmScope('multidelete', '')"
                    class="mr-2"
                    v-if="selected_scope.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn @click="AddNewScope" class="mr-2" title="Add New" small
                    >Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="btn_search_scope === false"
                    @click="btn_search_scope = true"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search_scope"
                    append-icon="search"
                    @click:append="btn_search_scope = false"
                    label="Search"
                    single-line
                    hide-details
                    v-if="btn_search_scope"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :value="selected_scope"
                  :headers="headers_scope"
                  :items="items_scope"
                  :search="search_scope"
                  @input="CheckScope($event)"
                  item-key="ticketscope_id"
                  show-select
                  class="elevation-1"
                  :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.scope"
                  loading-text="Loading... Please wait"
                >
                  <!-- <template v-slot:item.action="{ item }">
                  <v-icon
                  small
                  @click	  ="ConfirmScope('delete',item.ticketscope_id)"
                  :disabled = "access.delete === 0 ? true:false"
                  title 	  = "delete"
                  >
                  delete
                  </v-icon>
                </template> -->
                </v-data-table>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-financialreport">
              <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.financialreport"
              ></v-progress-linear>
              <template>
                <v-btn
                  @click="AddNewFinancialReport"
                  class="mx-2"
                  title="add"
                  small
                >
                  Add Financial Report
                </v-btn>
                <v-btn
                  @click="form.rangeyear = !form.rangeyear"
                  class="mx-2"
                  title="range year"
                  small
                  color="secondary"
                  >Range Year: {{ form.profilecapital_rangeyear }}</v-btn
                >
                <v-row class="mx-2" v-if="form.rangeyear">
                  <!-- Fee -->
                  <v-col cols="12" sm="1" md="1" class="mt-3">
                    <v-text-field
                      v-model="form.profilecapital_rangeyear"
                      required
                      label="Range Year"
                      placeholder="Range Year"
                      background-color="numeric"
                      @change="OpenRangeYear"
                      @keyup.enter="OpenRangeYear"
                      reverse
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-simple-table dense class="mx-2 mt-3">
                  <template v-slot:default>
                    <thead>
                      <tr class="fourth">
                        <th class="text-left">REPORT</th>
                        <th
                          class="text-right"
                          v-for="header in detail.financialreport[0].year"
                          :key="header.profilecapital_year"
                        >
                          {{ header.profilecapital_year }}
                          <v-icon
                            small
                            @click="
                              EditFinancialReport(header.profilecapital_year)
                            "
                            >edit</v-icon
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in detail.financialreport[0].data"
                        :key="item.profilecapital_category"
                      >
                        <td>{{ item.profilecapital_category }}</td>
                        <td class="text-right">
                          {{
                            $functions.NewFormatNumber(
                              item.profilecapital_current,
                              0
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{
                            $functions.NewFormatNumber(
                              item.profilecapital_next,
                              0
                            )
                          }}
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            form.profilecapital_rangeyear === '3' ||
                            form.profilecapital_rangeyear === '4'
                          "
                        >
                          {{
                            $functions.NewFormatNumber(
                              item.profilecapital_next1,
                              0
                            )
                          }}
                        </td>
                        <td
                          class="text-right"
                          v-if="form.profilecapital_rangeyear === '4'"
                        >
                          {{
                            $functions.NewFormatNumber(
                              item.profilecapital_next2,
                              0
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-alert
                  border="left"
                  color="primary"
                  dark
                  dense
                  icon="analytics"
                >
                  Ratio
                </v-alert>
                <v-simple-table dense class="mx-2 mt-3">
                  <template v-slot:default>
                    <thead>
                      <tr class="fourth">
                        <th class="text-left">YEAR</th>
                        <th class="text-center">Ratio Liquidity</th>
                        <th class="text-center">Ratio Profitability</th>
                        <th class="text-center">Ratio Solvability</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in detail.capitalreport"
                        :key="item.profilecapital_year"
                      >
                        <th class="text-right">
                          {{ item.profilecapital_year }}
                        </th>
                        <td class="text-right">
                          <span
                            v-if="
                              detail.capitalreport.length === 2 && index === 0
                            "
                            ><v-icon>{{
                              $functions.CompareIcon(
                                item.profilecapital_ratioliquidity,
                                detail.capitalreport[1]
                                  .profilecapital_ratioliquidity
                              )
                            }}</v-icon></span
                          >{{
                            $functions.NewFormatNumber(
                              item.profilecapital_ratioliquidity,
                              0
                            )
                          }}
                        </td>
                        <td class="text-right">
                          <span
                            v-if="
                              detail.capitalreport.length === 2 && index === 0
                            "
                            ><v-icon>{{
                              $functions.CompareIcon(
                                item.profilecapital_ratioprofitability,
                                detail.capitalreport[1]
                                  .profilecapital_ratioprofitability
                              )
                            }}</v-icon></span
                          >{{
                            $functions.NewFormatNumber(
                              item.profilecapital_ratioprofitability,
                              0
                            )
                          }}
                        </td>
                        <td class="text-right">
                          <span
                            v-if="
                              detail.capitalreport.length === 2 && index === 0
                            "
                            ><v-icon>{{
                              $functions.CompareIcon(
                                item.profilecapital_ratiosolvability,
                                detail.capitalreport[1]
                                  .profilecapital_ratiosolvability
                              )
                            }}</v-icon></span
                          >{{
                            $functions.NewFormatNumber(
                              item.profilecapital_ratiosolvability,
                              0
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <!-- <table>
                    <thead>
                        <tr align="right"><th>REPORT</th><td>2018</td><td>2017</td></tr>
                        <tr align="right"><th>Current Asset</th><td><input type="text" value="41619663858" class="text-right"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Fixed Asset</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Current Liability</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Non Current Liability</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Income</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Profit/Loss</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Liquidity Ratio</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Profitability Ratio</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                        <tr align="right"><th>Solvability Ratio</th><td><input type="text" value="41619663858"></td><td><input type="text" value="48844091441"></td></tr>
                    </thead>
                </table> -->
                <v-alert
                  border="left"
                  color="secondary"
                  dark
                  dense
                  icon="analytics"
                >
                  Scoring: {{ countscoring.capital_point }}
                </v-alert>
                <v-row class="mx-2">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    v-for="(data, index) in scoring.capital"
                    :key="`${data.ticketscoring_subtype}`"
                  >
                    <v-radio-group column>
                      {{ index + 1 }}. {{ data.ticketscoring_subtype }}
                      <v-radio
                        v-for="row in data.items"
                        :value="row.ticketscoring_score"
                        :key="row.ticketscoring_title"
                        :label="`${row.ticketscoring_title}`"
                        :off-icon="
                          row.ticketscoring_score === row.ticketscoring_point
                            ? '$radioOn'
                            : '$radioOff'
                        "
                        @click="
                          SendScore(
                            row.ticketscoring_type,
                            row.ticketscoring_subtype,
                            row.ticketscoring_title,
                            row.ticketscoring_score
                          )
                        "
                        color="red"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-portfolio">
              <template>
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      selected_portfolio.length === items_portfolio.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllPagePorto"
                    class="mr-2"
                    title="select all page"
                    small
                    >{{ $vars.V("txt_select_all_page") }}
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="ConfirmPorto('multidelete', '')"
                    class="mr-2"
                    v-if="selected_portfolio.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn @click="AddNewPorto" class="mr-2" title="Add New" small
                    >Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="btn_search_portfolio === false"
                    @click="btn_search_portfolio = true"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search_portfolio"
                    append-icon="search"
                    @click:append="btn_search_portfolio = false"
                    label="Search"
                    single-line
                    hide-details
                    v-if="btn_search_portfolio"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :value="selected_portfolio"
                  :headers="headers_portfolio"
                  :items="items_portfolio"
                  :search="search_portfolio"
                  @input="CheckPorto($event)"
                  item-key="profileporto_id"
                  show-select
                  class="elevation-1"
                  :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.portfolio"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.profileporto_obligee="{ item }">
                    {{ item.profileporto_obligee }} -
                    {{ item.profileporto_obligeedesc }}
                  </template>
                  <template v-slot:item.profileporto_amount="{ item }">
                    {{ $functions.NewFormatNumber(item.profileporto_amount) }}
                  </template>
                  <!-- <template v-slot:item.action="{ item }">
                  <v-icon
                  small
                  @click	  ="ConfirmPorto('delete',item.profileporto_id)"
                  :disabled = "access.delete === 0 ? true:false"
                  title 	  = "delete"
                  >
                  delete
                  </v-icon>
                </template> -->
                </v-data-table>
                <v-alert
                  border="left"
                  color="secondary"
                  dark
                  dense
                  icon="analytics"
                >
                  Scoring: {{ countscoring.capacity_point }}
                </v-alert>

                <v-row class="mx-2">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    v-for="(data, index) in scoring.capacity"
                    :key="`${data.ticketscoring_subtype}`"
                  >
                    <v-radio-group column>
                      {{ index + 1 }}. {{ data.ticketscoring_subtype }}
                      <v-radio
                        v-for="row in data.items"
                        :value="row.ticketscoring_title"
                        :key="row.ticketscoring_title"
                        :label="`${row.ticketscoring_title}`"
                        :off-icon="
                          row.ticketscoring_score === row.ticketscoring_point
                            ? '$radioOn'
                            : '$radioOff'
                        "
                        @click="
                          SendScore(
                            row.ticketscoring_type,
                            row.ticketscoring_subtype,
                            row.ticketscoring_title,
                            row.ticketscoring_score
                          )
                        "
                        color="red"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-attachment">
              <v-progress-linear
                indeterminate
                color="fourth"
                rounded
                v-if="loading.attachment"
              ></v-progress-linear>
              <template>
                <v-card class="mx-auto">
                  <v-list subheader three-line>
                    <v-list-item
                      v-for="folder in detail.attachment"
                      :key="folder.attachment_id"
                    >
                      <v-list-item-avatar>
                        <v-icon large>
                          {{ $functions.AttachmentIcon(folder.attachment_ext) }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="folder.attachment_path"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          ><v-icon small>mdi-account-outline</v-icon
                          >{{ folder.attachment_cuser }} -
                          {{
                            $functions.FormatDateTime(folder.attachment_cdate)
                          }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-if="folder.attachment_type"
                          ><v-icon small class="mt-n1">mdi-file-cabinet</v-icon
                          >{{ folder.attachment_typedesc }}
                          <v-icon
                            small
                            class="mt-n1"
                            @click="UpdateAttachType('delete', folder)"
                            >mdi-close-circle</v-icon
                          ></v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-else
                          ><v-select
                            v-model="folder.attachment_type"
                            :items="detail.attachmenttype"
                            label="Type"
                            dense
                            outlined
                            item-value="code_id"
                            item-text="code_desc"
                            return-object
                            @change="UpdateAttachType('edit', folder)"
                          ></v-select
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action
                        @click="$functions.OpenURL(folder.attachment_path_url)"
                      >
                        <v-btn icon>
                          <v-icon>mdi-download-circle</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-businessresult" :eager="true">
              <v-btn
                :loading="loading.calculate"
                class="mx-2 fourth"
                title="calculate"
                small
                @click="CalculateFee"
                block
              >
                <v-icon left dark> calculate </v-icon> Calculate
              </v-btn>
              <v-row class="mx-2">
                <!-- Fee -->
                <v-col cols="12" sm="3" md="3" class="mt-3">
                  <app-cb
                    cb_type="feeduty"
                    cb_url="apiListFeeDuty"
                    cb_url_parameter="feeduty_actived=Y&feeduty_code=F"
                    cb_title="Fee"
                    cb_id="ticket_feeid"
                    cb_desc="ticket_feedesc"
                    cb_rules="Please fill Fee"
                    cb_desc_readonly="readonly"
                    cb_items_id="feeduty_id"
                    cb_items_desc="feeduty_desc"
                    cb_items_additional_1="feeduty_amount"
                    :cb_value_id="this.form.ticket_feeid"
                    :cb_value_desc="this.form.ticket_feedesc"
                    @clicked="GetFee"
                  >
                  </app-cb>
                </v-col>
                <!-- Address -->
                <v-col cols="12" sm="1" md="1">
                  <vuetify-money
                    v-model="form.ticket_feeamount"
                    v-bind:options="formatamount"
                    label="Amount"
                    placeholder="Amount"
                    background-color="numeric"
                    v-bind:properties="properties"
                  />
                </v-col>
                <!-- Duty -->
                <v-col cols="12" sm="3" md="3" class="mt-3">
                  <app-cb
                    cb_type="feeduty"
                    cb_url="apiListFeeDuty"
                    cb_url_parameter="feeduty_actived=Y&feeduty_code=D"
                    cb_title="Duty"
                    cb_id="ticket_dutyid"
                    cb_desc="ticket_dutydesc"
                    cb_rules="Please fill Fee"
                    cb_desc_readonly="readonly"
                    cb_items_id="feeduty_id"
                    cb_items_desc="feeduty_desc"
                    cb_items_additional_1="feeduty_amount"
                    :cb_value_id="this.form.ticket_dutyid"
                    :cb_value_desc="this.form.ticket_dutydesc"
                    @clicked="GetDuty"
                  >
                  </app-cb>
                </v-col>
                <!-- Amount -->
                <v-col cols="12" sm="1" md="1">
                  <vuetify-money
                    v-model="form.ticket_dutyamount"
                    v-bind:options="formatamount"
                    label="Amount"
                    placeholder="Amount"
                    background-color="numeric"
                    v-bind:properties="properties"
                  />
                </v-col>
              </v-row>
              <v-row class="mx-2">
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_bizsourcef=Y&profile_actived=Y"
                    cb_title="Biz. Source"
                    cb_id="ticket_sourceid"
                    cb_desc="ticket_sourcedesc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_fullname"
                    cb_items_additional_1="profile_address"
                    cb_items_additional_2="profile_tax_pct"
                    :cb_value_id="form.ticket_sourceid"
                    :cb_value_desc="form.ticket_sourcename"
                    @clicked="GetBizSource"
                  >
                  </app-cb>
                </v-col>
                <!-- Address -->
                <v-col cols="12" sm="6" md="6" class="mt-n6">
                  <v-text-field
                    v-model="form.ticket_sourceaddress"
                    required
                    label="Biz Source Address"
                    placeholder="Biz Source Address"
                    id="ticket_sourceaddress"
                  >
                  </v-text-field>
                </v-col>
                <!-- Commission -->
                <v-col cols="12" sm="1" md="1" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_sourcefee"
                    v-bind:options="formatrate"
                    label="Commission"
                    placeholder="Commission"
                    background-color="numeric"
                    v-bind:properties="properties"
                  />
                </v-col>
                <!-- Tax -->
                <v-col cols="12" sm="1" md="1" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_sourcetax"
                    v-bind:options="formatrate"
                    :readonly="true"
                    label="Tax"
                    placeholder="Tax"
                    background-color="numeric"
                    v-bind:properties="properties"
                  />
                </v-col>
              </v-row>
              <v-row class="mx-2">
                <!-- Wording Custom -->
                <!-- <v-col cols="12" sm="2" md="2" class="mt-n7">
                    <label class="caption">Wording Custom</label>
                    <v-switch
                      v-model	="form.ticket_wordingcustom"
                      color  	="primary"
                      id 		  ="form.ticket_wordingcustom"
                      :label 	="form.ticket_wordingcustom !== true ? 'No' : 'Yes'" 
                    ></v-switch>
                  </v-col> -->

                <!-- Guarantee Fee Pct -->
                <v-col cols="12" sm="1" md="1" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_indemnity"
                    v-bind:options="formatrate"
                    label="Indemnity in %"
                    placeholder="Indemnity in %"
                    background-color="numeric"
                    v-bind:properties="properties"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_rate"
                    v-bind:options="formatrate"
                    label="Rate in %"
                    placeholder="Rate in %"
                    background-color="numeric"
                    v-bind:properties="properties"
                    @input="Calculate"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1" class="mt-n3">
                  <app-cb
                    cb_single="Y"
                    cb_type="currency"
                    cb_url="apiListCurrency"
                    cb_url_parameter="currency_actived=Y"
                    cb_title="CCY"
                    cb_id="ticket_ccy"
                    cb_desc="ticket_ccy"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="currency_id"
                    cb_items_desc="currency_desc"
                    :cb_value_id="form.ticket_ccy"
                    :cb_value_desc="form.ticket_ccy"
                  >
                  </app-cb>
                </v-col>
                <!-- Project Amount -->
                <v-col cols="12" sm="2" md="2" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_tsi"
                    v-bind:options="formatamount"
                    label="Project Amount"
                    placeholder="Project Amount"
                    background-color="numeric"
                    v-bind:properties="properties"
                    @input="Calculate"
                  />
                </v-col>

                <!-- Company Pct -->
                <v-col cols="12" sm="1" md="1" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_pcttsi"
                    v-bind:options="formatrate"
                    label="Percentage %"
                    placeholder="Percentage %"
                    background-color="numeric"
                    v-bind:properties="properties"
                    @input="Calculate"
                  />
                </v-col>

                <!-- Sum Insured -->
                <v-col cols="12" sm="2" md="2" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_guaranteevalue"
                    v-bind:options="formatamount"
                    label="Guarantee Value"
                    placeholder="Guarantee Value"
                    background-color="numeric"
                    v-bind:properties="properties"
                    @input="CalculateTSI"
                  />
                </v-col>

                <!-- Total Guarantee -->
                <v-col cols="12" sm="3" md="3" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_guaranteefee"
                    v-bind:options="formatamount"
                    label="Guarantee Fee"
                    placeholder="Guarantee Fee"
                    readonly="readonly"
                    background-color="readonly"
                    v-bind:properties="properties"
                  />
                </v-col>
                <!-- Total Admin -->
                <v-col cols="12" sm="1" md="1" class="mt-n6">
                  <vuetify-money
                    v-model="form.ticket_adminrandom"
                    v-bind:options="formatamount"
                    label="3 Digit Payment"
                    placeholder="3 Digit Payment"
                    readonly="readonly"
                    background-color="readonly"
                    v-bind:properties="properties"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>

          <v-spacer></v-spacer>
          <v-divider></v-divider>

          <v-row class="mx-2 mt-5">
            <v-col cols="12" sm="12" md="12">
              <label class="caption">Description</label>
              <quill-editor
                ref="ticket_description"
                v-model="form.ticket_description"
              >
              </quill-editor>
            </v-col>

            <!-- <v-col cols="12" sm="12" md="12" class="mt-n3">
              <label class="caption">Remarks</label>
              <quill-editor ref="ticket_remarks"
                v-model="form.ticket_remarks"
              >
              </quill-editor>
            </v-col> -->

            <v-col cols="12" sm="12" md="12" class="mt-n3">
              <label class="caption">Solution</label>
              <quill-editor
                ref="ticket_solution"
                v-model="form.ticket_solution"
              >
              </quill-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
            >
              select all page
            </v-btn>

            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{ $vars.V("icon_search") }}</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="ticket_id"
            show-select
            class="elevation-1"
            :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.ticket_subclass="{ item }">
              {{ item.ticket_subclass }} - {{ item.ticket_subclassdesc }}
            </template>
            <template v-slot:item.ticket_campaignid="{ item }">
              {{ item.ticket_campaignid }} - {{ item.ticket_campaigndesc }}
            </template>
            <template v-slot:item.ticket_statusdesc="{ item }">
              {{ item.ticket_keydesc }} - {{ item.ticket_statusdesc }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.ticket_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse </v-icon
              >|
              <v-icon
                small
                @click="Confirm('delete', item.ticket_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth">
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
          >
            {{ confirm.text }}
          </v-btn>
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Start Organization -->
    <v-dialog
      v-model="dialog_organization"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog_organization = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Organization</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewOrganization" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmOrganization(url_type_organization, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_organization"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="12" class="mt-3">
                <app-cb
                  cb_type="code"
                  cb_url="apiListCode"
                  cb_url_parameter="code_actived=Y&code_group=PROFILEORGTYPE"
                  cb_title="Type"
                  cb_id="profileorganization_type"
                  cb_desc="profileorganization_typedesc"
                  cb_rules="Please fill Type"
                  cb_items_id="code_id"
                  cb_items_desc="code_desc"
                  :cb_value_id="form.profileorganization_type"
                  :cb_value_desc="form.profileorganization_typedesc"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n6">
                <v-text-field
                  v-model="form.profileorganization_name"
                  required
                  label="Name"
                  placeholder="Name"
                  id="profileorganization_name"
                  :rules="form.profileorganization_name_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n6">
                <v-text-field
                  v-model="form.profileorganization_title"
                  required
                  label="Title"
                  placeholder="Title"
                  id="profileorganization_title"
                  :rules="form.profileorganization_title_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <vuetify-money
                  v-model="form.profileorganization_capitalpct"
                  v-bind:options="formatrate"
                  label="Capital In Percentage"
                  placeholder="Capital In Percentage"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <vuetify-money
                  v-model="form.profileorganization_capitalamount"
                  v-bind:options="formatamount"
                  label="Capital In Amount"
                  placeholder="Capital In Amount"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
              <!-- Active -->
              <v-col cols="2" sm="2" md="2" class="mt-n5">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.profileorganization_actived"
                  color="primary"
                  id="form.profileorganization_actived"
                  :label="
                    form.profileorganization_actived !== true ? '' : 'Active'
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Organization -->
    <v-dialog
      v-model="dialog_portfolio"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog_portfolio = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Portfolio</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewPorto" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmPorto(url_type_porto, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_portfolio"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="12" class="mt-3">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_lob=01&profile_actived=Y"
                  cb_title="Obligee"
                  cb_id="profileporto_obligee"
                  cb_desc="profileporto_obligeedesc"
                  cb_rules="Please fill Obligee"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_fullname"
                  :cb_value_id="form.profileporto_obligee"
                  :cb_value_desc="form.profileporto_obligeedesc"
                >
                </app-cb>
              </v-col>
              <!-- Year -->
              <v-col cols="3" sm="3" md="3" class="mt-n6">
                <v-text-field
                  v-model="form.profileporto_year"
                  label="Year"
                  background-color="numeric"
                  reverse
                  :rules="form.profileporto_year_rules"
                  :maxlength="maxinput.year"
                >
                </v-text-field>
              </v-col>
              <v-col cols="9" sm="9" md="9" class="mt-n6">
                <v-text-field
                  v-model="form.profileporto_projectname"
                  label="Project Name"
                  :rules="form.profileporto_projectname_rules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mt-n6">
                <v-text-field
                  v-model="form.profileporto_location"
                  label="Project Location"
                  :rules="form.profileporto_location_rules"
                >
                </v-text-field>
              </v-col>
              <!-- CCY -->
              <v-col cols="4" sm="4" md="4" class="mt-n3">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  cb_title="In CCY"
                  cb_id="profileporto_ccy"
                  cb_desc="profileporto_ccydesc"
                  cb_rules=""
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="form.profileporto_ccy"
                  :cb_value_desc="form.profileporto_ccydesc"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>
              <!-- Project Amount -->
              <v-col cols="8" sm="8" md="8" class="mt-n6">
                <vuetify-money
                  v-model="form.profileporto_amount"
                  v-bind:options="formatamount"
                  label="Project Amount"
                  placeholder="Project Amount"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_scope"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog_scope = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Scope of Work</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewScope" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmScope(url_type_scope, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_scopeofwork"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="form.ticketscope_work"
                  label="Scope of Work"
                  :rules="form.ticketscope_work_rules"
                  id="ticketscope_work"
                >
                </v-text-field>
              </v-col>
              <v-col cols="10" sm="10" md="10">
                <v-textarea
                  v-model="form.ticketscope_remarks"
                  label="Remarks"
                  :rules="form.ticketscope_remarks_rules"
                >
                </v-textarea>
              </v-col>
              <!-- Active -->
              <v-col cols="2" sm="2" md="2" class="mt-n5">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.ticketscope_actived"
                  color="primary"
                  id="form.ticketscope_actived"
                  :label="form.ticketscope_actived !== true ? '' : 'Active'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_financialreport"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog_financialreport = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Financial Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewFinancialReport" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmFinancialReport(url_type_financialreport, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_financialreport"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="10" sm="10" md="10"> </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  v-model="form.profilecapital_year"
                  label="Year"
                  background-color="numeric"
                  reverse
                  :rules="form.profilecapital_year_rules"
                  :maxlength="maxinput.year"
                  id="profilecapital_year"
                  :readonly="form.profilecapital_year_readonly"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-auto">
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_currentasset"
                  v-bind:options="formatamount"
                  label="Current Asset"
                  placeholder="Current Asset"
                  background-color="numeric"
                  v-bind:properties="properties"
                  :rules="form.profilecapital_currentasset_rules"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_fixedasset"
                  v-bind:options="formatamount"
                  label="Fixed Asset"
                  placeholder="Fixed Asset"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_income"
                  v-bind:options="formatamount"
                  label="Income"
                  placeholder="Income"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_pl"
                  v-bind:options="formatamount"
                  label="Profit and Loss"
                  placeholder="Profit and Loss"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_liquidity"
                  v-bind:options="formatamount"
                  label="Liquidity"
                  placeholder="Liquidity"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_profitability"
                  v-bind:options="formatamount"
                  label="Profitability"
                  placeholder="Profitability"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_equity"
                  v-bind:options="formatamount"
                  label="Equity"
                  placeholder="Equity"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_noncurrliability"
                  v-bind:options="formatamount"
                  label="Non Current Liability"
                  placeholder="Non Current Liability"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
              <v-col cols="3" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model="form.profilecapital_currliability"
                  v-bind:options="formatamount"
                  label="Current Liability"
                  placeholder="Current Liability"
                  background-color="numeric"
                  v-bind:properties="properties"
                  @keyup.enter="
                    ConfirmFinancialReport(url_type_financialreport, '')
                  "
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_collateral"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog_collateral = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Collateral</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewCollateral" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmCollateral(url_type_collateral, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_collateral"
            lazy-validation
          >
            <v-row class="mx-auto">
              <v-col cols="12" sm="5" md="5" class="mt-3">
                <app-cb
                  cb_type="code"
                  cb_url="apiListCode"
                  cb_url_parameter="code_actived=Y&code_group=COLLATERAL"
                  cb_title="Category"
                  cb_id="ticketcollateral_category"
                  cb_desc="ticketcollateral_categorydesc"
                  cb_rules="Please fill Category"
                  cb_items_id="code_id"
                  cb_items_desc="code_desc"
                  :cb_value_id="form.ticketcollateral_category"
                  :cb_value_desc="form.ticketcollateral_categorydesc"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model="form.ticketcollateral_pct"
                  v-bind:options="formatrate"
                  label="In Percentage"
                  placeholder="In Percentage"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
              <!-- CCY -->
              <v-col cols="12" sm="2" md="2" class="mt-3">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  cb_title="In CCY"
                  cb_id="ticketcollateral_currency"
                  cb_desc="ticketcollateral_currencydesc"
                  cb_rules=""
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="form.ticketcollateral_currency"
                  :cb_value_desc="form.ticketcollateral_currencydesc"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <vuetify-money
                  v-model="form.ticketcollateral_amount"
                  v-bind:options="formatamount"
                  label="In Amount"
                  placeholder="In Amount"
                  background-color="numeric"
                  v-bind:properties="properties"
                />
              </v-col>
              <v-col cols="10" sm="10" md="10">
                <v-textarea
                  v-model="form.ticketcollateral_remarks"
                  label="Remarks"
                  :rules="form.ticketcollateral_remarks_rules"
                >
                </v-textarea>
              </v-col>
              <!-- Active -->
              <v-col cols="2" sm="2" md="2" class="mt-n5">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.ticketcollateral_actived"
                  color="primary"
                  id="form.ticketcollateral_actived"
                  :label="
                    form.ticketcollateral_actived !== true ? '' : 'Active'
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_organization.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_organization.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_organization.text === 'Ok' ? false : true"
            @click="LoadingOrganization"
            >{{ confirm_organization.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_organization.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_portfolio.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_portfolio.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_portfolio.text === 'Ok' ? false : true"
            @click="LoadingPorto"
            >{{ confirm_portfolio.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_portfolio.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_scope.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_scope.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_scope.text === 'Ok' ? false : true"
            @click="LoadingScope"
            >{{ confirm_scope.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_scope.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_financialreport.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_financialreport.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_financialreport.text === 'Ok' ? false : true"
            @click="LoadingFinancialReport"
            >{{ confirm_financialreport.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_financialreport.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_collateral.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_collateral.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_collateral.text === 'Ok' ? false : true"
            @click="LoadingCollateral"
            >{{ confirm_collateral.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_collateral.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.solution" max-width="890" persistent>
      <v-card>
        <v-card-title class="primary white--text">Underwrite</v-card-title>
        <v-card-text>
          <v-form
            enctype="multipart/form-data"
            ref="form_underwrite"
            lazy-validation
          >
            <v-row class="mx-2 ma-1">
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="form.ticketlog_from"
                  required
                  :readonly="true"
                  background-color="readonly"
                  label="From *"
                  placeholder="From"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  v-model="form.ticketlog_subject"
                  required
                  :readonly="true"
                  background-color="readonly"
                  label="Subject *"
                  placeholder="Subject"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n6">
                <v-text-field
                  v-model="form.ticketlog_to"
                  required
                  :readonly="true"
                  background-color="readonly"
                  label="To *"
                  placeholder="To"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" class="mt-n6">
                <v-text-field
                  v-model="form.ticketlog_cc"
                  required
                  :readonly="true"
                  background-color="readonly"
                  label="CC *"
                  placeholder="CC"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mt-n3">
                <label class="caption">Remarks</label>
                <quill-editor
                  ref="ticket_bodyremarks"
                  v-model="form.ticketlog_body"
                >
                </quill-editor>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mt-n3">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  v-on:vdropzone-file-added="AppendFile"
                  :options="dropzoneOptions"
                ></vue-dropzone>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="dialog" color="secondary" @click="Response('R')"
            >Request Data</v-btn
          >
          <v-btn id="dialog" color="success" @click="Response('C')"
            >Confirm</v-btn
          >
          <v-btn id="dialog" color="warning" @click="Response('J')"
            >Reject</v-btn
          >
          <v-btn id="dialog" color="primary" @click="Response('A')"
            >Approve</v-btn
          >
          <v-btn id="dialog" color="purple" @click="Response('F')"
            >Facultative</v-btn
          >
          <v-btn color="error" @click="dialog.solution = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.response"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ response.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="response.text === 'Ok' ? false : true"
            @click="ResponseLoading"
          >
            {{ response.text }}
          </v-btn>
          <v-btn
            :color="Variable('confirm', 2)"
            @click="dialog.response = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-print
      v-model="dialog.print"
      modul="acceptance"
      :id="form.ticket_id"
    ></app-print>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>

<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 20,
      desc: 150,
      year: 4,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "ticket_id",
      },
      { text: "Date", value: "ticket_date" },
      { text: "Subject", value: "ticket_subject" },
      { text: "Sub Class", value: "ticket_subclass" },
      { text: "Campaign", value: "ticket_campaignid" },
      { text: "PIC", value: "ticket_pic" },
      { text: "Status", value: "ticket_statusdesc" },
      { text: "Actions", value: "action", sortable: false },
    ],
    selected_log: [],
    search_log: "",
    headers_log: [
      { text: "Date", value: "ticketlog_cdate" },
      { text: "User", value: "ticketlog_cuser" },
      { text: "Status", value: "ticketlog_keydesc" },
      { text: "Sender", value: "ticketlog_sender" },
      { text: "Recipient", value: "ticketlog_recipient" },
      { text: "CC", value: "ticketlog_cc" },
      { text: "Subject", value: "ticketlog_subject" },
      { text: "Body", value: "ticketlog_body" },
    ],
    items_log: [],
    confirm_organization: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    btn_search_organization: false,
    dialog_organization: false,
    url_type_organization: "add",
    selected_organization: [],
    search_organization: "",
    headers_organization: [
      { text: "ID", value: "profileorganization_id" },
      { text: "Type", value: "profileorganization_type" },
      { text: "Name", value: "profileorganization_name" },
      { text: "Title", value: "profileorganization_title" },
      { text: "Capital PCT", value: "profileorganization_capitalpct" },
      { text: "Capital Amount", value: "profileorganization_capitalamount" },
      // { text: 'Actions', value: 'action', sortable: false }
    ],
    items_organization: [],
    confirm_portfolio: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    btn_search_portfolio: false,
    dialog_portfolio: false,
    url_type_porto: "add",
    selected_portfolio: [],
    search_portfolio: "",
    headers_portfolio: [
      { text: "ID", value: "profileporto_id" },
      { text: "Year", value: "profileporto_year" },
      { text: "Obligee", value: "profileporto_obligee" },
      { text: "Project Name", value: "profileporto_projectname" },
      { text: "Location", value: "profileporto_location" },
      { text: "CCY", value: "profileporto_ccy" },
      { text: "Amount", value: "profileporto_amount", align: "right" },
      // { text: 'Actions', value: 'action', sortable: false }
    ],
    items_portfolio: [],
    confirm_scope: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    btn_search_scope: false,
    dialog_scope: false,
    url_type_scope: "add",
    selected_scope: [],
    search_scope: "",
    headers_scope: [
      { text: "ID", value: "ticketscope_id" },
      { text: "Work", value: "ticketscope_work" },
      { text: "Remarks", value: "ticketscope_remarks" },
      // { text: 'Actions', value: 'action', sortable: false }
    ],
    items_scope: [],
    confirm_collateral: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    btn_search_collateral: false,
    dialog_collateral: false,
    url_type_collateral: "add",
    selected_collateral: [],
    search_collateral: "",
    headers_collateral: [
      { text: "ID", value: "ticketcollateral_id" },
      { text: "Category", value: "ticketcollateral_categorydesc" },
      { text: "Remarks", value: "ticketcollateral_remarks" },
      { text: "PCT", value: "ticketcollateral_pct" },
      { text: "Amount", value: "ticketcollateral_amount" },
      // { text: 'Actions', value: 'action', sortable: false }
    ],
    items_collateral: [],
    remarks: {
      feedback: "",
      dialog: false,
    },
    confirm_financialreport: {
      dialog: false,
      title: "",
      text: "Ok",
    },

    dialog_financialreport: false,
    url_type_financialreport: "add",
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      attachment: false,
      riskloss: false,
      organization: false,
      portfolio: false,
      financialreport: false,
      scope: false,
      log: false,
      collateral: false,
      underwrite: false,
      calculate: false,
      scoring: false,
    },
    btn_search: false,
    //Format Number
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0,
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2,
    },
    properties: {
      reverse: true,
    },
    form: {
      rangeyear: false,
      ticket_campaignid: "",
      ticket_campaigndesc: "",
      ticket_id: "",
      ticket_id_rules: [(v) => !!v || "Please fill ID"],
      ticket_id_readonly: false,
      ticket_subject: "",
      ticket_subject_rules: [(v) => !!v || "Please fill Description"],
      ticket_subject_readonly: false,
      ticket_date_readonly: false,
      ticket_targetdate_readonly: false,
      ticket_pic: "",
      ticket_rate: 0,
      ticket_rateunit: "C",
      ticket_ccy: "",
      ticket_tsi: 0,
      ticket_guaranteevalue: 0,
      ticket_guaranteefee: 0,
      ticket_pcttsi: 0,
      ticket_multiyear: false,
      ticket_wordingcustom: false,
      ticket_attachment: "",
      ticket_pholderid: "",
      ticket_pholderdesc: "",
      ticket_pholderaddress: "",
      ticket_profileid: "",
      ticket_profiledesc: "",
      ticket_profileaddress: "",
      ticket_profilebirthdate: "",
      ticket_bank: "",
      ticket_bankdesc: "",
      ticket_bankaddress: "",
      ticket_duration: 0,
      ticket_durationday: 1,
      ticket_durationmonth: 0,
      ticket_policytype: "",
      ticket_policytypedesc: "",
      ticket_channel: "",
      ticket_channeldesc: "",
      ticket_instype: "",
      ticket_instypedesc: "",
      ticket_status: "",
      ticket_statusdesc: "",
      ticket_key: "",
      ticket_keydesc: "",
      ticket_feeid: "",
      ticket_feedesc: "",
      ticket_feeamount: 0,
      ticket_sourceid: "",
      ticket_sourcefee: 0,
      ticket_sourcetax: 0,
      ticket_sourceaddress: "",
      ticket_projectname: "",
      ticket_projectbasedon: "",
      ticket_projectdate: "",
      ticket_adminrandom: 0,
      ticket_indemnity: 0,
      ticket_subclass: "",
      ticket_mo: "",
      profileorganization_id: "",
      profileorganization_type: "",
      profileorganization_name: "",
      profileorganization_name_rules: [(v) => !!v || "Please fill Name"],
      profileorganization_title: "",
      profileorganization_title_rules: [(v) => !!v || "Please fill Title"],
      profileorganization_capitalpct: 0,
      profileorganization_capitalamount: 0,
      profileorganization_actived: true,
      profileporto_id: "",
      profileporto_obligee: "",
      profileporto_obligeedesc: "",
      profileporto_year: "",
      profileporto_year_rules: [(v) => !!v || "Please fill Year"],
      profileporto_projectname: "",
      profileporto_projectname_rules: [
        (v) => !!v || "Please fill Project Name",
      ],
      profileporto_location: "",
      profileporto_location_rules: [
        (v) => !!v || "Please fill Project Location",
      ],
      profileporto_ccy: "IDR",
      profileporto_amount: 0,
      profileporto_cuser: "",
      profileporto_cdate: "",
      profileporto_luser: "",
      profileporto_ldate: "",
      ticketscope_work: "",
      ticketscope_work_rules: [(v) => !!v || "Please fill Scope of Work"],
      ticketscope_remarks: "",
      ticketscope_remarks_rules: [(v) => !!v || "Please fill Remarks"],
      ticketscope_actived: true,
      ticketcollateral_currency: "IDR",
      ticketcollateral_pct: 0,
      ticketcollateral_amount: 0,
      ticketcollateral_remarks: "",
      ticketcollateral_remarks_rules: [(v) => !!v || "Please fill Remarks"],
      ticketcollateral_actived: true,
      ticket_extendday: 0,
      profilecapital_rangeyear: "2",
      profilecapital_id: "",
      profilecapital_year_readonly: false,
      profilecapital_year: "",
      profilecapital_year_rules: [(v) => !!v || "Please fill Year"],
      profilecapital_currentasset: 0,
      profilecapital_currentasset_rules: [
        (v) => !!v || "Please fill Current Asset",
      ],
      profilecapital_fixedasset: 0,
      profilecapital_fixedasset_rules: [
        (v) => !!v || "Please fill Fixed Asset",
      ],
      profilecapital_income: 0,
      profilecapital_income_rules: [(v) => !!v || "Please fill Income"],
      profilecapital_pl: 0,
      profilecapital_pl_rules: [(v) => !!v || "Please fill Profit and Loss"],
      profilecapital_liquidity: 0,
      profilecapital_liquidity_rules: [(v) => !!v || "Please fill Liquidity"],
      profilecapital_profitability: 0,
      profilecapital_profitability_rules: [
        (v) => !!v || "Please fill Profitability",
      ],
      profilecapital_noncurrliability: 0,
      profilecapital_noncurrliability_rules: [
        (v) => !!v || "Please fill Non Current Liability",
      ],
      profilecapital_currliability: 0,
      profilecapital_currliability_rules: [
        (v) => !!v || "Please fill Current Liability",
      ],
      profilecapital_equity: 0,
      profilecapital_ratioliquidity: 0,
      profilecapital_ratioprofitability: 0,
      profilecapital_ratiosolvability: 0,
      ticketlog_from: "",
      ticketlog_subject: "",
      ticketlog_to: "",
      ticketlog_cc: "",
      ticketlog_body: "",
      display_users: "",
    },
    detail: {
      attachment: [],
      attachmenttype: [],
      financialreport: [],
      capitalreport: [],
    },
    dialog: {
      users: false,
      print: false,
      solution: false,
      response: false,
    },
    modal: {
      date: false,
      date_time: false,
      ticket_inception: false,
      ticket_expiry: false,
      ticket_projectdate: false,
    },
    response: {
      title: "",
      text: "Ok",
      key: "",
    },
    dropzoneOptions: {
      url: "/",
      thumbnailWidth: 200,
      autoProcessQueue: false,
      uploadMultiple: true,
      maxFiles: 10,
      addRemoveLinks: true,
      acceptedFiles: ".xls,.xlsx,.doc,.docx,.pdf,.jpeg,.jpg,.png,.eml",
    },
    formresponse: new FormData(),
    toggle: {
      source: 0,
    },
    scoring: {
      condition: [],
      character: [],
      capacity: [],
      capital: [],
      collateral: [],
    },
    countscoring: {
      condition_point: 0,
      condition_subtypedata: 0,
      character_point: 0,
      character_subtypedata: 0,
      capacity_point: 0,
      capacity_subtypedata: 0,
      capital_point: 0,
      capital_subtypedata: 0,
      collateral_point: 0,
      collateral_subtypedate: 0,
    },
    datascoring: [],
  }),

  created() {
    this.FirstLoad();
  },

  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "acceptance";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    SelectAllPageOrganization() {
      this.selected_organization =
        this.selected_organization === this.items_organization
          ? []
          : this.items_organization;
    },
    CheckOrganization(value) {
      this.selected_organization = value;
    },
    SelectAllPagePorto() {
      this.selected_portfolio =
        this.selected_portfolio === this.items_portfolio
          ? []
          : this.items_portfolio;
    },
    CheckPorto(value) {
      this.selected_portfolio = value;
    },
    SelectAllPageScope() {
      this.selected_scope =
        this.selected_scope === this.items_scope ? [] : this.items_scope;
    },
    CheckScope(value) {
      this.selected_scope = value;
    },
    SelectAllPageCollateral() {
      this.selected_collateral =
        this.selected_collateral === this.items_collateral
          ? []
          : this.items_collateral;
    },
    CheckCollateral(value) {
      this.selected_collateral = value;
    },
    //Function for get all data from table Ticket with API in mod_users.go
    List() {
      this.loading.list = true;
      let formdata = {
        ticket_id: "",
        ticket_subject: "",
        order_by: "ticket_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id) {
      if (flag === "add" || flag === "edit" || flag === "acceptance") {
        if (this.$refs.form_ticket.validate()) {
          this.url_type = "acceptance";
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.ticket_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    //Function to request insert data to table Ticket 'The API will validating your data, include the user who process the data' (go to apiWriteTicket with url_type ="add")
    Add() {
      this.url_type = "add";
      this.form.ticket_id = "";
      this.form.ticket_subject = "";
      this.form.ticket_pic = true;
      this.form.ticket_id_readonly = false;
      setTimeout(function () {
        document.getElementById("ticket_id").focus();
      }, 500);
    },
    //Function to request update data to table Ticket 'The API will validating your data, include the user who process the data' (go to apiWriteTicket with url_type ="edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        ticket_id: id,
        ticket_subject: "",
        order_by: "ticket_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();
              this.url_type = "edit";
              this.form.ticket_id = feedback[0].ticket_id;
              this.form.ticket_campaignid = feedback[0].ticket_campaignid;
              this.form.ticket_campaigndesc = feedback[0].ticket_campaigndesc;
              this.form.ticket_date = feedback[0].ticket_date;
              this.form.ticket_targetdate = feedback[0].ticket_targetdate;
              this.form.ticket_name = feedback[0].ticket_name;
              this.form.ticket_subject = feedback[0].ticket_subject;
              this.form.ticket_pic = feedback[0].ticket_pic;
              this.form.ticket_mo = feedback[0].ticket_mo;
              this.form.ticket_source = feedback[0].ticket_source;
              this.form.ticket_sourcedesc = feedback[0].ticket_sourcedesc;
              this.form.ticket_rate = feedback[0].ticket_rate;
              this.form.ticket_rateunit = feedback[0].ticket_rateunit;
              this.form.ticket_ccy = feedback[0].ticket_ccy;
              this.form.ticket_tsi = feedback[0].ticket_tsi;
              this.form.ticket_pcttsi = feedback[0].ticket_pcttsi;
              this.form.ticket_guaranteevalue =
                feedback[0].ticket_guaranteevalue;
              this.form.ticket_guaranteefee = feedback[0].ticket_guaranteefee;
              this.form.ticket_description = feedback[0].ticket_description;
              this.form.ticket_remarks = feedback[0].ticket_remarks;
              this.form.ticket_solution = feedback[0].ticket_solution;
              this.form.ticket_inception = feedback[0].ticket_inception;
              this.form.ticket_expiry = feedback[0].ticket_expiry;
              this.form.ticket_durationday = feedback[0].ticket_durationday;
              this.form.ticket_durationmonth = feedback[0].ticket_durationmonth;
              this.form.ticket_pholderid = feedback[0].ticket_pholderid;
              this.form.ticket_pholderdesc = feedback[0].ticket_pholderdesc;
              this.form.ticket_pholderaddress =
                feedback[0].ticket_pholderaddress;
              this.form.ticket_profileid = feedback[0].ticket_profileid;
              this.form.ticket_profiledesc = feedback[0].ticket_profiledesc;
              this.form.ticket_profileaddress =
                feedback[0].ticket_profileaddress;
              this.form.ticket_profilebirthdate =
                feedback[0].ticket_profilebirthdate;
              this.form.ticket_bank = feedback[0].ticket_bank;
              this.form.ticket_bankdesc = feedback[0].ticket_bankdesc;
              this.form.ticket_bankaddress = feedback[0].ticket_bankaddress;
              this.form.ticket_riskcov = feedback[0].ticket_riskcov;
              this.form.ticket_riskcovdesc = feedback[0].ticket_riskcovdesc;
              this.form.ticket_interest = feedback[0].ticket_interest;
              this.form.ticket_interestdesc = feedback[0].ticket_interestdesc;
              this.form.ticket_interestdesc = feedback[0].ticket_interestdesc;
              this.form.ticket_policytype = feedback[0].ticket_policytype;
              this.form.ticket_policytypedesc =
                feedback[0].ticket_policytypedesc;
              this.form.ticket_channel = feedback[0].ticket_channel;
              this.form.ticket_channeldesc = feedback[0].ticket_channeldesc;
              this.form.ticket_instype = feedback[0].ticket_instype;
              this.form.ticket_instypedesc = feedback[0].ticket_instypedesc;
              this.form.ticket_feeid = feedback[0].ticket_feeid;
              this.form.ticket_feedesc = feedback[0].ticket_feedesc;
              this.form.ticket_feeamount = feedback[0].ticket_feeamount;
              this.form.ticket_dutyid = feedback[0].ticket_dutyid;
              this.form.ticket_dutydesc = feedback[0].ticket_dutydesc;
              this.form.ticket_dutyamount = feedback[0].ticket_dutyamount;
              this.form.ticket_sourceid = feedback[0].ticket_sourceid;
              this.form.ticket_sourcename = feedback[0].ticket_sourcename;
              this.form.ticket_sourceaddress = feedback[0].ticket_sourceaddress;
              this.form.ticket_sourcefee = feedback[0].ticket_sourcefee;
              this.form.ticket_sourcetax = feedback[0].ticket_sourcetax;
              this.form.ticket_status = feedback[0].ticket_status;
              this.form.ticket_statusdesc = feedback[0].ticket_statusdesc;
              this.form.ticket_key = feedback[0].ticket_key;
              this.form.ticket_keydesc = feedback[0].ticket_keydesc;
              this.form.ticket_projectname = feedback[0].ticket_projectname;
              this.form.ticket_projectbasedon =
                feedback[0].ticket_projectbasedon;
              this.form.ticket_projectdate = feedback[0].ticket_projectdate;
              this.form.ticket_indemnity = feedback[0].ticket_indemnity;
              this.form.ticket_subclass =
                feedback[0].ticket_subclass +
                "-" +
                feedback[0].ticket_subclassdesc;
              this.form.ticket_cdate = feedback[0].ticket_cdate;
              this.form.ticket_cuser = feedback[0].ticket_cuser;
              this.form.ticket_ldate = feedback[0].ticket_ldate;
              this.form.ticket_luser = feedback[0].ticket_luser;
              this.form.ticket_id_readonly = true;
              this.form.ticket_subject_readonly = true;
              this.form.ticket_date_readonly = true;
              this.form.ticket_targetdate_readonly = true;
              this.form.ticket_sourcedesc_readonly = true;
              this.form.ticket_pic_readonly = true;
              this.form.ticket_mo_readonly = true;
              this.form.ticket_principal_readonly = true;
              if (this.form.ticket_source === "E") {
                this.toggle.source = 0;
              }
              this.ListLog(feedback[0].ticket_id);
              // this.ListOrganization(feedback[0].ticket_profileid)
              // this.ListCollateral(feedback[0].ticket_id)
              // this.ListScope(feedback[0].ticket_id)
              // this.ListPorto(feedback[0].ticket_profileid)
              // this.ListFinancialReport(feedback[0].ticket_profileid)
              // this.Attachment(feedback[0].ticket_id)
              // this.Scoring('5C04')
              setTimeout(function () {
                document.getElementById("ticket_subject").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        ticket_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (
        this.url_type === "add" ||
        this.url_type === "edit" ||
        this.url_type === "acceptance"
      ) {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.ticket_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      }
    },
    Write() {
      let ticket_pholderid = document.getElementById("ticket_pholderid").value;
      let ticket_profileid = document.getElementById("ticket_profileid").value;
      let ticket_bank = document.getElementById("ticket_bank").value;
      let ticket_riskcov = document.getElementById("ticket_riskcov").value;
      let ticket_riskcovdesc =
        document.getElementById("ticket_riskcovdesc").value;
      let ticket_interest = document.getElementById("ticket_interest").value;
      let ticket_interestdesc = document.getElementById(
        "ticket_interestdesc"
      ).value;
      let ticket_policytype =
        document.getElementById("ticket_policytype").value;
      let ticket_policytypedesc = document.getElementById(
        "ticket_policytypedesc"
      ).value;
      let ticket_channel = document.getElementById("ticket_channel").value;
      let ticket_channeldesc =
        document.getElementById("ticket_channeldesc").value;
      let ticket_instype = document.getElementById("ticket_instype").value;
      let ticket_instypedesc =
        document.getElementById("ticket_instypedesc").value;
      let ticket_sourceid = document.getElementById("ticket_sourceid").value;
      let ticket_sourcedesc =
        document.getElementById("ticket_sourcedesc").value;
      let ticket_feeid = document.getElementById("ticket_feeid").value;
      let ticket_feedesc = document.getElementById("ticket_feedesc").value;
      let ticket_dutyid = document.getElementById("ticket_dutyid").value;
      let ticket_dutydesc = document.getElementById("ticket_dutydesc").value;
      let ticket_ccy = document.getElementById("ticket_ccy").value;
      let formdata = {
        url_type: this.url_type,
        ticket_id: this.form.ticket_id,
        ticket_inception: this.form.ticket_inception,
        ticket_expiry: this.form.ticket_expiry,
        ticket_pholderid: ticket_pholderid,
        ticket_profileid: ticket_profileid,
        ticket_bank: ticket_bank,
        ticket_riskcov: ticket_riskcov,
        ticket_riskcovdesc: ticket_riskcovdesc,
        ticket_interest: ticket_interest,
        ticket_interestdesc: ticket_interestdesc,
        ticket_policytype: ticket_policytype,
        ticket_policytypedesc: ticket_policytypedesc,
        ticket_channel: ticket_channel,
        ticket_channeldesc: ticket_channeldesc,
        ticket_instype: ticket_instype,
        ticket_instypedesc: ticket_instypedesc,
        ticket_rate: String(this.form.ticket_rate),
        ticket_rateunit: this.form.ticket_rateunit,
        ticket_guaranteevalue: String(this.form.ticket_guaranteevalue),
        ticket_guaranteefee: String(this.form.ticket_guaranteefee),
        ticket_tsi: String(this.form.ticket_tsi),
        ticket_pcttsi: String(this.form.ticket_pcttsi),
        ticket_wordingcustom: String(this.form.ticket_wordingcustom),
        ticket_durationday: String(this.form.ticket_durationday),
        ticket_durationmonth: String(this.form.ticket_durationmonth),
        ticket_extendday: String(this.form.ticket_extendday),
        ticket_sourceid: ticket_sourceid,
        ticket_sourcedesc: ticket_sourcedesc,
        ticket_feeid: ticket_feeid,
        ticket_feedesc: ticket_feedesc,
        ticket_dutyid: ticket_dutyid,
        ticket_dutydesc: ticket_dutydesc,
        ticket_sourcefee: String(this.form.ticket_sourcefee),
        ticket_sourcetax: String(this.form.ticket_sourcetax),
        ticket_feeamount: String(this.form.ticket_feeamount),
        ticket_dutyamount: String(this.form.ticket_dutyamount),
        ticket_adminrandom: String(this.form.ticket_adminrandom),
        ticket_indemnity: String(this.form.ticket_indemnity),
        ticket_projectname: this.form.ticket_projectname,
        ticket_projectbasedon: this.form.ticket_projectbasedon,
        ticket_projectdate: this.form.ticket_projectdate,
        ticket_ccy: ticket_ccy,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicket"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.ticket_cuser = feedback[0].feedback_users_id;
                this.form.ticket_cdate = feedback[0].feedback_users_date;
              }
              this.form.ticket_luser = feedback[0].feedback_users_id;
              this.form.ticket_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ShowUsers(id) {
      this.dialog.users = true;
      this.form.display_users = id;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
    Calculate() {
      let rate = this.form.ticket_rate;
      let tsi = this.form.ticket_tsi;
      let share = this.form.ticket_pcttsi;
      let sharepct = share / 100;
      let ratepct = rate / 100;
      let shareamount = sharepct * tsi;
      let guarantee = ratepct * tsi * sharepct;
      this.form.ticket_guaranteevalue = shareamount;
      this.form.ticket_guaranteefee = guarantee;
    },
    CalculateTSI() {
      let gv = this.form.ticket_guaranteevalue;
      let tsi = this.form.ticket_tsi;
      let share = (gv / tsi) * 100;
      this.form.ticket_pcttsi = share;
      this.Calculate();
    },
    Attachment(id) {
      this.loading.attachment = true;
      let formdata = {
        attachment_modul: "cbc",
        attachment_refno: id,
        order_by: "attachment_id",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListAttachment"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.detail.attachment = feedback;
              this.AttachmentType();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
          this.loading.attachment = false;
        })
        .catch((e) => {
          this.loading.attachment = false;
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    AttachmentType() {
      let formdata = {
        code_group: "DOCUMENT",
        code_actived: "Y",
        order_by: "code_desc",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCode"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.detail.attachmenttype = feedback;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    UpdateAttachType(flag, item) {
      let type = item.attachment_type.code_id;
      if (flag === "delete") {
        type = "";
      }
      this.loading.attachment = true;
      let formdata = {
        url_type: "edittype",
        attachment_id: item.attachment_id,
        attachment_type: type,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteAttachment"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.Attachment(this.form.ticket_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
            this.loading.attachment = false;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.attachment = false;
        });
    },
    GetPholder(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let address = splits[3];
        this.form.ticket_pholderaddress = address;
      }
    },
    GetProfile(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let address = splits[3];
        let birthdate = splits[4];
        this.form.ticket_profileaddress = address;
        this.form.ticket_profilebirthdate = birthdate;
      }
    },
    GetBank(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let address = splits[3];
        this.form.ticket_bankaddress = address;
      }
    },
    GetFee(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let amount = splits[3];
        this.form.ticket_feeamount = amount;
      }
    },
    GetDuty(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let amount = splits[3];
        this.form.ticket_dutyamount = amount;
      }
    },
    GetBizSource(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let address = splits[3];
        let taxpct = splits[4];
        this.form.ticket_sourceaddress = address;
        this.form.ticket_sourcetax = taxpct;
      }
    },
    AddNewFinancialReport() {
      this.dialog_financialreport = true;
      this.url_type_financialreport = "add";
      this.form.profilecapital_year_readonly = false;
      this.form.profilecapital_year = "";
      this.form.profilecapital_currentasset = 0;
      this.form.profilecapital_fixedasset = 0;
      this.form.profilecapital_income = 0;
      this.form.profilecapital_pl = 0;
      this.form.profilecapital_liquidity = 0;
      this.form.profilecapital_profitability = 0;
      this.form.profilecapital_noncurrliability = 0;
      this.form.profilecapital_currliability = 0;
      this.form.profilecapital_equity = 0;
      setTimeout(function () {
        document.getElementById("profilecapital_year").focus();
      }, 500);
    },
    EditFinancialReport(id) {
      this.dialog_financialreport = true;
      let formdata = {
        profilecapital_profileid: this.form.ticket_profileid,
        profilecapital_year: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfileCapital"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type_financialreport = "edit";
              this.form.profilecapital_id = feedback[0].profilecapital_id;
              this.form.profilecapital_year_readonly = true;
              this.form.profilecapital_year = feedback[0].profilecapital_year;
              this.form.profilecapital_currentasset =
                feedback[0].profilecapital_currentasset;
              this.form.profilecapital_fixedasset =
                feedback[0].profilecapital_fixedasset;
              this.form.profilecapital_income =
                feedback[0].profilecapital_income;
              this.form.profilecapital_pl = feedback[0].profilecapital_pl;
              this.form.profilecapital_liquidity =
                feedback[0].profilecapital_liquidity;
              this.form.profilecapital_profitability =
                feedback[0].profilecapital_profitability;
              this.form.profilecapital_noncurrliability =
                feedback[0].profilecapital_noncurrliability;
              this.form.profilecapital_currliability =
                feedback[0].profilecapital_currliability;
              this.form.profilecapital_equity =
                feedback[0].profilecapital_equity;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // Step 2
    IEDMOnChange(flag) {
      this.loading.riskloss = true;
      let day = parseInt(this.form.ticket_durationday);
      let month = parseInt(this.form.ticket_durationmonth);
      let formdata = {
        url_type: flag,
        calculate_inception: this.form.ticket_inception,
        calculate_expiry: this.form.ticket_expiry,
        calculate_day: day,
        calculate_month: month,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCalculate"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.ticket_inception = feedback[0].calculate_inception;
              this.form.ticket_expiry = feedback[0].calculate_expiry;
              this.form.ticket_durationday = feedback[0].calculate_day;
              this.form.ticket_durationmonth = feedback[0].calculate_month;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.riskloss = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.riskloss = false;
        });
    },
    ListLog(id) {
      this.loading.log = true;
      let formdata = {
        ticketlog_ticketid: id,
        order_by: "ticketlog_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketLog"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items_log = feedback;
            }
          } else {
            this.items_log = feedback;
          }
          this.loading.log = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.log = false;
        });
    },
    LoadingOrganization() {
      this.confirm_organization.text = "Loading...";
      if (
        this.url_type_organization === "add" ||
        this.url_type_organization === "edit"
      ) {
        this.WriteOrganization();
      } else if (this.url_type_organization === "delete") {
        this.DeleteOrganization(this.form.profileorganization_id);
      } else if (this.url_type_organization === "multidelete") {
        this.MultiProcessOrganization("delete");
      }
    },
    WriteOrganization() {
      let profileorganization_type = document.getElementById(
        "profileorganization_type"
      ).value;
      let formdata = {
        url_type: this.url_type_organization,
        profileorganization_profileid: this.form.ticket_profileid,
        profileorganization_type: profileorganization_type,
        profileorganization_name: this.form.profileorganization_name,
        profileorganization_title: this.form.profileorganization_title,
        profileorganization_capitalpct: String(
          this.form.profileorganization_capitalpct
        ),
        profileorganization_capitalamount: String(
          this.form.profileorganization_capitalamount
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfileOrganization"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog_organization = false;
              this.confirm_organization.dialog = false;
              this.confirm_organization.text = "Ok";
              if (this.url_type_organization === "add") {
                this.form.profileorganization_cuser =
                  feedback[0].feedback_users_id;
                this.form.profileorganization_cdate =
                  feedback[0].feedback_users_date;
              }
              this.ListOrganization(this.form.ticket_profileid);
              this.form.profileorganization_luser =
                feedback[0].feedback_users_id;
              this.form.profileorganization_ldate =
                feedback[0].feedback_users_date;
              this.url_type_organization = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_organization.dialog = false;
              this.confirm_organization.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    DeleteOrganization(id) {
      let formdata = {
        url_type: "delete",
        profileorganization_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfileOrganization"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm_organization.dialog = false;
              this.confirm_organization.text = "Ok";
              this.ListOrganization(this.form.ticket_profileid);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_organization.dialog = false;
              this.confirm_organization.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_organization.dialog = false;
          this.confirm_organization.text = "Ok";
        });
    },
    AddNewOrganization() {
      this.dialog_organization = true;
      this.url_type_organization = "add";
      this.form.profileorganization_type = "";
      this.form.profileorganization_typedesc = "";
      this.form.profileorganization_name = "";
      this.form.profileorganization_title = "";
      this.form.profileorganization_capitalpct = 0;
      this.form.profileorganization_capitalamount = 0;
      setTimeout(function () {
        document.getElementById("profileorganization_type").focus();
      }, 500);
    },
    ConfirmOrganization(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_organization.validate()) {
          this.confirm_organization.dialog = true;
          this.confirm_organization.title = "Save";
          this.url_type_organization = "add";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type_organization = "delete";
        this.confirm_organization.dialog = true;
        this.confirm_organization.title = "Delete `" + id + "`";
        this.form.profileorganization_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type_organization = "multidelete";
        this.confirm_organization.dialog = true;
        this.confirm_organization.title =
          "Delete `" + this.selected_organization.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    ListOrganization(id) {
      this.items_organization = [];
      if (id !== "") {
        this.loading.organization = true;
        let formdata = {
          profileorganization_profileid: id,
          order_by: "profileorganization_profileid",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListProfileOrganization"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items_organization = feedback;
                this.ListScoring(this.form.ticket_id, "5C02");
              }
            } else {
              this.items_organization = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.organization = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.organization = false;
          });
      }
    },
    LoadingPorto() {
      this.confirm_portfolio.text = "Loading...";
      if (this.url_type_porto === "add" || this.url_type_porto === "edit") {
        this.WritePorto();
      } else if (this.url_type_porto === "delete") {
        this.DeletePorto(this.form.profileporto_id);
      } else if (this.url_type_porto === "multidelete") {
        this.MultiProcessPorto("delete");
      }
    },
    WritePorto() {
      let profileporto_obligee = document.getElementById(
        "profileporto_obligee"
      ).value;
      let profileporto_ccy = document.getElementById("profileporto_ccy").value;
      let formdata = {
        url_type: this.url_type_porto,
        profileporto_profileid: this.form.ticket_profileid,
        profileporto_projectname: this.form.profileporto_projectname,
        profileporto_obligee: profileporto_obligee,
        profileporto_location: this.form.profileporto_location,
        profileporto_ccy: profileporto_ccy,
        profileporto_amount: String(this.form.profileporto_amount),
        profileporto_year: this.form.profileporto_year,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfilePorto"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog_portfolio = false;
              this.confirm_portfolio.dialog = false;
              this.confirm_portfolio.text = "Ok";
              if (this.url_type_porto === "add") {
                this.form.profileporto_cuser = feedback[0].feedback_users_id;
                this.form.profileporto_cdate = feedback[0].feedback_users_date;
              }
              this.ListPorto(this.form.ticket_profileid);
              this.form.profileporto_luser = feedback[0].feedback_users_id;
              this.form.profileporto_ldate = feedback[0].feedback_users_date;
              this.url_type_porto = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_portfolio.dialog = false;
              this.confirm_portfolio.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    DeletePorto(id) {
      let formdata = {
        url_type: "delete",
        profileporto_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfilePorto"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm_portfolio.dialog = false;
              this.confirm_portfolio.text = "Ok";
              this.ListPorto(this.form.ticket_profileid);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_portfolio.dialog = false;
              this.confirm_portfolio.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_portfolio.dialog = false;
          this.confirm_portfolio.text = "Ok";
        });
    },
    AddNewPorto() {
      this.dialog_portfolio = true;
      this.url_type_porto = "add";
      this.form.profileporto_year = "";
      this.form.profileporto_projectname = "";
      this.form.profileporto_location = "";
      this.form.profileporto_ccy = "";
      this.form.profileporto_amount = 0;
      setTimeout(function () {
        document.getElementById("profileporto_obligee").focus();
      }, 500);
    },
    ConfirmPorto(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_portfolio.validate()) {
          this.confirm_portfolio.dialog = true;
          this.confirm_portfolio.title = "Save";
          this.url_type_porto = "add";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type_porto = "delete";
        this.confirm_portfolio.dialog = true;
        this.confirm_portfolio.title = "Delete `" + id + "`";
        this.form.profileporto_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type_porto = "multidelete";
        this.confirm_portfolio.dialog = true;
        this.confirm_portfolio.title =
          "Delete `" + this.selected_portfolio.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    ListPorto(id) {
      this.items_portfolio = [];
      if (id !== "") {
        this.loading.portfolio = true;
        let formdata = {
          profileporto_profileid: id,
          order_by: "profileporto_profileid",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListProfilePorto"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items_portfolio = feedback;
                this.ListScoring(this.form.ticket_id, "5C03");
              }
            } else {
              this.items_portfolio = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.portfolio = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.portfolio = false;
          });
      }
    },
    LoadingScope() {
      this.confirm_scope.text = "Loading...";
      if (this.url_type_scope === "add" || this.url_type_scope === "edit") {
        this.WriteScope();
      } else if (this.url_type_scope === "delete") {
        this.DeleteScope(this.form.ticketscope_id);
      } else if (this.url_type_scope === "multidelete") {
        this.MultiProcessScope("delete");
      }
    },
    WriteScope() {
      let formdata = {
        url_type: this.url_type_scope,
        ticketscope_ticketid: this.form.ticket_id,
        ticketscope_work: this.form.ticketscope_work,
        ticketscope_remarks: this.form.ticketscope_remarks,
        ticketscope_actived: this.$functions.ActivedFlag(
          this.form.ticketscope_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicketScope"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog_scope = false;
              this.confirm_scope.dialog = false;
              this.confirm_scope.text = "Ok";
              if (this.url_type_scope === "add") {
                this.form.ticketscope_cuser = feedback[0].feedback_users_id;
                this.form.ticketscope_cdate = feedback[0].feedback_users_date;
              }
              this.ListScope(this.form.ticket_id);
              this.form.ticketscope_luser = feedback[0].feedback_users_id;
              this.form.ticketscope_ldate = feedback[0].feedback_users_date;
              this.url_type_scope = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_scope.dialog = false;
              this.confirm_scope.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
            this.confirm_scope.dialog = false;
            this.confirm_scope.text = "Ok";
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_scope.dialog = false;
          this.confirm_scope.text = "Ok";
        });
    },
    DeleteScope(id) {
      let formdata = {
        url_type: "delete",
        ticketscope_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicketScope"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm_scope.dialog = false;
              this.confirm_scope.text = "Ok";
              this.ListScope(this.form.ticket_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_scope.dialog = false;
              this.confirm_scope.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_scope.dialog = false;
          this.confirm_scope.text = "Ok";
        });
    },
    AddNewScope() {
      this.dialog_scope = true;
      this.url_type_scope = "add";
      this.form.ticketscope_work = "";
      this.form.ticketscope_remarks = "";
      this.form.ticketscope_actived = true;
      setTimeout(function () {
        document.getElementById("ticketscope_work").focus();
      }, 500);
    },
    ConfirmScope(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_scopeofwork.validate()) {
          this.confirm_scope.dialog = true;
          this.confirm_scope.title = "Save";
          this.url_type_scope = flag;
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type_scope = "delete";
        this.confirm_scope.dialog = true;
        this.confirm_scope.title = "Delete `" + id + "`";
        this.form.ticketscope_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type_scope = "multidelete";
        this.confirm_scope.dialog = true;
        this.confirm_scope.title =
          "Delete `" + this.selected_scope.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    ListScope(id) {
      this.loading.scope = true;
      let formdata = {
        ticketscope_ticketid: id,
        order_by: "ticketscope_ticketid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketScope"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items_scope = feedback;
            }
          } else {
            this.items_scope = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.scope = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.scope = false;
        });
    },
    ConfirmFinancialReport(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_financialreport.validate()) {
          console.log(id);
          this.confirm_financialreport.dialog = true;
          this.confirm_financialreport.title = "Save";
          this.url_type_financialreport = flag;
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }
    },
    LoadingFinancialReport() {
      this.confirm_financialreport.text = "Loading...";
      if (
        this.url_type_financialreport === "add" ||
        this.url_type_financialreport === "edit"
      ) {
        this.WriteFinancialReport();
      }
    },
    ListFinancialReport(id) {
      this.loading.financialreport = true;
      let formdata = {
        profilecapital_profileid: id,
        profilecapital_rangeyear: this.form.profilecapital_rangeyear,
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfileCapitalGroup"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.detail.financialreport = feedback;
              this.ListCapitalReport(id);
              this.ListScoring(this.form.ticket_id, "5C04");
            }
          } else {
            this.detail.financialreport = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.financialreport = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.financialreport = false;
        });
    },
    WriteFinancialReport() {
      let formdata = {
        url_type: this.url_type_financialreport,
        profilecapital_profileid: this.form.ticket_profileid,
        profilecapital_id: this.form.profilecapital_id,
        profilecapital_year: String(this.form.profilecapital_year),
        profilecapital_currentasset: String(
          this.form.profilecapital_currentasset
        ),
        profilecapital_fixedasset: String(this.form.profilecapital_fixedasset),
        profilecapital_income: String(this.form.profilecapital_income),
        profilecapital_pl: String(this.form.profilecapital_pl),
        profilecapital_liquidity: String(this.form.profilecapital_liquidity),
        profilecapital_profitability: String(
          this.form.profilecapital_profitability
        ),
        profilecapital_noncurrliability: String(
          this.form.profilecapital_noncurrliability
        ),
        profilecapital_currliability: String(
          this.form.profilecapital_currliability
        ),
        profilecapital_equity: String(this.form.profilecapital_equity),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteProfileCapital"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog_financialreport = false;
              this.confirm_financialreport.dialog = false;
              this.confirm_financialreport.text = "Ok";
              if (this.url_type_financialreport === "add") {
                this.form.profilecapital_cuser = feedback[0].feedback_users_id;
                this.form.profilecapital_cdate =
                  feedback[0].feedback_users_date;
              }
              this.ListFinancialReport(this.form.ticket_profileid);
              this.form.profilecapital_luser = feedback[0].feedback_users_id;
              this.form.profilecapital_ldate = feedback[0].feedback_users_date;
              this.url_type_financialreport = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_financialreport.dialog = false;
              this.confirm_financialreport.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
            this.confirm_financialreport.dialog = false;
            this.confirm_financialreport.text = "Ok";
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_scope.dialog = false;
          this.confirm_scope.text = "Ok";
        });
    },
    LoadingCollateral() {
      this.confirm_collateral.text = "Loading...";
      if (
        this.url_type_collateral === "add" ||
        this.url_type_collateral === "edit"
      ) {
        this.WriteCollateral();
      } else if (this.url_type_collateral === "delete") {
        this.DeleteCollateral(this.form.ticketcollateral_id);
      } else if (this.url_type_collateral === "multidelete") {
        this.MultiProcessCollateral("delete");
      }
    },
    WriteCollateral() {
      let ticketcollateral_category = document.getElementById(
        "ticketcollateral_category"
      ).value;
      let ticketcollateral_currency = document.getElementById(
        "ticketcollateral_currency"
      ).value;
      let formdata = {
        url_type: this.url_type_collateral,
        ticketcollateral_ticketid: this.form.ticket_id,
        ticketcollateral_category: ticketcollateral_category,
        ticketcollateral_currency: ticketcollateral_currency,
        ticketcollateral_pct: String(this.form.ticketcollateral_pct),
        ticketcollateral_amount: String(this.form.ticketcollateral_amount),
        ticketcollateral_remarks: this.form.ticketcollateral_remarks,
        ticketcollateral_actived: this.$functions.ActivedFlag(
          this.form.ticketcollateral_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicketCollateral"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.dialog_collateral = false;
              this.confirm_collateral.dialog = false;
              this.confirm_collateral.text = "Ok";
              if (this.url_type_collateral === "add") {
                this.form.ticketcollateral_cuser =
                  feedback[0].feedback_users_id;
                this.form.ticketcollateral_cdate =
                  feedback[0].feedback_users_date;
              }
              this.ListCollateral(this.form.ticket_id);
              this.form.ticketcollateral_luser = feedback[0].feedback_users_id;
              this.form.ticketcollateral_ldate =
                feedback[0].feedback_users_date;
              this.url_type_collateral = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_collateral.dialog = false;
              this.confirm_collateral.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
            this.confirm_collateral.dialog = false;
            this.confirm_collateral.text = "Ok";
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_collateral.dialog = false;
          this.confirm_collateral.text = "Ok";
        });
    },
    DeleteCollateral(id) {
      let formdata = {
        url_type: "delete",
        ticketcollateral_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicketCollateral"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm_collateral.dialog = false;
              this.confirm_collateral.text = "Ok";
              this.ListCollateral(this.form.ticket_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_collateral.dialog = false;
              this.confirm_collateral.text = "Ok";
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.confirm_collateral.dialog = false;
          this.confirm_collateral.text = "Ok";
        });
    },
    AddNewCollateral() {
      this.dialog_collateral = true;
      this.url_type_collateral = "add";
      this.form.ticketcollateral_category = "";
      this.form.ticketcollateral_categorydesc = "";
      this.form.ticketcollateral_pct = 0;
      this.form.ticketcollateral_amount = 0;
      this.form.ticketcollateral_remarks = "";
      this.form.ticketcollateral_actived = true;
      setTimeout(function () {
        document.getElementById("ticketcollateral_category").focus();
      }, 500);
    },
    ConfirmCollateral(flag, id) {
      if (flag === "add" || flag === "edit") {
        if (this.$refs.form_collateral.validate()) {
          this.confirm_collateral.dialog = true;
          this.confirm_collateral.title = "Save";
          this.url_type_collateral = flag;
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type_collateral = "delete";
        this.confirm_collateral.dialog = true;
        this.confirm_collateral.title = "Delete `" + id + "`";
        this.form.ticketcollateral_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type_collateral = "multidelete";
        this.confirm_collateral.dialog = true;
        this.confirm_collateral.title =
          "Delete `" + this.selected_collateral.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    ListCollateral(id) {
      this.loading.collateral = true;
      let formdata = {
        ticketcollateral_ticketid: id,
        order_by: "ticketcollateral_ticketid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketCollateral"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items_collateral = feedback;
            }
          } else {
            this.items_collateral = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.ListScoring(id, "5C05");
          this.loading.collateral = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.collateral = false;
        });
    },
    Print() {
      this.dialog.print = true;
    },
    OpenRangeYear() {
      this.ListFinancialReport(this.form.ticket_profileid);
    },
    Response(flag) {
      if (this.form.ticketlog_body === "") {
        this.SnackBar(true, "error", "Please fill Remarks", 3000);
      } else {
        this.dialog.response = true;
        if (flag === "C") {
          this.response.key = flag;
          this.response.title = "Confirm";
        } else if (flag === "R") {
          this.response.key = flag;
          this.response.title = "Request Data";
        } else if (flag === "J") {
          this.response.key = flag;
          this.response.title = "Reject";
        } else if (flag === "A") {
          this.response.key = flag;
          this.response.title = "Approve";
        } else if (flag === "F") {
          this.response.key = flag;
          this.response.title = "Facultative";
        }
      }
    },
    ResponseLoading() {
      this.SendSolution();
    },
    Solution() {
      this.loading.underwrite = true;
      let formdata = {
        ticketlog_ticketid: this.form.ticket_id,
        order_by: "ticketlog_cdate",
        order_type: "DESC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketLog"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.dialog.solution = true;
              this.form.ticketlog_from = this.$functions.UsersID();
              this.form.ticketlog_subject = feedback[0].ticketlog_subject;
              this.form.ticketlog_to = feedback[0].ticketlog_sender;
              this.form.ticketlog_cc = feedback[0].ticketlog_cc;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.underwrite = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.underwrite = false;
        });
    },
    SendSolution() {
      this.response.text = "Loading...";
      this.formresponse.append("url_type", "edit");
      this.formresponse.append("ticket_key", this.response.key);
      this.formresponse.append("ticket_id", this.form.ticket_id);
      this.formresponse.append("ticket_from", this.form.ticketlog_from);
      this.formresponse.append("ticket_to", this.form.ticketlog_to);
      this.formresponse.append("ticket_cc", this.form.ticketlog_cc);
      this.formresponse.append("ticket_body", this.form.ticketlog_body);
      this.$axios
        .post(
          this.$functions.SafeURL("apiWriteTicketResponse", ``),
          this.formresponse,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.Edit(this.form.ticket_id);
              this.SnackBar(true, "success", "Process Success", 0);
              this.dialog.solution = false;
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          }
          this.dialog.response = false;
          this.response.text = "Ok";
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.dialog.response = false;
          this.response.text = "Ok";
        });
    },
    AppendFile(file) {
      this.formresponse.append("file", file);
    },
    CalculateFee() {
      this.loading.calculate = true;
      let formdata = {
        calculate_flag: "ticket",
        calculate_id: this.form.ticket_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiCalculateRiskLogic"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.form.ticket_rate = feedback[0].feedback_rate_min;
              this.form.ticket_rateunit = feedback[0].feedback_rateunit;
              this.CalculateTSI();
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.calculate = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.calculate = false;
        });
    },
    ListScoring(ticketid, type) {
      let formdata = {
        ticketscoring_ticketid: ticketid,
        ticketscoring_type: type,
        order_by: "ticketscoring_score",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              const groupedData = {};
              // Loop through the JSON data and group it by "ticketscoring_subtype"
              feedback.forEach((item) => {
                const subtype = item.ticketscoring_subtype;

                if (!groupedData[subtype]) {
                  // If the subtype doesn't exist in groupedData, initialize it as an empty array
                  groupedData[subtype] = [];
                }

                // Push the current item into the corresponding subtype array
                groupedData[subtype].push(item);
              });

              // Convert the grouped data object into an array of objects
              const groupedArray = Object.keys(groupedData).map((subtype) => ({
                ticketscoring_subtype: subtype,
                items: groupedData[subtype],
              }));
              if (type === "5C01") {
                this.scoring.condition = groupedArray;
              } else if (type === "5C02") {
                this.scoring.character = groupedArray;
              } else if (type === "5C03") {
                this.scoring.capacity = groupedArray;
              } else if (type === "5C04") {
                this.scoring.capital = groupedArray;
              } else if (type === "5C05") {
                this.scoring.collateral = groupedArray;
              }
              this.CountScore(type);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    ListScoringData(ticketid) {
      this.loading.scoring = true;
      let formdata = {
        ticketscoring_ticketid: ticketid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiCountTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.datascoring = feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.scoring = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.scoring = false;
        });
    },
    SendScore(type, subtype, title, score) {
      let formdata = {
        url_type: "edit",
        ticketscoring_ticketid: this.form.ticket_id,
        ticketscoring_type: type,
        ticketscoring_subtype: subtype,
        ticketscoring_title: title,
        ticketscoring_point: String(score),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.SnackBar(true, "success", "Success Update Scoring", 2000);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    CountScore(type) {
      let formdata = {
        ticketscoring_ticketid: this.form.ticket_id,
        ticketscoring_type: type,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiCountTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              if (type === "5C01") {
                this.countscoring.condition_point =
                  feedback[0].ticketscoring_point;
                this.countscoring.condition_subtypedata =
                  feedback[0].ticketscoring_subtypedata;
              } else if (type === "5C02") {
                this.countscoring.character_point =
                  feedback[0].ticketscoring_point;
                this.countscoring.character_subtypedata =
                  feedback[0].ticketscoring_subtypedata;
              } else if (type === "5C03") {
                this.countscoring.capacity_point =
                  feedback[0].ticketscoring_point;
                this.countscoring.capacity_subtypedata =
                  feedback[0].ticketscoring_subtypedata;
              } else if (type === "5C04") {
                this.countscoring.capital_point =
                  feedback[0].ticketscoring_point;
                this.countscoring.capital_subtypedata =
                  feedback[0].ticketscoring_subtypedata;
              } else if (type === "5C05") {
                this.countscoring.collateral_point =
                  feedback[0].ticketscoring_point;
                this.countscoring.collateral_subtypedate =
                  feedback[0].ticketscoring_subtypedata;
              }
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    OpenScoring(id) {
      if (id === "5C01") {
        document.getElementById("tabriskloss").click();
      } else if (id === "5C02") {
        document.getElementById("taborganization").click();
      } else if (id === "5C03") {
        document.getElementById("tabportfolio").click();
      } else if (id === "5C04") {
        document.getElementById("tabfinancialreport").click();
      } else if (id === "5C05") {
        document.getElementById("tabcollateral").click();
      }
    },
    ListCapitalReport(id) {
      let formdata = {
        profilecapital_profileid: id,
        limit: "2",
        order_by: "profilecapital_year",
        order_type: "DESC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListProfileCapital"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              console.log(feedback);
              this.detail.capitalreport = feedback;
            }
          } else {
            this.detail.capitalreport = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
  },
};
</script>
<style scoped>
.border {
  border: 2px solid #607d8b !important;
}
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 65px !important;
}
.v-btn--absolute.v-btn--left,
.v-btn--fixed.v-btn--left {
  left: -26px !important;
}
</style>
