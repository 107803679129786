var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app-attachment-detail"}},[(_vm.loading.page)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"fourth","rounded":""}}):_vm._e(),(_vm.access.read === 0)?_c('div',{staticClass:"text-center font-weight-black"},[_vm._v(" "+_vm._s(_vm.$vars.V("txt_no_access"))+" ")]):_c('div',[_c('v-card-title',{staticClass:"ml-n4"},[_c('v-spacer'),(_vm.btn_search)?_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"click:append":function($event){_vm.btn_search = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"attachment_id","header-props":{ 'sort-icon': 'mdi-arrow-down-circle' },"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right',
      },"dense":"","loading":_vm.loading.detail,"hide-default-header":"","loading-text":"Loading... Please wait"},on:{"input":function($event){return _vm.Check($event)}},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,staticClass:"secondary white--text"},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])]}},{key:"item.attachment_path",fn:function({ item }){return [_c('v-icon',{on:{"click":function($event){return _vm.$functions.OpenURL(item.attachment_path_url)}}},[_vm._v("download")])]}}])})],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar.timeout,"color":"fourth","rounded":"pill","top":"","absolute":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [(_vm.snackbar.color === 'error')?_c('v-btn',{attrs:{"dark":"","text":"","small":""},nativeOn:{"click":function($event){_vm.remarks.dialog = true}}},[_vm._v("Open")]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":"","small":""},nativeOn:{"click":function($event){_vm.snackbar.dialog = false}}},[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.dialog),callback:function ($$v) {_vm.$set(_vm.snackbar, "dialog", $$v)},expression:"snackbar.dialog"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }