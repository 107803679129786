<template>
  <div>
    <app-drawer_menu drawer_menu_title="Class of Business"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="white" color="fourth" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad"> Form </v-tab>
      <v-tab href="#tab-list" @click="List"> List </v-tab>
      <v-tab-item value="tab-form">
        <v-form enctype="multipart/form-data" ref="form_class" lazy-validation>
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{ $vars.V("icon_add") }}</v-icon
                >{{ $vars.V("txt_add") }}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
                >{{ $vars.V("txt_save") }}
              </v-btn>
            </v-col>
            <!-- ID -->
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="form.class_id"
                required
                :rules="form.class_id_rules"
                :readonly="form.class_id_readonly"
                :background-color="form.class_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="class_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Description -->
            <v-col cols="12" sm="6" md="8">
              <v-text-field
                v-model="form.class_desc"
                required
                :rules="form.class_desc_rules"
                :maxlength="maxinput.desc"
                label="Description *"
                placeholder="Description"
                id="class_desc"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.class_actived"
                color="primary"
                id="form.class_actived"
                :label="form.class_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <label class="caption">Email Quotation ENG *</label>
              <quill-editor
                ref="class_email_quo_eng"
                v-model="form.class_email_quo_eng"
              ></quill-editor>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <label class="caption">Email Quotation IND *</label>
              <quill-editor
                ref="class_email_quo_ind"
                v-model="form.class_email_quo_ind"
              ></quill-editor>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont.class" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont.class"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.class_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.class_cuser)">{{form.class_cuser}}</span>
Last Update Date : {{form.class_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.class_luser)">{{form.class_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
          <v-tabs
            background-color="white"
            color="secondary"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            v-show="url_type !== 'add' ? true : false"
          >
            <v-tab
              id="tabclause"
              href="#tab-clause"
              @click="ListClassTC(form.class_id, 'CL')"
            >
              Clause
            </v-tab>
            <v-tab
              id="tabcondition"
              href="#tab-condition"
              @click="ListClassTC(form.class_id, 'CO')"
            >
              Condition
            </v-tab>
            <v-tab
              id="tabexclusion"
              href="#tab-exclusion"
              @click="ListClassTC(form.class_id, 'EX')"
            >
              Exclusion
            </v-tab>
            <v-tab-item value="tab-condition">
              <!-- Data Table Class TC Condition -->
              <template>
                <!-- Main Button Class TC Condition -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      classtc.selected.length === classtc.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllClassTC"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenClassTC('CO')"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideleteclasstc', '')"
                    class="mr-2"
                    v-if="classtc.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="classtc.searchbutton === false"
                    @click="SearchClassTC"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="classtc.search"
                    append-icon="search"
                    @click:append="classtc.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="classtc.searchbutton"
                    id="searchclasstc"
                    clearable
                    @keyup.enter="
                      ListClassTC(form.class_id, form.classtc_category)
                    "
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Class TC Condition -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="classtc.selected"
                  :headers="classtc.headers"
                  :items="ItemsClassTC"
                  :search="classtc.search"
                  @input="CheckClassTC($event)"
                  item-key="classtc_row"
                  show-select
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listclasstc"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditClassTC(item.classtc_id, item.classtc_classid)
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deleteclasstc',
                          item.classtc_id,
                          item.classtc_classid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Class TC Condition -->
            </v-tab-item>
            <v-tab-item value="tab-clause">
              <!-- Data Table Class TC Clause -->
              <template>
                <!-- Main Button Class TC Clause -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      classtc.selected.length === classtc.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllClassTC"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenClassTC('CL')"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideleteclasstc', '')"
                    class="mr-2"
                    v-if="classtc.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="classtc.searchbutton === false"
                    @click="SearchClassTC"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="classtc.search"
                    append-icon="search"
                    @click:append="classtc.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="classtc.searchbutton"
                    id="searchclasstc"
                    clearable
                    @keyup.enter="
                      ListClassTC(form.class_id, form.classtc_category)
                    "
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Class TC Clause -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="classtc.selected"
                  :headers="classtc.headers"
                  :items="ItemsClassTC"
                  :search="classtc.search"
                  @input="CheckClassTC($event)"
                  item-key="classtc_row"
                  show-select
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listclasstc"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditClassTC(item.classtc_id, item.classtc_classid)
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deleteclasstc',
                          item.classtc_id,
                          item.classtc_classid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Class TC Clause -->
            </v-tab-item>
            <v-tab-item value="tab-exclusion">
              <!-- Data Table Class TC Exclusion -->
              <template>
                <!-- Main Button Class TC Exclusion -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      classtc.selected.length === classtc.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllClassTC"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenClassTC('EX')"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideleteclasstc', '')"
                    class="mr-2"
                    v-if="classtc.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="classtc.searchbutton === false"
                    @click="SearchClassTC"
                    small
                  >
                    <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="classtc.search"
                    append-icon="search"
                    @click:append="classtc.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="classtc.searchbutton"
                    id="searchclasstc"
                    clearable
                    @keyup.enter="
                      ListClassTC(form.class_id, form.classtc_category)
                    "
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Class TC Exclusion -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="classtc.selected"
                  :headers="classtc.headers"
                  :items="ItemsClassTC"
                  :search="classtc.search"
                  @input="CheckClassTC($event)"
                  item-key="classtc_row"
                  show-select
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listclasstc"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditClassTC(item.classtc_id, item.classtc_classid)
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deleteclasstc',
                          item.classtc_id,
                          item.classtc_classid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Class TC Exclusion -->
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{ $vars.V("txt_select_all_page") }}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{ $vars.V("icon_search") }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="class_id"
            show-select
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.class_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse </v-icon
              >|
              <v-icon
                small
                @click="Confirm('delete', item.class_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <!-- Dialog Class TC -->
    <v-dialog
      v-model="dialog.classtc"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="1600"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('classtc')">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Class TC</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="AddClassTC(form.classtc_category)"
            title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.classtcdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_classtc"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <!-- ID -->
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="form.classtc_id"
                  required
                  :rules="form.classtc_id_rules"
                  :readonly="form.classtc_id_readonly"
                  :background-color="form.classtc_id_readonly ? 'readonly' : ''"
                  :maxlength="maxinput.tcid"
                  :counter="maxinput.tcid"
                  label="ID *"
                  placeholder="ID"
                  id="classtc_id"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.classtc_actived"
                  color="primary"
                  id="form.classtc_actived"
                  :label="form.classtc_actived !== true ? '' : 'Active'"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.classtc_shortdesc_eng"
                  required
                  :rules="form.classtc_shortdesc_eng_rules"
                  :maxlength="maxinput.short_desc"
                  label="Short Desc ENG *"
                  placeholder="Short Desc ENG"
                  id="classtc_shortdesc_eng"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.classtc_shortdesc_ind"
                  required
                  :rules="form.classtc_shortdesc_ind_rules"
                  :maxlength="maxinput.short_desc"
                  label="Short Desc IND *"
                  placeholder="Short Desc IND"
                  id="classtc_shortdesc_ind"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <label class="caption">Long Desc ENG *</label>
                <quill-editor
                  ref="classtc_longdesc_eng"
                  v-model="form.classtc_longdesc_eng"
                >
                </quill-editor>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <label class="caption">Long Desc IND *</label>
                <quill-editor
                  ref="classtc_longdesc_ind"
                  v-model="form.classtc_longdesc_ind"
                >
                </quill-editor>
              </v-col>
            </v-row>

            <!-- Info Class TC -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addclasstc' ? true : false">
                  <v-checkbox
                    v-model="cont.classtc"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.classtc"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
              Created Date 	 : {{form.classtc_cdate}}
              Created User 	 : <span class="cursor" @click="ShowUsers(form.classtc_cuser)">{{form.classtc_cuser}}</span>
              Last Update Date : {{form.classtc_ldate}}
              Last Update User : <span class="cursor" @click="ShowUsers(form.classtc_luser)">{{form.classtc_luser}}</span>
              </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Class TC -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Class TC -->

    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}
          </v-btn>
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: {
      class: false,
      classtc: false,
    },
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 4,
      tcid: 10,
      desc: 150,
      short_desc: 255,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "class_id",
      },
      { text: "Description", value: "class_desc" },
      { text: "Actived", value: "class_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      listclasstc: false,
      classtcdialog: false,
    },
    btn_search: false,
    form: {
      class_id: "",
      class_desc: "",
      class_id_readonly: false,
      class_actived: true,
      class_email_quo_eng: "",
      class_email_quo_ind: "",
      display_users: "",

      class_id_rules: [(v) => !!v || "Please fill ID"],
      class_desc_rules: [(v) => !!v || "Please fill Description"],

      // Class TC
      classtc_id: "",
      classtc_classid: "",
      classtc_category: "",
      classtc_actived: true,
      classtc_shortdesc_eng: "",
      classtc_longdesc_eng: "",
      classtc_shortdesc_ind: "",
      classtc_longdesc_ind: "",

      classtc_id_par: "",
    },
    // Data Table Class TC
    classtc: {
      headers: [
        { text: "ID", value: "classtc_id" },
        { text: "Short Eng", value: "classtc_shortdesc_eng" },
        { text: "Short Ind", value: "classtc_shortdesc_ind" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Class TC
    dialog: {
      users: false,
      classtc: false,
    },
    flag: {
      classtc: false,
    },
  }),
  created() {
    this.FirstLoad();
  },
  computed: {
    ItemsClassTC() {
      for (let i = 0; i <= this.classtc.items.length - 1; i++) {
        const classtcindex = this.classtc.items[i];
        classtcindex.classtc_row =
          this.classtc.items[i].classtc_classid +
          "|" +
          this.classtc.items[i].classtc_id +
          "|";
      }
      return this.classtc.items;
    },
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "class";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = priv[0].feedback;
            } else {
              this.access.read = priv[0].usersmenu_read;
              this.access.add = priv[0].usersmenu_add;
              this.access.edit = priv[0].usersmenu_edit;
              this.access.delete = priv[0].usersmenu_delete;
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                );
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    //Function for get all data from table channel with API in mod_users.go
    List() {
      this.loading.list = true;
      let formdata = {
        class_id: "",
        class_desc: "",
        order_by: "class_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id, par1) {
      if (flag === "write") {
        if (this.$refs.form_class.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.class_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "addclasstc" || flag === "editclasstc") {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deleteclasstc") {
        this.url_type = "deleteclasstc";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.classtc_classid = par1;
        this.classtc_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideleteclasstc") {
        this.url_type = "multideleteclasstc";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.classtc.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },

    //Function to request insert data to table channel 'The API will validating your data, include the user who process the data' (go to APIWriteClass with url_type = "add")
    Add() {
      this.url_type = "add";
      this.form.class_id = "";
      this.form.class_desc = "";
      this.form.class_actived = true;
      this.form.class_id_readonly = false;
      this.form.class_email_quo_ind = "";
      this.form.class_email_quo_eng = "";
      setTimeout(function () {
        document.getElementById("class_id").focus();
      }, 500);
    },
    //Function to request update data to table channel 'The API will validating your data, include the user who process the data' (go to APIWriteClass with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        class_id: id,
        class_desc: "",
        order_by: "class_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();
              this.url_type = "edit";
              this.form.class_id = feedback[0].class_id;
              this.form.class_desc = feedback[0].class_desc;
              this.form.class_actived = this.$functions.TrueOrFalse(
                feedback[0].class_actived
              );
              this.form.class_cdate = feedback[0].class_cdate;
              this.form.class_cuser = feedback[0].class_cuser;
              this.form.class_ldate = feedback[0].class_ldate;
              this.form.class_luser = feedback[0].class_luser;
              this.form.class_email_quo_eng = feedback[0].class_email_quo_eng;
              this.form.class_email_quo_ind = feedback[0].class_email_quo_ind;
              this.form.class_id_readonly = true;

              if (this.form.classtc_category !== "") {
                this.ListClassTC(
                  this.form.class_id,
                  this.form.classtc_category
                );
              } else {
                this.ListClassTC(this.form.class_id, "CL");
              }
              setTimeout(function () {
                document.getElementById("class_desc").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        class_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.class_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (
        this.url_type === "addclasstc" ||
        this.url_type === "editclasstc"
      ) {
        this.WriteClassTC();
      } else if (this.url_type === "deleteclasstc") {
        this.DeleteClassTC(this.classtc_id, this.classtc_classid);
      } else if (this.url_type === "multideleteclasstc") {
        this.MultiProcessClassTC();
      }
    },
    Close(flag) {
      if (flag === "classtc") {
        this.url_type = "edit";
        this.dialog.classtc = false;
        this.cont.classtc = false;
        this.ListClassTC(this.form.class_id, this.form.classtc_category);
      }
    },
    Write() {
      let formdata = {
        url_type: this.url_type,
        class_id: this.form.class_id,
        class_desc: this.form.class_desc,
        class_email_quo_eng: this.form.class_email_quo_eng,
        class_email_quo_ind: this.form.class_email_quo_ind,
        class_actived: this.$functions.ActivedFlag(this.form.class_actived),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.class_cuser = feedback[0].feedback_users_id;
                this.form.class_cdate = feedback[0].feedback_users_date;
              }
              this.form.class_luser = feedback[0].feedback_users_id;
              this.form.class_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ShowUsers(id) {
      this.dialog.users = true;
      this.form.display_users = id;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
    // Function Class TC
    SelectAllClassTC() {
      this.classtc.selected =
        this.classtc.selected === this.classtc.items ? [] : this.classtc.items;
    },

    ListClassTC(id, category) {
      this.loading.listclasstc = true;
      this.form.classtc_category = category;

      this.classtc.searchbutton = false;
      this.classtc.search = "";

      this.classtc.selected = [];

      let formdata = {
        classtc_classid: id,
        classtc_category: category,
        order_by: "classtc_classid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListClassTC"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.classtc.items = feedback;
            }
          } else {
            this.classtc.items = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.listclasstc = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listclasstc = false;
        });
    },

    CheckClassTC(value) {
      this.classtc.selected = value;
    },

    SearchClassTC() {
      this.classtc.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchclasstc").focus();
      }, 500);
    },

    OpenClassTC(category) {
      this.dialog.classtc = true;
      this.AddClassTC(category);
    },

    AddClassTC(category) {
      this.url_type = "addclasstc";
      this.form.classtc_classid = "";
      this.form.classtc_id = "";
      this.form.classtc_category = category;
      this.form.classtc_shortdesc_eng = "";
      this.form.classtc_longdesc_eng = "";
      this.form.classtc_shortdesc_ind = "";
      this.form.classtc_longdesc_ind = "";
      this.form.classtc_actived = true;

      this.flag.classtc = true;
      this.dialog.classtc = true;
    },

    WriteClassTC() {
      let formdata = {
        url_type: this.url_type,
        classtc_id: this.form.classtc_id,
        classtc_classid: this.form.class_id,
        classtc_category: this.form.classtc_category,
        classtc_shortdesc_eng: this.form.classtc_shortdesc_eng,
        classtc_longdesc_eng: this.form.classtc_longdesc_eng,
        classtc_shortdesc_ind: this.form.classtc_shortdesc_ind,
        classtc_longdesc_ind: this.form.classtc_longdesc_ind,
        classtc_actived: this.$functions.ActivedFlag(this.form.classtc_actived),
        classtc_id_par: this.form.classtc_id_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClassTC"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addclasstc") {
                this.form.classtc_cuser = feedback[0].feedback_users_id;
                this.form.classtc_cdate = feedback[0].feedback_users_date;
              }
              this.form.classtc_luser = feedback[0].feedback_users_id;
              this.form.classtc_ldate = feedback[0].feedback_users_date;
              this.url_type = "editclasstc";
              this.dialog.classtc = false;
              this.ListClassTC(this.form.class_id, this.form.classtc_category);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditClassTC(id, classid) {
      this.flag.classtc = true;
      this.dialog.classtc = true;
      this.loading.classtcdialog = true;
      let formdata = {
        classtc_id: id,
        classtc_classid: classid,
        order_by: "classtc_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListClassTC"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editclasstc";

              this.form.classtc_classid = feedback[0].classtc_classid;
              this.form.classtc_id = feedback[0].classtc_id;
              this.form.classtc_classid = feedback[0].classtc_classid;
              this.form.classtc_shortdesc_eng =
                feedback[0].classtc_shortdesc_eng;
              this.form.classtc_longdesc_eng = feedback[0].classtc_longdesc_eng;
              this.form.classtc_shortdesc_ind =
                feedback[0].classtc_shortdesc_ind;
              this.form.classtc_longdesc_ind = feedback[0].classtc_longdesc_ind;

              this.form.classtc_id_par = feedback[0].classtc_id;

              this.form.classtc_cdate = feedback[0].classtc_cdate;
              this.form.classtc_cuser = feedback[0].classtc_cuser;
              this.form.classtc_ldate = feedback[0].classtc_ldate;
              this.form.classtc_luser = feedback[0].classtc_luser;
              this.dialog.classtc = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.classtcdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.classtcdialog = false;
        });
    },

    DeleteClassTC(id, classid) {
      let formdata = {
        url_type: "deleteclasstc",
        classtc_id: id,
        classtc_classid: classid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClassTC"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.classtc.selected = [];
              this.ListClassTC(this.form.class_id, this.form.classtc_category);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessClassTC() {
      let multiData = JSON.stringify(this.classtc.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClassTC"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.classtc.selected = [];
            if (this.url_type === "multideleteclasstc") {
              this.ListClassTC(this.form.class_id, this.form.classtc_category);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // end: Function Class TC
  },
};
</script>
