<!-- Module Campaign -->
<template>
  <div>
    <app-drawer_menu drawer_menu_title="Campaign"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="primary" color="white" v-if="access.read === 1">
      <v-tab id="tabform" ref="tabform" href="#tab-form" @click="FirstLoad">
        Form
      </v-tab>
      <v-tab href="#tab-list" @click="List"> List </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_campaign"
          lazy-validation
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>

            <!-- Sub Class -->
            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="subclass"
                cb_url="apiListSubClass"
                cb_url_parameter="subclass_actived=Y"
                cb_title="Subclass"
                cb_id="campaign_subclass"
                cb_desc="campaign_subclass_desc"
                cb_rules="Please fill Subclass"
                cb_desc_readonly="readonly"
                cb_items_id="subclass_id"
                cb_items_desc="subclass_desc"
                :cb_value_id="form.campaign_subclass"
                :cb_value_desc="form.campaign_subclass_desc"
                cb_items_additional_1="subclass_ujroh"
                @clicked="GetSubClass"
              >
              </app-cb>
            </v-col>

            <!-- ID -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model="form.campaign_id"
                required
                :rules="form.campaign_id_rules"
                :readonly="form.campaign_id_readonly"
                :background-color="form.campaign_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="<<Auto Generated ID>>"
                id="campaign_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Description -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model="form.campaign_desc"
                required
                :rules="form.campaign_desc_rules"
                :maxlength="maxinput.desc"
                label="Description *"
                placeholder="Description"
                id="campaign_desc"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            

            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.campaign_actived"
                color="primary"
                id="form.campaign_actived"
                :label="form.campaign_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
            <!-- Letter ID -->
            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="letter"
                cb_url="apiListLetter"
                cb_url_parameter="letter_actived=Y"
                cb_title="Letter ID"
                cb_id="campaign_letterid"
                cb_desc="campaign_letter_desc"
                :cb_rules="
                  form.campaign_flag === 'C' ? 'Please Fill Letter ID' : ''
                "
                cb_desc_readonly="readonly"
                cb_items_id="letter_id"
                cb_items_desc="letter_subject"
                :cb_value_id="form.campaign_letterid"
                :cb_value_desc="form.campaign_letter_desc"
              >
              </app-cb>
            </v-col>

            <!-- Flag -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-select
                v-model="form.campaign_flag"
                :items="campaign_flag"
                label="Flag"
                required
                :rules="form.campaign_flag_rules"
                item-value="code_id"
                item-text="code_desc"
              ></v-select>
            </v-col>

            <!-- Effective Date -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-dialog
                ref="dialog_effdate"
                v-model="modal.effdate"
                :return-value.sync="form.campaign_effdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.campaign_effdate"
                    label="Effective Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.campaign_effdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.effdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_effdate.save(form.campaign_effdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Expired Date -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-dialog
                ref="dialog_expdate"
                v-model="modal.expdate"
                :return-value.sync="form.campaign_expdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.campaign_expdate"
                    label="Expired Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.campaign_expdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.expdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_expdate.save(form.campaign_expdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Risk Logic -->
            <v-col cols="12" sm="2" md="2" class="mt-n7">
              <label class="caption">Risk Logic</label>
              <v-checkbox
                v-model="form.campaign_risklogicf"
                label=""
              ></v-checkbox>
            </v-col>
            <!-- Product No -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model="form.campaign_productno"
                required
                :maxlength="maxinput.desc"
                label="Product No"
                placeholder="Product No"
                id="campaign_productno"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Product Date -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-dialog
                ref="dialog_productdate"
                v-model="modal.productdate"
                :return-value.sync="form.campaign_productdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.campaign_productdate"
                    label="Product Date"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.campaign_productdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.productdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_productdate.save(form.campaign_productdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- Initial -->
            <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field
                v-model="form.campaign_initial"
                required
                :maxlength="maxinput.initial"
                label="Initial"
                placeholder="Initial"
                id="campaign_initial"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
              <!-- Title -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model="form.campaign_title"
                required
                :maxlength="maxinput.desc"
                label="Title"
                placeholder="Title"
                id="campaign_title"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
              <!-- Insurance -->
            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type="profile"
                cb_url="apiListProfile"
                cb_url_parameter="profile_lob=05&profile_actived=Y"
                cb_title="Insurance"
                cb_id="campaign_insurance"
                cb_desc="campaign_insurance_desc"
                cb_rules=""
                cb_desc_readonly="readonly"
                cb_items_id="profile_id"
                cb_items_desc="profile_name"
                :cb_value_id="this.form.campaign_insurance"
                :cb_value_desc="this.form.campaign_insurance_desc"
                @clicked="GetPholder"
              >
              </app-cb>
            </v-col>
          </v-row>

          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox
                  v-model="cont.campaign"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont.campaign"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.campaign_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaign_cuser)">{{form.campaign_cuser}}</span>
Last Update Date : {{form.campaign_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaign_luser)">{{form.campaign_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
          <v-tabs
            background-color="white"
            color="secondary"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tab id="tabgeneral" ref="tabgeneral" href="#tab-general">
              General
            </v-tab>
            <v-tab
              id      ="tabrisklogic"
              href    ="#tab-risklogic"
              @click  ="ListCampaignRiskLogic(form.campaign_id)"
              v-show  ="url_type !== 'add' ? true : false"
            >
              Risk Logic
            </v-tab>
            <v-tab
              id="tabrisk"
              href="#tab-risk"
              @click="ListCampaignRisk(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Risk
            </v-tab>
            <v-tab
              id="tabinterest"
              href="#tab-interest"
              @click="ListCampaignInt(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Interest
            </v-tab>
            <!-- <v-tab
              id="tabdeductible"
              href="#tab-deductible"
              @click="ListCampaignDeductible(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Deductible
            </v-tab> -->
            <v-tab
              id="tabfeeduty"
              href="#tab-feeduty"
              @click="ListCampaignFeeDuty(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Fee & Duty
            </v-tab>
            <v-tab
              id="tabsource"
              href="#tab-source"
              @click="ListCampaignSource(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Biz. Source
            </v-tab>
            <v-tab
              id="tabpayordelivery"
              ref="tabpayordelivery"
              href="#tab-payordelivery"
              @click="CheckPayorDelivery(form.campaign_id)"
            >
              Payor & Delivery
            </v-tab>
            <v-tab
              id="tabtermcondition"
              href="#tab-termcondition"
              @click="ListCampaignTC(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Term & Condition
            </v-tab>
            <v-tab
              id="tabsor"
              href="#tab-sor"
              @click="ListCampaignSOR(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Spreading Of Risk
            </v-tab>
            <!-- <v-tab
              id="tabdepreciation"
              href="#tab-depreciation"
              @click="ListCampaignDepreciation(form.campaign_id)"
              v-show="url_type !== 'add' ? true : false"
            >
              Depreciation
            </v-tab> -->
            <v-tab-item value="tab-general">
              <v-row class="mx-2 ma-1">
                <!-- Policy Type -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="policytype"
                    cb_url="apiListPolicyType"
                    cb_url_parameter="policytype_actived=Y"
                    cb_title="Certificate Type"
                    cb_id="campaign_policytype"
                    cb_desc="campaign_policytype_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="policytype_id"
                    cb_items_desc="policytype_desc"
                    :cb_value_id="form.campaign_policytype"
                    :cb_value_desc="form.campaign_policytype_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Insurance Type -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="instype"
                    cb_url="apiListInsType"
                    cb_url_parameter="instype_actived=Y"
                    cb_title="Insurance Type"
                    cb_id="campaign_instype"
                    cb_desc="campaign_instype_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="instype_id"
                    cb_items_desc="instype_desc"
                    :cb_value_id="form.campaign_instype"
                    :cb_value_desc="form.campaign_instype_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Branch -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="branch"
                    cb_url="apiListBranch"
                    cb_url_parameter="branch_actived=Y"
                    cb_title="Branch"
                    cb_id="campaign_branch"
                    cb_desc="campaign_branch_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="branch_id"
                    cb_items_desc="branch_desc"
                    :cb_value_id="form.campaign_branch"
                    :cb_value_desc="form.campaign_branch_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Cost Center -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="department"
                    cb_url="apiListDepartment"
                    cb_url_parameter="department_actived=Y"
                    cb_title="Cost Center"
                    cb_id="campaign_costcenter"
                    cb_desc="campaign_costcenter_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="department_id"
                    cb_items_desc="department_desc"
                    :cb_value_id="form.campaign_costcenter"
                    :cb_value_desc="form.campaign_costcenter_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Channel -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="channel"
                    cb_url="apiListChannel"
                    cb_url_parameter="channel_actived=Y"
                    cb_title="Channel"
                    cb_id="campaign_channel"
                    cb_desc="campaign_channel_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="channel_id"
                    cb_items_desc="channel_desc"
                    :cb_value_id="form.campaign_channel"
                    :cb_value_desc="form.campaign_channel_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Segment -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="segment"
                    cb_url="apiListSegment"
                    cb_url_parameter="segment_actived=Y"
                    cb_title="Segment"
                    cb_id="campaign_segment"
                    cb_desc="campaign_segment_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="segment_id"
                    cb_items_desc="segment_desc"
                    :cb_value_id="form.campaign_segment"
                    :cb_value_desc="form.campaign_segment_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Policy Holder -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_actived=Y"
                    cb_title="Obligee"
                    cb_id="campaign_pholder"
                    cb_desc="campaign_pholder_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_name"
                    cb_items_additional_1="profile_address"
                    cb_items_additional_2="profile_zipcode"
                    :cb_value_id="this.form.campaign_pholder"
                    :cb_value_desc="this.form.campaign_pholder_desc"
                    @clicked="GetPholder"
                  >
                  </app-cb>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_actived=Y"
                    cb_title="Principal"
                    cb_id="campaign_pholder"
                    cb_desc="campaign_pholder_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_name"
                    :cb_value_id="this.form.campaign_pholder"
                    :cb_value_desc="this.form.campaign_pholder_desc"
                    @clicked="GetPholder"
                  >
                  </app-cb>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_actived=Y"
                    cb_title="Bank"
                    cb_id="campaign_pholder"
                    cb_desc="campaign_pholder_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_name"
                    :cb_value_id="this.form.campaign_pholder"
                    :cb_value_desc="this.form.campaign_pholder_desc"
                    @clicked="GetPholder"
                  >
                  </app-cb>
                </v-col>

                <!-- Marketing Officer -->
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type="marketingofficer"
                    cb_url="apiListUsers"
                    cb_url_parameter="profile_actived=Y"
                    cb_title="Marketing Officer"
                    cb_id="campaign_mo"
                    cb_desc="campaign_mo_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="users_id"
                    cb_items_desc="users_name"
                    :cb_value_id="this.form.campaign_mo"
                    :cb_value_desc="this.form.campaign_mo_desc"
                  >
                  </app-cb>
                </v-col>

                <!-- Ujroh Cedant -->
                <v-col cols="12" sm="3" md="1" class="mt-n3">
                  <vuetify-money
                    v-model           ="form.campaign_ujrohcedant"
                    v-bind:options    ="formatrate"
                    label             ="Ujroh Cedant"
                    placeholder       ="Ujroh Cedant"
                    background-color  ="numeric"
                    v-bind:properties ="properties"
                  />
                </v-col>

                <!-- WPC -->
                <v-col cols="12" sm="3" md="1" class="mt-n3">
                  <vuetify-money
                    v-model           ="form.campaign_wpc"
                    v-bind:options    ="formatint"
                    label             ="WPC"
                    placeholder       ="WPC"
                    background-color  ="numeric"
                    v-bind:properties ="properties"
                  />
                </v-col>

                <!-- Grace Period -->
                <v-col cols="12" sm="3" md="1" class="mt-n3">
                  <vuetify-money
                    v-model           ="form.campaign_graceperiod"
                    v-bind:options    ="formatint"
                    label             ="Grace Period"
                    placeholder       ="Grace Period"
                    background-color  ="numeric"
                    v-bind:properties ="properties"
                  />
                </v-col>

                <!-- Max Out Go Pct -->
                <!-- <v-col cols="12" sm="1" md="1">
                  <v-text-field 
                    v-model.lazy 		  ="form.campaign_maxoutgopct"
                    ref 				      ="campaign_maxoutgopct"
                    label 				    ="Max Out Go %"
                    placeholder 		  ="Rp 0"
                    background-color 	="numeric"
                    v-money 			    ="format_campaign.maxoutgopct"
                    id 		      	    ="campaign_maxoutgopct"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->

                <!-- Stop Loss Pct -->
                <v-col cols="12" sm="3" md="1" class="mt-n3">
                  <vuetify-money
                    v-model           ="form.campaign_stoplosspct"
                    v-bind:options    ="formatrate"
                    label             ="Stop Loss %"
                    placeholder       ="Stop Loss %"
                    background-color  ="numeric"
                    v-bind:properties ="properties"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-risk">
              <!-- Data Table Campaign Risk -->
              <template>
                <!-- Main Button Campaign Risk -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaignrisk.selected.length === campaignrisk.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignRisk"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignRisk"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaignrisk', '')"
                    class="mr-2"
                    v-if="campaignrisk.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaignrisk.searchbutton === false"
                    @click="SearchCampaignRisk"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaignrisk.search"
                    append-icon="search"
                    @click:append="campaignrisk.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaignrisk.searchbutton"
                    id="searchcampaignrisk"
                    clearable
                    @keyup.enter="ListCampaignRisk(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Campaign Risk  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaignrisk.selected"
                  :headers="campaignrisk.headers"
                  :items="ItemsCampaignRisk"
                  :search="campaignrisk.search"
                  @input="CheckCampaignRisk($event)"
                  item-key="campaignrisk_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaignrisk"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignRisk(
                          item.campaignrisk_campaignid,
                          item.campaignrisk_riskcovid
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaignrisk',
                          item.campaignrisk_campaignid,
                          item.campaignrisk_riskcovid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Campaign Risk -->
            </v-tab-item>

            <!-- <v-tab-item value="tab-deductible">
              <template>
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaigndeductible.selected.length ===
                      campaigndeductible.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignDeductible"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignDeductible"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaigndeductible', '')"
                    class="mr-2"
                    v-if="campaigndeductible.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaigndeductible.searchbutton === false"
                    @click="SearchCampaignDeductible"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaigndeductible.search"
                    append-icon="search"
                    @click:append="campaigndeductible.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaigndeductible.searchbutton"
                    id="searchcampaigndeductible"
                    clearable
                    @keyup.enter="ListCampaignDeductible(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaigndeductible.selected"
                  :headers="campaigndeductible.headers"
                  :items="ItemsCampaignDeductible"
                  :search="campaigndeductible.search"
                  @input="CheckCampaignDeductible($event)"
                  item-key="campaigndeductible_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaigndeductible"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignDeductible(
                          item.campaignded_campaignid,
                          item.campaignded_id
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaigndeductible',
                          item.campaignded_campaignid,
                          item.campaignded_id
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
            </v-tab-item> -->

            <v-tab-item value="tab-interest">
              <!-- Data Table Campaign Interest -->
              <template>
                <!-- Main Button Campaign Interest -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaignint.selected.length === campaignint.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignInt"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignInt"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaignint', '')"
                    class="mr-2"
                    v-if="campaignint.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaignint.searchbutton === false"
                    @click="SearchCampaignInt"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaignint.search"
                    append-icon="search"
                    @click:append="campaignint.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaignint.searchbutton"
                    id="searchcampaignint"
                    clearable
                    @keyup.enter="ListCampaignInt(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Campaign Interest -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaignint.selected"
                  :headers="campaignint.headers"
                  :items="ItemsCampaignInt"
                  :search="campaignint.search"
                  @input="CheckCampaignInt($event)"
                  item-key="campaignint_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaignint"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignInt(
                          item.campaignint_campaignid,
                          item.campaignint_id
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaignint',
                          item.campaignint_campaignid,
                          item.campaignint_id
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Campaign Interest -->
            </v-tab-item>

            <v-tab-item value="tab-feeduty">
              <!-- Data Table Campaign Fee & Duty -->
              <template>
                <!-- Main Button Campaign Fee & Duty -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaignfeeduty.selected.length ===
                      campaignfeeduty.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignFeeDuty"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignFeeDuty"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaignfeeduty', '')"
                    class="mr-2"
                    v-if="campaignfeeduty.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaignfeeduty.searchbutton === false"
                    @click="SearchCampaignFeeDuty"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaignfeeduty.search"
                    append-icon="search"
                    @click:append="campaignfeeduty.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaignfeeduty.searchbutton"
                    id="searchcampaignfeeduty"
                    clearable
                    @keyup.enter="ListCampaignFeeDuty(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Campaign Fee & Duty -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaignfeeduty.selected"
                  :headers="campaignfeeduty.headers"
                  :items="ItemsCampaignFeeDuty"
                  :search="campaignfeeduty.search"
                  @input="CheckCampaignFeeDuty($event)"
                  item-key="campaignfeeduty_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaignfeeduty"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.campaignfeeduty_payorid="{ item }">
                    {{item.campaignfeeduty_payorid}} - {{item.campaignfeeduty_payor_desc}}
                  </template>
                  <template v-slot:item.campaignfeeduty_amount="{ item }">
                    {{$functions.NewFormatNumber(item.campaignfeeduty_amount)}}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignFeeDuty(
                          item.campaignfeeduty_campaignid,
                          item.campaignfeeduty_id
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaignfeeduty',
                          item.campaignfeeduty_campaignid,
                          item.campaignfeeduty_id
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Campaign Fee & Duty -->
            </v-tab-item>

            <v-tab-item value="tab-source">
              <!-- Data Table Campaign Source -->
              <template>
                <!-- Main Button Campaign Source -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaignsource.selected.length ===
                      campaignsource.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignSource"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignSource"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaignsource', '')"
                    class="mr-2"
                    v-if="campaignsource.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaignsource.searchbutton === false"
                    @click="SearchCampaignSource"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaignsource.search"
                    append-icon="search"
                    @click:append="campaignsource.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaignsource.searchbutton"
                    id="searchcampaignsource"
                    clearable
                    @keyup.enter="ListCampaignSource(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Campaign Source -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaignsource.selected"
                  :headers="campaignsource.headers"
                  :items="ItemsCampaignSource"
                  :search="campaignsource.search"
                  @input="CheckCampaignSource($event)"
                  item-key="campaignsource_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaignsource"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignSource(
                          item.campaignsource_campaignid,
                          item.campaignsource_profileid,
                          item.campaignsource_type
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaignsource',
                          item.campaignsource_campaignid,
                          item.campaignsource_profileid,
                          item.campaignsource_type
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Campaign Source -->
            </v-tab-item>

            <v-tab-item value="tab-payordelivery">
              <v-row class="mx-2 ma-1">
                <!-- Payor ID -->
                <v-col cols="12" sm="12" md="12">
                  <app-cb
                    cb_type="profile"
                    cb_url="apiListProfile"
                    cb_url_parameter="profile_actived=Y"
                    cb_title="Payor ID"
                    cb_id="campaign_payorid"
                    cb_desc="campaign_payor_desc"
                    cb_rules=""
                    cb_desc_readonly="readonly"
                    cb_items_id="profile_id"
                    cb_items_desc="profile_name"
                    cb_items_additional_1="profile_address"
                    cb_items_additional_2="profile_zipcode"
                    :cb_value_id="form.campaign_payorid"
                    :cb_value_desc="form.campaign_payor_desc"
                    @clicked="GetPholder"
                  >
                  </app-cb>
                </v-col>

                <!-- Payor Address -->
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                    v-model="form.campaign_payoraddress"
                    required
                    :rules="form.campaign_payoraddress_rules"
                    :maxlength="maxinput.desc"
                    label="Payor Address"
                    placeholder="Payor Address"
                    id="campaign_payoraddress"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col>

                <!-- Delivery Receipt Name -->
                <v-col cols="12" sm="3" md="3" class="mt-n3">
                  <v-text-field
                    v-model="form.campaign_deliveryreceiptname"
                    required
                    :rules="form.campaign_deliveryreceiptname_rules"
                    :maxlength="maxinput.desc"
                    label="Delivery Receipt Name"
                    placeholder="Delivery Receipt Name"
                    id="campaign_deliveryreceiptname"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col>

                <!-- Delivery Address -->
                <v-col cols="12" sm="3" md="3" class="mt-n3">
                  <v-text-field
                    v-model="form.campaign_deliveryaddress"
                    required
                    :rules="form.campaign_deliveryaddress_rules"
                    :maxlength="maxinput.desc"
                    label="Delivery Address"
                    placeholder="Delivery Address"
                    id="campaign_deliveryaddress"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col>

                <!-- Delivery Zip Code -->
                <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-text-field
                    v-model="form.campaign_deliveryzipcode"
                    required
                    :rules="form.campaign_deliveryzipcode_rules"
                    :maxlength="maxinput.desc"
                    label="Zip Code"
                    placeholder="Zip Code"
                    id="campaign_deliveryzipcode"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-text-field>
                </v-col>

                <!-- Payment Method -->
                <v-col cols="12" sm="3" md="2">
                  <v-select
                    v-model="form.campaign_paymentmethod"
                    :items="campaign_paymentmethod"
                    label="Payment Method"
                    required
                    :rules="form.campaign_paymentmethod_rules"
                    item-value="code_id"
                    item-text="code_desc"
                  ></v-select>
                </v-col>

                <!-- Account Bank -->
                <v-col cols="12" sm="3" md="4">
                  <v-text-field
                    v-model="form.campaign_accountbank"
                    required
                    :rules="form.campaign_accountbank_rules"
                    :maxlength="maxinput.desc"
                    label="Account Bank"
                    placeholder="Account Bank"
                    id="campaign_accountbank"
                    @keyup.enter="Confirm('write', '')"
                    :disabled="form.campaign_accountbank_readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Account No -->
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="form.campaign_accountno"
                    required
                    :rules="form.campaign_accountno_rules"
                    :maxlength="maxinput.desc"
                    label="Account No"
                    placeholder="Account No"
                    id="campaign_accountno"
                    @keyup.enter="Confirm('write', '')"
                    :disabled="form.campaign_accountno_readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Account Name -->
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="form.campaign_accountname"
                    required
                    :rules="form.campaign_accountname_rules"
                    :maxlength="maxinput.desc"
                    label="Account Name"
                    placeholder="Account Name"
                    id="campaign_accountname"
                    @keyup.enter="Confirm('write', '')"
                    :disabled="form.campaign_accountname_readonly"
                  >
                  </v-text-field>
                </v-col>

                <!-- Period Term -->
                <v-col cols="12" sm="3" md="2">
                  <v-combobox
                    v-model="form.campaign_periodterm"
                    required
                    :rules="form.campaign_periodterm_rules"
                    :items="campaign_periodterm"
                    label="Period Term"
                    placeholder="Period Term"
                    id="campaign_periodterm"
                    @keyup.enter="Confirm('write', '')"
                  >
                  </v-combobox>
                </v-col>

                <!-- Num Installment -->
                <v-col cols="12" sm="3" md="1">
                  <v-text-field
                    v-model.lazy="form.campaign_numinstallment"
                    ref="campaign_numinstallment"
                    label="Num Installment"
                    placeholder="0"
                    background-color="numeric"
                    v-money="format_campaign.numinstallment"
                    id="campaign_numinstallment"
                    reverse
                  >
                  </v-text-field>
                </v-col>

                <!-- First Installment -->
                <v-col cols="12" sm="4" md="2">
                  <v-dialog
                    ref="dialog_firstinstallment"
                    v-model="modal.firstinstallment"
                    :return-value.sync="form.campaign_firstinstallment"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.campaign_firstinstallment"
                        label="First Installment"
                        prepend-inner-icon="event"
                        background-color="date"
                        v-on="on"
                        v-mask="'####-##-##'"
                        placeholder="YYYY-MM-DD"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.campaign_firstinstallment"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.firstinstallment = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog_firstinstallment.save(
                            form.campaign_firstinstallment
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <!-- Discount percentage -->
                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    v-model.lazy="form.campaign_discountpct"
                    ref="campaign_discountpct"
                    label="Discount %"
                    placeholder="Rp 0"
                    background-color="numeric"
                    v-money="format_campaign.discountpct"
                    id="campaign_discountpct"
                    reverse
                    :disabled="form.campaign_discountpct_readonly"
                    @change="DiscountPctOnChange"
                  >
                  </v-text-field>
                </v-col>

                <!-- Discount AMT -->
                <v-col cols="12" sm="12" md="1">
                  <v-text-field
                    v-model.lazy="form.campaign_discountamt"
                    ref="campaign_discountamt"
                    label="Discount Amount"
                    placeholder="Rp 0"
                    background-color="numeric"
                    v-money="format_campaign.discountamt"
                    id="campaign_discountamt"
                    reverse
                    :disabled="form.campaign_discountamt_readonly"
                    @change="DiscountAmtOnChange"
                  >
                  </v-text-field>
                </v-col>

                <!-- Billing Pol Year -->
                <v-col cols="12" sm="4" md="1">
                  <label class="caption">Billing Pol Year</label>
                  <v-checkbox
                    v-model="form.campaign_billingpolyear"
                    label=""
                  ></v-checkbox>
                </v-col>

                <!-- Auto Rounding -->
                <v-col cols="12" sm="4" md="1">
                  <label class="caption">Auto Rounding</label>
                  <v-checkbox
                    v-model="form.campaign_autorounding"
                    @change="AutoRoundingOnChecked()"
                    label=""
                  ></v-checkbox>
                </v-col>

                <!-- Installment Fee & Duty -->
                <v-col cols="12" sm="4" md="2">
                  <label class="caption">Installment Fee & Duty</label>
                  <v-checkbox
                    v-model="form.campaign_installmentfeeduty"
                    label=""
                  ></v-checkbox>
                </v-col>
              </v-row>

              <template v-if="campaigninstallment.show == true ? true : false">
                <v-row
                  v-for="item in this.campaigninstallment.items"
                  :key="item.row"
                >
                  <v-col cols="12" sm="3" md="3">
                    Installment #{{ item.row }}
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    {{ item.date }}
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>

            <v-tab-item value="tab-termcondition">
              <!-- Data Table Campaign Term & Condition -->
              <template>
                <!-- Main Button Campaign Term & Condition -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaigntc.selected.length === campaigntc.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignTC"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn @click="OpenCampaignTC" class="mr-2" title="add" small>
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaigntc', '')"
                    class="mr-2"
                    v-if="campaigntc.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaigntc.searchbutton === false"
                    @click="SearchCampaignTC"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaigntc.search"
                    append-icon="search"
                    @click:append="campaigntc.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaigntc.searchbutton"
                    id="searchcampaigntc"
                    clearable
                    @keyup.enter="ListCampaignTC(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Campaign Term & Condition -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaigntc.selected"
                  :headers="campaigntc.headers"
                  :items="ItemsCampaignTC"
                  :search="campaigntc.search"
                  @input="CheckCampaignTC($event)"
                  item-key="campaigntc_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaigntc"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignTC(
                          item.campaigntc_campaignid,
                          item.campaigntc_id,
                          item.campaigntc_classid
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaigntc',
                          item.campaigntc_campaignid,
                          item.campaigntc_id,
                          item.campaigntc_classid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Campaign Term & Condition -->
            </v-tab-item>

            <v-tab-item value="tab-sor">
              <!-- Data Table Campaign Spreading Of Risk -->
              <template>
                <!-- Main Button Campaign Spreading Of Risk -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaignsor.selected.length === campaignsor.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignSOR"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignSOR"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaignsor', '')"
                    class="mr-2"
                    v-if="campaignsor.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaignsor.searchbutton === false"
                    @click="SearchCampaignSOR"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaignsor.search"
                    append-icon="search"
                    @click:append="campaignsor.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaignsor.searchbutton"
                    id="searchcampaignsor"
                    clearable
                    @keyup.enter="ListCampaignSOR(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Campaign Spreading Of Risk -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaignsor.selected"
                  :headers="campaignsor.headers"
                  :items="ItemsCampaignSOR"
                  :search="campaignsor.search"
                  @input="CheckCampaignSOR($event)"
                  item-key="campaignsor_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaignsor"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignSOR(
                          item.campaignsor_campaignid,
                          item.campaignsor_profileid
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaignsor',
                          item.campaignsor_campaignid,
                          item.campaignsor_profileid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Campaign Spreading Of Risk -->
            </v-tab-item>

            <!-- <v-tab-item value="tab-depreciation">
              <template>
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      campaigndepreciation.selected.length ===
                      campaigndepreciation.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllCampaignDepreciation"
                    class="mr-2"
                    title="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn
                    @click="OpenCampaignDepreciation"
                    class="mr-2"
                    title="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    @click="Confirm('multideletecampaigndepreciation', '')"
                    class="mr-2"
                    v-if="campaigndepreciation.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="campaigndepreciation.searchbutton === false"
                    @click="SearchCampaignDepreciation"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model="campaigndepreciation.search"
                    append-icon="search"
                    @click:append="campaigndepreciation.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="campaigndepreciation.searchbutton"
                    id="searchcampaigndepreciation"
                    clearable
                    @keyup.enter="ListCampaignDepreciation(form.campaign_id)"
                  ></v-text-field>
                </v-card-title>
                <v-spacer></v-spacer>

                <v-data-table
                  :value="campaigndepreciation.selected"
                  :headers="campaigndepreciation.headers"
                  :items="ItemsCampaignDepreciation"
                  :search="campaigndepreciation.search"
                  @input="CheckCampaignDepreciation($event)"
                  item-key="campaigndepreciation_row"
                  show-select
                  class="elevation-1"
                   :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.listcampaigndepreciation"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditCampaignDepreciation(
                          item.campaigndepreciation_campaignid,
                          item.campaigndepreciation_yearnumber
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletecampaigndepreciation',
                          item.campaigndepreciation_campaignid,
                          item.campaigndepreciation_yearnumber
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
            </v-tab-item> -->
            
              <v-tab-item :eager ="true" value="tab-risklogic">
                <!-- Data Table Campaign Risk Logic -->
                <template>
                  <!-- Main Button Campaign Risk Logic -->
                  <v-card-title class="ml-n4">
                    <v-btn
                      :color="
                        campaignrisklogic.selected.length ===
                        campaignrisklogic.items.length
                          ? 'fourth'
                          : ''
                      "
                      @click  ="SelectAllCampaignRiskLogic"
                      class   ="mr-2"
                      title   ="select all"
                      small
                    >
                      select all
                    </v-btn>
                    <v-btn
                    @click    ="OpenCampaignRiskLogic"
                      class     ="mr-2"
                      title     ="add"
                      :disabled ="form.campaign_risklogicf === false ? true : false"
                      small
                    >
                      Add
                    </v-btn>
                    <v-btn
                      color     ="error"
                      title     ="delete selected"
                      @click    ="Confirm('multideletecampaignrisklogic', '')"
                      class     ="mr-2"
                      v-if      ="campaignrisklogic.selected.length > 0"
                      :disabled ="access.delete === 0 ? true : false"
                      small
                    >
                      <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                    </v-btn>
                    <v-btn
                      color   ="primary"
                      title   ="search"
                      v-if    ="campaignrisklogic.searchbutton === false"
                      @click  ="SearchCampaignRiskLogic"
                      small
                    >
                      <v-icon>{{$vars.V('icon_search')}}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-text-field
                      v-model       ="campaignrisklogic.search"
                      append-icon   ="search"
                      @click:append ="campaignrisklogic.searchbutton = false"
                      label         ="Type and Enter"
                      single-line
                      hide-details
                      v-if          ="campaignrisklogic.searchbutton"
                      id            ="searchcampaignrisklogic"
                      clearable
                      @keyup.enter  ="ListCampaignRiskLogic(form.campaign_id)"
                    ></v-text-field>
                  </v-card-title>
                  <!-- end: Main Button Campaign Risk Logic  -->
                  <v-spacer></v-spacer>

                  <v-data-table
                    :value        ="campaignrisklogic.selected"
                    :headers      ="campaignrisklogic.headers"
                    :items        ="ItemsCampaignRiskLogic"
                    :search       ="campaignrisklogic.search"
                    @input        ="CheckCampaignRiskLogic($event)"
                    item-key      ="campaignrisklogic_row"
                    show-select
                    class         ="elevation-1"
                    :footer-props ="{
                      showFirstLastPage: true,
                      firstIcon: 'first_page',
                      lastIcon: 'last_page',
                      prevIcon: 'keyboard_arrow_left',
                      nextIcon: 'keyboard_arrow_right',
                    }"
                    dense
                    :loading      ="loading.listcampaignrisklogic"
                    loading-text  ="Loading... Please wait"
                  >
                    <template v-slot:item.campaignrisklogic_riskcovid="{ item }">
                      {{item.campaignrisklogic_riskcovid}} - {{item.campaignrisklogic_riskcovdesc}}
                    </template>
                    <template v-slot:item.campaignrisklogic_interestid="{ item }">
                      {{item.campaignrisklogic_interestid}} - {{item.campaignrisklogic_interestdesc}}
                    </template>
                    <template v-slot:item.campaignrisklogic_tsimin="{ item }">
                      {{$functions.NewFormatNumber(item.campaignrisklogic_tsimin,0)}} - {{$functions.NewFormatNumber(item.campaignrisklogic_tsimax,0)}}
                    </template>
                    <template v-slot:item.campaignrisklogic_periodmin="{ item }">
                      {{item.campaignrisklogic_periodmin}} - {{item.campaignrisklogic_periodmax}} {{$functions.PeriodUnit(item.campaignrisklogic_periodunit)}}
                    </template>
                    <template v-slot:item.campaignrisklogic_ratemin="{ item }">
                      <span v-if="item.campaignrisklogic_ratemin !== item.campaignrisklogic_ratemax">{{item.campaignrisklogic_ratemin}} - {{item.campaignrisklogic_ratemax}} {{$functions.RateUnitPCT(item.campaignrisklogic_rateunit)}}</span>
                      <span v-else>{{item.campaignrisklogic_ratemin}} {{$functions.RateUnitPCT(item.campaignrisklogic_rateunit)}}</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="
                          EditCampaignRiskLogic(
                            item.campaignrisklogic_row,
                            item.campaignrisklogic_campaignid
                          )
                        "
                        :disabled="access.edit === 0 ? true : false"
                        title="edit"
                      >
                        edit
                      </v-icon>
                      <v-icon
                        small
                        @click="
                          Confirm(
                            'deletecampaignrisklogic',
                            item.campaignrisklogic_row,
                            item.campaignrisklogic_campaignid
                          )
                        "
                        :disabled="access.delete === 0 ? true : false"
                        title="delete"
                      >
                        delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </template>
                <!-- end: Data Table Campaign Risk Logic -->
              </v-tab-item>

          </v-tabs>
        </v-form>
      </v-tab-item>

      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{$vars.V('txt_select_all_page')}}
            </v-btn>

            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="campaign_id"
            show-select
            class="elevation-1"
             :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
              :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
              <template v-slot:item.campaign_insurance="{ item }">
                {{item.campaign_insurance}} - {{item.campaign_insurance_desc}}
              </template>
              <template v-slot:item.campaign_actived="{ item }">
                <v-chip
                  v-if="item.campaign_actived === 'Y'"
                  class="ma-2"
                  color="primary"
                  text-color="white"
                >
                  Active
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2"
                  color="fourth"
                  text-color="white"
                >
                  Not Active
                </v-chip>
            </template>
            <template v-slot:item.campaign_risklogicf="{ item }">
                <v-chip
                  v-if="item.campaign_risklogicf === 'Y'"
                  class="ma-2"
                  color="primary"
                  text-color="white"
                >
                  Yes
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2"
                  color="fourth"
                  text-color="white"
                >
                  No
                </v-chip>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.campaign_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click="Confirm('delete', item.campaign_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
            <template v-slot:item.campaign_subclass="{ item }">
              {{ item.campaign_subclass }} - {{ item.campaign_subclass_desc }}
            </template>
            <template v-slot:item.campaign_pholder="{ item }">
              {{ item.campaign_pholder }} - {{ item.campaign_pholder_desc }}
            </template>
            <template v-slot:item.campaign_effdate="{ item }">
              {{ FormatDateFullName(item.campaign_effdate) }}
            </template>
            <template v-slot:item.campaign_instype="{ item }">
              {{ item.campaign_instype }} - {{ item.campaign_instype_desc }}
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <!-- Dialog Campaign Risk -->
    <v-dialog
      v-model="dialog.campaignrisk"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="850"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaignrisk')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Risk</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignRisk" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaignriskdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaignrisk"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type="riskcov"
                  cb_url="apiListRiskcov"
                  cb_url_parameter="riskcov_actived=Y"
                  cb_title="Risk Coverage"
                  cb_id="campaignrisk_riskcovid"
                  cb_desc="campaignrisk_riskcovdesc"
                  cb_rules="Please fill Risk Coverage"
                  cb_desc_readonly="readonly"
                  cb_items_id="riskcov_id"
                  cb_items_desc="riskcov_desc"
                  :cb_value_id="form.campaignrisk_riskcovid"
                  :cb_value_desc="form.campaignrisk_riskcovdesc"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.campaignrisk_desc"
                  required
                  :rules="form.campaignrisk_desc_rules"
                  :maxlength="maxinput.desc"
                  label="Description *"
                  placeholder="Description"
                  id="campaignrisk_desc"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-combobox
                  v-model="form.campaignrisk_rateunit"
                  required
                  :rules="form.campaignrisk_rateunit_rules"
                  :items="campaignrisk_rateunit"
                  label="Rate Unit"
                  placeholder="Rate Unit"
                  id="campaignrisk_rateunit"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignrisk_rate"
                  ref="campaignrisk_rate"
                  label="Rate"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignrisk.rate"
                  id="campaignrisk_rate"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignrisk_rateloading"
                  ref="campaignrisk_rateloading"
                  label="Rate Loading"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignrisk.rateloading"
                  id="campaignrisk_rateloading"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-combobox
                  v-model="form.campaignrisk_category"
                  required
                  :rules="form.campaignrisk_category_rules"
                  :items="campaignrisk_category"
                  label="Category"
                  placeholder="Category"
                  id="campaignrisk_category"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-dialog
                  ref="dialog_sdate"
                  v-model="modal.sdate"
                  :return-value.sync="form.campaignrisk_sdate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.campaignrisk_sdate"
                      label="Start Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.campaignrisk_sdate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.sdate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog_sdate.save(form.campaignrisk_sdate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-dialog
                  ref="dialog_edate"
                  v-model="modal.edate"
                  :return-value.sync="form.campaignrisk_edate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.campaignrisk_edate"
                      label="End Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.campaignrisk_edate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.edate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog_edate.save(form.campaignrisk_edate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <!-- Info Campaign Risk -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addcampaignrisk' ? true : false">
                  <v-checkbox
                    v-model="cont.campaignrisk"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaignrisk"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
Created Date 	 : {{form.campaignrisk_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisk_cuser)">{{form.campaignrisk_cuser}}</span>
Last Update Date : {{form.campaignrisk_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisk_luser)">{{form.campaignrisk_luser}}</span>
</pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Risk -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Risk -->

    <!-- Dialog Campaign Deductible -->
    <!-- <v-dialog
      v-model="dialog.campaigndeductible"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="850"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaigndeductible')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Deductible</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignDeductible" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaigndeductibledialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaigndeductible"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <app-cb
                  cb_type="deductible"
                  cb_url="apiListDeductible"
                  cb_url_parameter="deductible_actived=Y"
                  cb_title="Deductible"
                  cb_id="campaignded_id"
                  cb_desc="campaignded_ccy"
                  cb_rules="Please fill Deductible"
                  cb_desc_readonly="readonly"
                  cb_items_id="deductible_id"
                  cb_items_desc="deductible_ccy"
                  :cb_value_id="form.campaignded_id"
                  :cb_value_desc="form.campaignded_ccy"
                  cb_single="Y"
                  @clicked="GetDeductible"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  cb_title="In CCY"
                  cb_id="campaignded_ccy"
                  cb_desc="campaignded_ccydesc"
                  cb_rules=""
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="form.campaignded_ccy"
                  :cb_value_desc="form.campaignded_ccydesc"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <app-cb
                  cb_type="indemnity"
                  cb_url="apiListIndemnity"
                  cb_url_parameter="indemnity_actived=Y"
                  cb_title="For Indemnity"
                  cb_id="campaignded_forindemnity"
                  cb_desc="campaignded_forindemnitydesc"
                  cb_rules=""
                  cb_desc_readonly="readonly"
                  cb_items_id="indemnity_id"
                  cb_items_desc="indemnity_desc"
                  :cb_value_id="form.campaignded_forindemnity"
                  :cb_value_desc="form.campaignded_forindemnitydesc"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignded_tsisipct"
                  ref="campaignded_tsisipct"
                  label="TSI SI %"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaigndeductible.tsisipct"
                  id="campaignded_tsisipct"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignded_claimpct"
                  ref="campaignded_claimpct"
                  label="Claim %"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaigndeductible.claimpct"
                  id="campaignded_claimpct"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignded_amountmin"
                  ref="campaignded_amountmin"
                  label="Amount Minimum"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaigndeductible.amountmin"
                  id="campaignded_amountmin"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignded_amountmax"
                  ref="campaignded_amountmax"
                  label="Amount Maximum"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaigndeductible.amountmax"
                  id="campaignded_amountmax"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <small>Loss Flag</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignded_totallossflag"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <small>With Flag</small><br />
                <v-btn-toggle dense v-model="form.campaignded_withflag" rounded>
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <small>With Flag</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignded_tsisiflag"
                  rounded
                >
                  <v-btn small> SI </v-btn>
                  <v-btn small> TSI </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="4" sm="3" md="3" class="mt-n3">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.campaignded_actived"
                  color="primary"
                  id="form.campaignded_actived"
                  :label="form.campaignded_actived !== true ? '' : 'Active'"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-textarea
                  v-model="form.campaignded_remarkseng"
                  required
                  :rules="form.campaignded_remarkseng_rules"
                  :maxlength="maxinput.desc"
                  label="Remarks Eng *"
                  placeholder="Remarks Eng"
                  id="campaignded_remarkseng"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-textarea
                  v-model="form.campaignded_remarksind"
                  required
                  :rules="form.campaignded_remarksind_rules"
                  :maxlength="maxinput.desc"
                  label="Remarks Loc *"
                  placeholder="Remarks Loc"
                  id="campaignded_remarksind"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div
                  v-show="url_type !== 'addcampaigndeductible' ? true : false"
                >
                  <v-checkbox
                    v-model="cont.campaigndeductible"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaigndeductible"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
Created Date 	 : {{form.campaignded_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignded_cuser)">{{form.campaignded_cuser}}</span>
Last Update Date : {{form.campaignded_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignded_luser)">{{form.campaignded_luser}}</span>
</pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- end: Dialog Campaign Deductible -->

    <!-- Dialog Campaign Interest -->
    <v-dialog
      v-model="dialog.campaignint"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="1360"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaignint')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Interest</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignInt" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaignintdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaignint"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <app-cb
                  cb_type="interest"
                  cb_url="apiListInterest"
                  cb_url_parameter="interest_actived=Y"
                  cb_title="Interest"
                  cb_id="campaignint_id"
                  cb_desc="campaignint_desc"
                  cb_rules="Please fill Interest"
                  cb_desc_readonly="readonly"
                  cb_items_id="interest_id"
                  cb_items_desc="interest_desc"
                  :cb_value_id="form.campaignint_id"
                  :cb_value_desc="form.campaignint_desc"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.campaignint_remarks"
                  required
                  :rules="form.campaignint_remarks_rules"
                  :maxlength="maxinput.interest.remarks"
                  label="Remarks *"
                  placeholder="Remarks"
                  id="campaignint_remarks"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-combobox
                  v-model="form.campaignint_category"
                  required
                  :rules="form.campaignint_category_rules"
                  :items="campaignint_category"
                  label="Category"
                  placeholder="Category"
                  id="campaignint_category"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  cb_title="Currency"
                  cb_id="campaignint_currency"
                  cb_desc="campaignint_currency_desc"
                  cb_rules="Please fill Currency"
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="form.campaignint_currency"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_limitofliability"
                  ref="campaignint_limitofliability"
                  label="Limit Of Liability"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.limitofliability"
                  id="campaignint_limitofliability"
                  reverse
                  @change="LOLOnType()"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_tsi"
                  ref="campaignint_tsi"
                  label="TSI"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.tsi"
                  id="campaignint_tsi"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_aggregate"
                  ref="campaignint_aggregate"
                  label="Aggregate"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.aggregate"
                  id="campaignint_aggregate"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <app-cb
                  cb_type="currency"
                  cb_url="apiListCurrency"
                  cb_url_parameter="currency_actived=Y"
                  cb_title="Currency OC"
                  cb_id="campaignint_currencyoc"
                  cb_desc="campaignint_currencyoc_desc"
                  cb_rules="Please fill Currency OC"
                  cb_desc_readonly="readonly"
                  cb_items_id="currency_id"
                  cb_items_desc="currency_desc"
                  :cb_value_id="form.campaignint_currencyoc"
                  cb_single="Y"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_limitofliabilityoc"
                  ref="campaignint_limitofliabilityoc"
                  label="Limit Of Liability OC"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.limitofliabilityoc"
                  id="campaignint_limitofliabilityoc"
                  reverse
                  @change="LOLOCOnType()"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_tsioc"
                  ref="campaignint_tsioc"
                  label="TSI OC"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.tsioc"
                  id="campaignint_tsioc"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_aggregateoc"
                  ref="campaignint_aggregateoc"
                  label="Aggregate OC"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.aggregateoc"
                  id="campaignint_aggregateoc"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <small>First Loss</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignint_firstloss"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <small>Adjustable</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignint_adjustable"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignint_adjustablerate"
                  ref="campaignint_adjustablerate"
                  label="Adjustable Rate"
                  placeholder="Rp 0"
                  background-color="numeric"
                  v-money="format_campaignint.adjustablerate"
                  id="campaignint_adjustablerate"
                  reverse
                  :disabled="
                    this.form.campaignint_adjustable !== 1 ? true : false
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Info Campaign Interest -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addcampaignint' ? true : false">
                  <v-checkbox
                    v-model="cont.campaignint"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaignint"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
Created Date 	 : {{form.campaignint_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignint_cuser)">{{form.campaignint_cuser}}</span>
Last Update Date : {{form.campaign_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.campaignint_luser)">{{form.campaignint_luser}}</span>
</pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Interest -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Interest -->

    <!-- Dialog Campaign Fee & Duty -->
    <v-dialog
      v-model="dialog.campaignfeeduty"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="960"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaignfeeduty')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Fee & Duty</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignFeeDuty" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaignfeedutydialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaignfeeduty"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type="feeduty"
                  cb_url="apiListFeeDuty"
                  cb_url_parameter="feeduty_actived=Y"
                  cb_title="Fee & Duty"
                  cb_id="campaignfeeduty_id"
                  cb_desc="campaignfeeduty_desc"
                  cb_rules="Please fill Fee & Duty"
                  cb_desc_readonly="readonly"
                  cb_items_id="feeduty_id"
                  cb_items_desc="feeduty_desc"
                  :cb_value_id="form.campaignfeeduty_id"
                  :cb_value_desc="form.campaignfeeduty_desc"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_actived=Y"
                  cb_title="Payor ID"
                  cb_id="campaignfeeduty_payorid"
                  cb_desc="campaignfeeduty_payor_desc"
                  cb_rules="Please fill Payor ID"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_name"
                  :cb_value_id="form.campaignfeeduty_payorid"
                  :cb_value_desc="form.campaignfeeduty_payor_desc"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="8" md="8" class="mt-n3">
                <v-text-field
                  v-model="form.campaignfeeduty_remarks"
                  required
                  :rules="form.campaignfeeduty_remarks_rules"
                  :maxlength="maxinput.feeduty.remarks"
                  label="Remarks *"
                  placeholder="Remarks"
                  id="campaignfeeduty_remarks"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <vuetify-money
                  v-model           ="form.campaignfeeduty_amount"
                  v-bind:options    ="formatamount"
                  label             ="Amount"
                  placeholder       ="Amount"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                />
              </v-col>
            </v-row>

            <!-- Info Campaign Fee & Duty -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addcampaignfeeduty' ? true : false">
                  <v-checkbox
                    v-model="cont.campaignfeeduty"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaignfeeduty"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
    Created Date 	 : {{form.campaignfeeduty_cdate}}
    Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignfeeduty_cuser)">{{form.campaignfeeduty_cuser}}</span>
    Last Update Date : {{form.campaignfeeduty_ldate}}
    Last Update User : <span class="cursor" @click="ShowUsers(form.campaignfeeduty_luser)">{{form.campaignfeeduty_luser}}</span>
  </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Fee & Duty -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Fee & Duty -->

    <!-- Dialog Campaign Source -->
    <v-dialog
      v-model="dialog.campaignsource"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="1050"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaignsource')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Biz Source</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignSource" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaignsourcedialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaignfeeduty"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="9" md="9" class="mt-n3">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_actived=Y"
                  cb_title="Biz. Source"
                  cb_id="campaignsource_profileid"
                  cb_desc="campaignsource_profile_desc"
                  cb_rules="Please fill Biz Source"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_name"
                  cb_items_additional_1="profile_tax_pct"
                  cb_items_additional_2="profile_vat_pct"
                  :cb_value_id="form.campaignsource_profileid"
                  :cb_value_desc="form.campaignsource_profile_desc"
                  @clicked="GetBizSource"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-combobox
                  v-model="form.campaignsource_type"
                  required
                  :rules="form.campaignsource_type_rules"
                  :items="campaignsource_type"
                  label="Type"
                  placeholder="Type"
                  id="campaignsource_type"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n5">
                <small>Main Flag</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignsource_mainflag"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n5">
                <small>Net After Member Flag</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignsource_netaftermemberflag"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n5">
                <small>Gross Commision Flag</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignsource_grosscommflag"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-n5">
                <small>Member Fee Flag</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.campaignsource_memberfeeflag"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model.lazy="form.campaignsource_feepct"
                  ref="campaignsource_feepct"
                  label="Fee Percent"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsource.feepct"
                  id="campaignsource_feepct"
                  reverse
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model.lazy="form.campaignsource_tax"
                  ref="campaignsource_tax"
                  label="Tax"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsource.tax"
                  id="campaignsource_tax"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model.lazy="form.campaignsource_vat"
                  ref="campaignsource_vat"
                  label="Vat"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsource.vat"
                  id="campaignsource_vat"
                  reverse
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model.lazy="form.campaignsource_discount"
                  ref="campaignsource_discount"
                  label="Discount"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsource.discount"
                  id="campaignsource_discount"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model.lazy="form.campaignsource_feeamt"
                  ref="campaignsource_feeamt"
                  label="Fee Amount"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsource.feeamt"
                  id="campaignsource_feeamt"
                  reverse
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Info Campaign Source -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addcampaignsource' ? true : false">
                  <v-checkbox
                    v-model="cont.campaignsource"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaignsource"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
    Created Date 	 : {{form.campaignsource_cdate}}
    Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignsource_cuser)">{{form.campaignsource_cuser}}</span>
    Last Update Date : {{form.campaignsource_ldate}}
    Last Update User : <span class="cursor" @click="ShowUsers(form.campaignsource_luser)">{{form.campaignsource_luser}}</span>
    </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Source -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Source -->

    <!-- Dialog Campaign Term & Condition -->
    <v-dialog
      v-model="dialog.campaigntc"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="920"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaigntc')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Term & Condition</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignTC" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaigntcdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaigntc"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="12" md="12" class="mt-n3">
                <app-cb
                  cb_type="classtc"
                  cb_url="apiListClassTC"
                  :cb_url_parameter="`classtc_actived=Y&classtc_subclassid=${form.campaign_subclass}`"
                  cb_title="Class TC"
                  cb_id="campaigntc_id"
                  cb_desc="campaigntc_classid"
                  cb_rules="Please fill Class TC"
                  cb_desc_readonly="readonly"
                  cb_items_id="classtc_id"
                  cb_items_desc="classtc_classid"
                  :cb_value_id="form.campaigntc_id"
                  :cb_value_desc="form.campaigntc_classid"
                  @clicked="GetTC"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.campaigntc_shortdesc_eng"
                  required
                  :rules="form.campaigntc_shortdesc_eng_rules"
                  :maxlength="maxinput.tc.short_desc"
                  label="Short Desc ENG *"
                  placeholder="Short Desc ENG"
                  id="campaigntc_shortdesc_eng"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.campaigntc_shortdesc_ind"
                  required
                  :rules="form.campaigntc_shortdesc_ind_rules"
                  :maxlength="maxinput.tc.short_desc"
                  label="Short Desc IND *"
                  placeholder="Short Desc IND"
                  id="campaigntc_shortdesc_ind"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <label class="caption">Long Desc ENG *</label>
                <quill-editor
                  ref="campaigntc_longdesc_eng"
                  v-model="form.campaigntc_longdesc_eng"
                >
                </quill-editor>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <label class="caption">Long Desc IND *</label>
                <quill-editor
                  ref="campaigntc_longdesc_ind"
                  v-model="form.campaigntc_longdesc_ind"
                >
                </quill-editor>
              </v-col>
            </v-row>

            <!-- Info Campaign Term & Condition -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addcampaigntc' ? true : false">
                  <v-checkbox
                    v-model="cont.campaigntc"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaigntc"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
    Created Date 	 : {{form.campaigntc_cdate}}
    Created User 	 : <span class="cursor" @click="ShowUsers(form.campaigntc_cuser)">{{form.campaigntc_cuser}}</span>
    Last Update Date : {{form.campaigntc_ldate}}
    Last Update User : <span class="cursor" @click="ShowUsers(form.campaigntc_luser)">{{form.campaigntc_luser}}</span>
    </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Term & Condition -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Term & Condition -->

    <!-- Dialog Campaign Spreading Of Risk -->
    <v-dialog
      v-model="dialog.campaignsor"
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      fullscreen
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaignsor')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Spreading Of Risk</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddCampaignSOR" title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaignsordialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaignsor"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <!-- Type -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-combobox
                  v-model="form.campaignsor_type"
                  required
                  :rules="form.campaignsor_type_rules"
                  :items="campaignsor_type"
                  label="Type"
                  placeholder="Type"
                  id="campaignsor_type"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-combobox>
              </v-col>

              <!-- Reinsurance ID -->
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_actived=Y"
                  cb_title="Reinsurance ID"
                  cb_id="campaignsor_profileid"
                  cb_desc="campaignsor_profile_desc"
                  cb_rules="Please fill Reinsurance ID"
                  cb_desc_readonly="readonly"
                  cb_items_id="profile_id"
                  cb_items_desc="profile_name"
                  :cb_value_id="form.campaignsor_profileid"
                  :cb_value_desc="form.campaignsor_profile_desc"
                >
                </app-cb>
              </v-col>

              <!-- Rate Code -->
              <!-- <v-col cols="12" sm="6" md="6" class="mt-n3">
              <app-cb
                cb_type           ="profile"
                cb_url            ="apiListProfile"
                cb_url_parameter  ="profile_actived=Y"
                cb_title          ="Rate Code"
                cb_id             ="campaignsor_profileid"
                cb_desc           ="campaignsor_profile_desc"
                cb_rules          ="Please fill Rate Code"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="profile_id"
                cb_items_desc     ="profile_name"
                :cb_value_id		  ="form.campaignsor_profileid"
                :cb_value_desc		="form.campaignsor_profile_desc"
              >
              </app-cb>
            </v-col> -->

              <!-- Reinsurance Period From -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref="dialog_sorsdate"
                  v-model="modal.sorsdate"
                  :return-value.sync="form.campaignsor_sdate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.campaignsor_sdate"
                      label="Due Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.campaignsor_sdate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.sorsdate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog_sorsdate.save(form.campaignsor_sdate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!-- Reinsurance Period To -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref="dialog_soredate"
                  v-model="modal.soredate"
                  :return-value.sync="form.campaignsor_edate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.campaignsor_edate"
                      label="Due Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.campaignsor_edate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.soredate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog_soredate.save(form.campaignsor_edate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!-- Leader No -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-text-field
                  v-model="form.campaignsor_leaderno"
                  required
                  :rules="form.campaignsor_leaderno_rules"
                  :maxlength="maxinput.interest.remarks"
                  label="Leader No *"
                  placeholder="Leader No"
                  id="campaignsor_leaderno"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <!-- UW -->
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type="users"
                  cb_url="apiListUsers"
                  cb_url_parameter="users_actived=Y"
                  cb_title="Under Writer"
                  cb_id="campaignsor_uw"
                  cb_desc="campaignsor_uw_desc"
                  cb_rules="Please fill Under Writer"
                  cb_desc_readonly="readonly"
                  cb_items_id="users_id"
                  cb_items_desc="users_name"
                  :cb_value_id="form.campaignsor_uw"
                  :cb_value_desc="form.campaignsor_uw_desc"
                >
                </app-cb>
              </v-col>

              <!-- Accepted By -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-text-field
                  v-model="form.campaignsor_acceptedby"
                  required
                  :rules="form.campaignsor_acceptedby_rules"
                  :maxlength="maxinput.interest.remarks"
                  label="Accepted By *"
                  placeholder="Accepted By"
                  id="campaignsor_acceptedby"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <!-- Accpeted Date -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref="dialog_accepteddate"
                  v-model="modal.accepteddate"
                  :return-value.sync="form.campaignsor_accepteddate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.campaignsor_accepteddate"
                      label="Due Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.campaignsor_accepteddate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal.accepteddate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog_accepteddate.save(
                          form.campaignsor_accepteddate
                        )
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!-- Share Pct -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_sharepct"
                  ref="campaignsor_sharepct"
                  label="Share %"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.sharepct"
                  id="campaignsor_sharepct"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Share Amt -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_shareamt"
                  ref="campaignsor_shareamt"
                  label="Share Amount"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.shareamt"
                  id="campaignsor_shareamt"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Share TSI -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_sharetsi"
                  ref="campaignsor_sharetsi"
                  label="Share TSI %"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.sharetsi"
                  id="campaignsor_sharetsi"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Period Term -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_periodterm"
                  ref="campaignsor_periodterm"
                  label="Period Term"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.periodterm"
                  id="campaignsor_periodterm"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Installment -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_installment"
                  ref="campaignsor_installment"
                  label="Installment"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.installment"
                  id="campaignsor_installment"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Ujroh Fee -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_ujrohfee"
                  ref="campaignsor_ujrohfee"
                  label="Ujroh Fee"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.ujrohfee"
                  id="campaignsor_ujrohfee"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Ujroh Cedant -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_ujrohcedant"
                  ref="campaignsor_ujrohcedant"
                  label="Ujroh Cedant"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.ujrohcedant"
                  id="campaignsor_ujrohcedant"
                  reverse
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-2 mt-2">
              <!-- Ref No -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-text-field
                  v-model="form.campaignsor_refno"
                  required
                  :rules="form.campaignsor_refno_rules"
                  :maxlength="maxinput.interest.remarks"
                  label="Ref No *"
                  placeholder="Ref No"
                  id="campaignsor_refno"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-text-field>
              </v-col>

              <!-- WPC -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_wpc"
                  ref="campaignsor_wpc"
                  label="WPC (Days)"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.wpc"
                  id="campaignsor_wpc"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Grace -->
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaignsor_grace"
                  ref="campaignsor_grace"
                  label="Grace (Days)"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaignsor.grace"
                  id="campaignsor_grace"
                  reverse
                >
                </v-text-field>
              </v-col>

              <!-- Due Date -->
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref="dialog_duedate"
                  v-model="modal.duedate"
                  :return-value.sync="form.campaignsor_duedate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.campaignsor_duedate"
                      label="Due Date"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.campaignsor_duedate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.duedate = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog_duedate.save(form.campaignsor_duedate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!-- First Loss -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_firstloss"
                ref 				      ="campaignsor_firstloss"
                label 				    ="First Loss"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_firstloss"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Limit -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_limit"
                ref 				      ="campaignsor_limit"
                label 				    ="Limit"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_limit"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- In Excess Of -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_inexcess"
                ref 				      ="campaignsor_inexcess"
                label 				    ="In Excess Of"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_inexcess"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Commission Pct -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_commpct"
                ref 				      ="campaignsor_commpct"
                label 				    ="Comm %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_commpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Commission Amount -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_commamt"
                ref 				      ="campaignsor_commamt"
                label 				    ="Comm Amt"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_commamt"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Overriding Comm Pct -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_overcommpct"
                ref 				      ="campaignsor_overcommpct"
                label 				    ="Over Comm %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_overcommpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Overriding Comm Amount -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_overcommamt"
                ref 				      ="campaignsor_overcommamt"
                label 				    ="Over Comm Amt"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_overcommamt"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Net Rate -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <app-cb
                cb_type           ="profile"
                cb_url            ="apiListProfile"
                cb_url_parameter  ="profile_actived=Y"
                cb_title          ="Net Rate"
                cb_id             ="campaignsor_profileid"
                cb_desc           ="campaignsor_profile_desc"
                cb_rules          ="Please fill Net Rate"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="profile_id"
                cb_items_desc     ="profile_name"
                :cb_value_id		  ="form.campaignsor_profileid"
                :cb_value_desc		="form.campaignsor_profile_desc"
                cb_single         ="Y"
              >
              </app-cb>
            </v-col> -->

              <!-- Original Rate -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_originalrate"
                ref 				      ="campaignsor_originalrate"
                label 				    ="Original Rate"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_originalrate"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Net To Gross -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-combobox
                v-model 	  	  ="form.campaignsor_type"
                required 
                :rules 	  		  ="form.campaignsor_type_rules"
                :items        	="campaignsor_type"
                label       	  ="Net To Gross"
                placeholder 	  ="Net To Gross"
                id 				      ="campaignsor_type"
                @keyup.enter 	  ="Confirm('write','')"
              >
              </v-combobox>
            </v-col> -->

              <!-- Premium Adjustment -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_premadjamt"
                ref 				      ="campaignsor_premadjamt"
                label 				    ="Premium Adj"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_premadjamt"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Premium Adjustment Pct -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_premadjpct"
                ref 				      ="campaignsor_premadjpct"
                label 				    ="Premium Adj %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_premadjpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- ACQ COST -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_acqcostpct"
                ref 				      ="campaignsor_acqcostpct"
                label 				    ="Acq Cost %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_acqcostpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- ADV PROFIT SHARING -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_profitpct"
                ref 				      ="campaignsor_profitpct"
                label 				    ="Adv Profit %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_profitpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Premium Calculation -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-combobox
                v-model 	  	  ="form.campaignsor_type"
                required 
                :rules 	  		  ="form.campaignsor_type_rules"
                :items        	="campaignsor_type"
                label       	  ="Calculation"
                placeholder 	  ="Calculation"
                id 				      ="campaignsor_type"
                @keyup.enter 	  ="Confirm('write','')"
              >
              </v-combobox>
            </v-col> -->

              <!-- Prorate -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_proratepct"
                ref 				      ="campaignsor_proratepct"
                label 				    ="Prorate %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_proratepct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Cancel Premium Refund -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_refundpct"
                ref 				      ="campaignsor_refundpct"
                label 				    ="Refund %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_refundpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Claim Coop / Control -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_claimcoop"
                ref 				      ="campaignsor_claimcoop"
                label 				    ="Claim Coop"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_claimcoop"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Confirmation No -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_confirmno"
                ref 				      ="campaignsor_confirmno"
                label 				    ="Confirm No"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_confirmno"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Binder Warranty -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_warranty"
                ref 				      ="campaignsor_warranty"
                label 				    ="Warranty (Days)"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_warranty"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Profile -->
              <!-- <v-col cols="12" sm="6" md="6" class="mt-n3">
              <app-cb
                cb_type           ="profile"
                cb_url            ="apiListProfile"
                cb_url_parameter  ="profile_actived=Y"
                cb_title          ="Profile ID"
                cb_id             ="campaignsor_profileid"
                cb_desc           ="campaignsor_profile_desc"
                cb_rules          ="Please fill Profile ID"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="profile_id"
                cb_items_desc     ="profile_name"
                :cb_value_id		  ="form.campaignsor_profileid"
                :cb_value_desc		="form.campaignsor_profile_desc"
              >
              </app-cb>
            </v-col> -->

              <!-- JP Slip No -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model 	  	="form.campaignsor_acceptedby"
                required
                :rules        ="form.campaignsor_acceptedby_rules"
                :maxlength    ="maxinput.interest.remarks"
                label       	="JP Slip No *"
                placeholder 	="JP Slip No"
                id 			      ="campaignsor_acceptedby"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-text-field>
            </v-col> -->

              <!-- JP Share -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_jpsharepct"
                ref 				      ="campaignsor_jpsharepct"
                label 				    ="JP Share %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_jpsharepct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- JP Comm. -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_jpcommpct"
                ref 				      ="campaignsor_jpcommpct"
                label 				    ="JP Comm. %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_jpcommpct"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Comm. -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_"
                ref 				      ="campaignsor_"
                label 				    ="Comm. %"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.ujrohcedant"
                id 		      	    ="campaignsor_"
                reverse
              >
              </v-text-field>
            </v-col> -->
            </v-row>

            <v-row class="mx-2 mt-2">
              <!-- Remarks -->
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-textarea
                  v-model="form.campaignsor_remarks"
                  required
                  :rules="form.campaignsor_remarks_rules"
                  :maxlength="maxinput.desc"
                  label="Remarks *"
                  placeholder="Remarks"
                  id="campaignsor_remarks"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-textarea>
              </v-col>

              <!-- Memo -->
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-textarea
                  v-model="form.campaignsor_memo"
                  required
                  :rules="form.campaignsor_memo_rules"
                  :maxlength="maxinput.desc"
                  label="Memo *"
                  placeholder="Memo"
                  id="campaignsor_memo"
                  @keyup.enter="Confirm('write', '')"
                >
                </v-textarea>
              </v-col>

              <!-- Full Coverage -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>Full Coverage</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- As Of Original Policy -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>As Of Original Policy</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- Allow Net Rate Greather Than Gross Rate -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>Allow Net Rate Greather Than Gross Rate</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- Not Apply Discount (CF) -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>Not Apply Discount (CF)</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- On Premium After Overriding -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>On Premium After Overriding</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- On Net Rate Basis -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>On Net Rate Basis</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- No Add/Refund -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>No Add/Refund</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- Allow Payor Due Greather Than Fac Due -->
              <!-- <v-col cols="12" sm="2" md="2" class="mt-n3">
              <small>Allow Payor Due Greather Than Fac Due</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- Pre-cede Facultative Cession Risk Arrangement -->
              <!-- <v-col cols="12" sm="8" md="8" class="mt-n3">
              <small>Pre-cede Facultative Cession Risk Arrangement</small><br>
              <v-btn-toggle dense v-model="form.campaignint_firstloss" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col> -->

              <!-- Recovery Due -->
              <!-- <v-col cols="12" sm="1" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.campaignsor_recoverydue"
                ref 				      ="campaignsor_recoverydue"
                label 				    ="Recovery Due"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_campaignsor.recoverydue"
                id 		      	    ="campaignsor_recoverydue"
                reverse
              >
              </v-text-field>
            </v-col> -->

              <!-- Table Installment Schedule -->
              <!-- <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-simple-table
                fixed-header
                height="300px"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        PCT(%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Installment #1</td>
                      <td>
                        <v-text-field
                          required
                          label       		  ="Period To"
                          placeholder 		  ="YYYY-MM-DD"
                          id 			   	      ="campaignsor_installment1"
                          @keyup.enter 		  ="Confirm('write','')"
                          v-mask         	  ="'####-##-##'"
                          background-color  ="date"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          placeholder 		  ="0"
                          background-color  ="numeric"
                          v-money 			    ="format_campaignsor.ujrohcedant"
                          id 		      	    ="campaignsor_ujrohcedant"
                          reverse
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Installment #2</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Installment #3</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Installment #4</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col> -->
            </v-row>

            <!-- Info Campaign Spreading Of Risk -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div v-show="url_type !== 'addcampaignsor' ? true : false">
                  <v-checkbox
                    v-model="cont.campaignsor"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaignsor"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
    Created Date 	 : {{form.campaignsor_cdate}}
    Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignsor_cuser)">{{form.campaignsor_cuser}}</span>
    Last Update Date : {{form.campaignsor_ldate}}
    Last Update User : <span class="cursor" @click="ShowUsers(form.campaignsor_luser)">{{form.campaignsor_luser}}</span>
    </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Spreading Of Risk -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Spreading Of Risk -->

    <!-- Dialog Campaign Depreciation -->
    <!-- <v-dialog
      v-model="dialog.campaigndepreciation"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="520"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaigndepreciation')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Depreciation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="AddCampaignDepreciation"
            title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaigndepreciationdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaigndepreciation"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaigndepreciation_yearnumber"
                  ref="campaigndepreciation_yearnumber"
                  label="Year Number"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaigndepreciation.yearnumber"
                  id="campaigndepreciation_yearnumber"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  v-model.lazy="form.campaigndepreciation_pct"
                  ref="campaigndepreciation_pct"
                  label="Pct"
                  placeholder="0"
                  background-color="numeric"
                  v-money="format_campaigndepreciation.pct"
                  id="campaigndepreciation_pct"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="4" sm="3" md="3" class="mt-n3">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.campaigndepreciation_actived"
                  color="primary"
                  id="form.campaigndepreciation_actived"
                  :label="
                    form.campaigndepreciation_actived !== true ? '' : 'Active'
                  "
                  class="mt-n1"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div
                  v-show="url_type !== 'addcampaigndepreciation' ? true : false"
                >
                  <v-checkbox
                    v-model="cont.campaigndepreciation"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaigndepreciation"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
    Created Date 	 : {{form.campaigndepreciation_cdate}}
    Created User 	 : <span class="cursor" @click="ShowUsers(form.campaigndepreciation_cuser)">{{form.campaigndepreciation_cuser}}</span>
    Last Update Date : {{form.campaigndepreciation_ldate}}
    Last Update User : <span class="cursor" @click="ShowUsers(form.campaigndepreciation_luser)">{{form.campaigndepreciation_luser}}</span>
    </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- end: Dialog Campaign Depreciation -->
    
    <!-- Dialog Campaign Risk Logic -->
    <v-dialog
      v-model="dialog.campaignrisklogic"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="920"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('campaignrisklogic')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Campaign Risk Logic</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="AddCampaignRiskLogic"
            title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.campaignrisklogicdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_campaignrisklogic"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <!-- Riskcov -->
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type           ="riskcov"
                  cb_url            ="apiListRiskcov"
                  cb_url_parameter  ="riskcov_actived=Y"
                  cb_title          ="Risk Coverage"
                  cb_id             ="campaignrisklogic_riskcovid"
                  cb_desc           ="campaignrisklogic_riskcovdesc"
                  cb_rules          ="Please fill Risk Coverage"
                  cb_desc_readonly  ="readonly"
                  cb_items_id       ="riskcov_id"
                  cb_items_desc     ="riskcov_desc"
                  :cb_value_id      ="form.campaignrisklogic_riskcovid"
                  :cb_value_desc    ="form.campaignrisklogic_riskcovdesc"
                >
                </app-cb>
              </v-col>

              <!-- Interest -->
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <app-cb
                  cb_type           ="interest"
                  cb_url            ="apiListInterest"
                  cb_url_parameter  ="interest_actived=Y"
                  cb_title          ="Interest"
                  cb_id             ="campaignrisklogic_interestid"
                  cb_desc           ="campaignrisklogic_interestdesc"
                  cb_rules          ="Please fill Interest"
                  cb_desc_readonly  ="readonly"
                  cb_items_id       ="interest_id"
                  cb_items_desc     ="interest_desc"
                  :cb_value_id      ="form.campaignrisklogic_interestid"
                  :cb_value_desc    ="form.campaignrisklogic_interestdesc"
                >
                </app-cb>
              </v-col>
              
              <!-- TSI Range Min -->
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.campaignrisklogic_tsimin"
                  v-bind:options    ="formatamount"
                  label             ="TSI Range Min"
                  placeholder       ="TSI Range Min"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                />
              </v-col>

              <!-- TSI Range Max -->
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.campaignrisklogic_tsimax"
                  v-bind:options    ="formatamount"
                  label             ="TSI Range Max"
                  placeholder       ="TSI Range Max"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                />
              </v-col>

              <!-- Period Min -->
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.campaignrisklogic_periodmin"
                  v-bind:options    ="formatint"
                  label             ="Period Min"
                  placeholder       ="Period Min"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                />
              </v-col>

              <!-- Period Max -->
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <vuetify-money
                  v-model           ="form.campaignrisklogic_periodmax"
                  v-bind:options    ="formatint"
                  label             ="Period Max"
                  placeholder       ="Period Max"
                  background-color  ="numeric"
                  v-bind:properties ="properties"
                />
              </v-col>

              <!-- Period Unit -->
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-select
                  v-model		  ="form.campaignrisklogic_periodunit"										
                  :items		  ="form.campaignrisklogic_periodunit_option"
                  :item-text  ="form.campaignrisklogic_periodunit_option.text"
                  :item-value ="form.campaignrisklogic_periodunit_option.value"
                  label		    ="Period Unit"
                  id			    ="campaignrisklogic_periodunit"
                ></v-select>
              </v-col>

              <!-- Actived -->
              <v-col cols="4" sm="3" md="3" class="mt-n3">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model="form.campaignrisklogic_actived"
                  color="primary"
                  id="form.campaignrisklogic_actived"
                  :label="
                    form.campaignrisklogic_actived !== true ? '' : 'Active'
                  "
                  class="mt-n1"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" sm="8" md="8" class="mb-n5">
                <v-card class="mb-12" outlined>
                  <v-card-title>OBJECT INFO</v-card-title>
                  <v-card-text>
                    <v-row class="mx-2" v-for="index in 20" :key="index">
                      <v-col cols="12" sm="12" md="12" class="" v-if ="gendet_fieldlabel_value[index] !== undefined">
                        <app-oi
                          :oi_title         ="gendet_fieldlabel_value[index]"
                          :oi_id            ="`gendet_fieldid_${index}`"
                          :oi_desc          ="`gendet_fielddesc_${index}`"
                          oi_rules          ="Please fill"
                          :oi_desc_readonly ="false"
                          :oi_refid         ="gendet_fieldid_value[index]"
                          :oi_value_id      ="gentab_detailid_value[index]"
                          :oi_value_desc    ="gentab_detaildesc_value[index]"
                          dense
                        ></app-oi>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="4" sm="4" md="4" class="mb-n5">
                <v-card class="mb-12" outlined>
                  <v-card-title>RATE</v-card-title>
                  <v-card-text>
                    <!-- Rate Lower -->
                    <v-col cols="12" sm="12" md="12" class="mt-n5">
                       <vuetify-money
                          v-model           ="form.campaignrisklogic_ratemin"
                          v-bind:options    ="formatrate"
                          label             ="Lower"
                          placeholder       ="Lower"
                          background-color  ="numeric"
                          v-bind:properties ="properties"
                        />
                    </v-col>

                    <!-- Rate Upper -->
                    <v-col cols="12" sm="12" md="12" class="mt-n5">
                      <vuetify-money
                        v-model           ="form.campaignrisklogic_ratemax"
                        v-bind:options    ="formatrate"
                        label             ="Upper"
                        placeholder       ="Upper"
                        background-color  ="numeric"
                        v-bind:properties ="properties"
                      />
                    </v-col>

                    <!-- Rate Unit -->
                    <v-col cols="12" sm="12" md="12" class="mt-n5">
                      <v-select
                        v-model		  ="form.campaignrisklogic_rateunit"										
                        :items		  ="form.campaignrisklogic_rateunit_option"
                        :item-text  ="form.campaignrisklogic_rateunit_option.text"
                        :item-value ="form.campaignrisklogic_rateunit_option.value"
                        label		    ="Rate Unit"
                        id			    ="campaignrisklogic_rateunit"
                      ></v-select>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- Info Campaign Risk Logic -->
            <v-row class="mx-2">
              <v-col cols="12" sm="12" md="12">
                <div
                  v-show="url_type !== 'addcampaignrisklogic' ? true : false"
                >
                  <v-checkbox
                    v-model="cont.campaignrisklogic"
                    label="Show Info"
                  ></v-checkbox>
                  <v-banner
                    v-model="cont.campaignrisklogic"
                    single-line
                    transition="slide-y-transition"
                  >
                    <pre>
    Created Date 	 : {{form.campaignrisklogic_cdate}}
    Created User 	 : <span class="cursor" @click="ShowUsers(form.campaignrisklogic_cuser)">{{form.campaignrisklogic_cuser}}</span>
    Last Update Date : {{form.campaignrisklogic_ldate}}
    Last Update User : <span class="cursor" @click="ShowUsers(form.campaignrisklogic_luser)">{{form.campaignrisklogic_luser}}</span>
    </pre>
                  </v-banner>
                </div>
              </v-col>
            </v-row>
            <!-- end: Info Campaign Risk Logic -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Campaign Risk Logic -->
    
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: {
      campaign: false,
      campaignrisk: false,
      // campaigndeductible: false,
      campaignint: false,
      campaignfeeduty: false,
      campaignsource: false,
      campaigntc: false,
      campaignsor: false,
      campaigndepreciation: false,
      campaignrisklogic: false,
    },
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    mininput: {
      payordelivery: {
        numinstallment: 0,
      },
    },
    maxinput: {
      id: 20,
      desc: 150,
      interest: {
        remarks: 8000,
      },
      feeduty: {
        remarks: 255,
      },
      payordelivery: {
        numinstallment: 60,
      },
      tc: {
        short_desc: 255,
        long_desc: 8000,
      },
      initial: 10
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    dialog: {
      campaignrisk: false,
      // campaigndeductible: false,
      campaignint: false,
      campaignfeeduty: false,
      campaignsource: false,
      campaigntc: false,
      campaignsor: false,
      campaigndepreciation: false,
      campaignrisklogic: false,
      users: false,
    },
    flag: {
      campaignrisk: false,
      showcampaignrisk: false,
      // campaigndeductible: false,
      // showcampaigndeductible: false,
      campaignint: false,
      showcampaignint: false,
      campaignfeeduty: false,
      showcampaignfeeduty: false,
      campaignsource: false,
      showcampaignsource: false,
      campaigntc: false,
      showcampaigntc: false,
      campaignsor: false,
      showcampaignsor: false,
      campaigndepreciation: false,
      showcampaigndepreciation: false,
      campaignrisklogic: false,
      showcampaignrisklogic: false,
    },
    format_campaign: {
      ujrohcedant: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      wpc: {
        decimal: "",
        thousands: "",
        precision: 0,
      },
      graceperiod: {
        decimal: "",
        thousands: "",
        precision: 0,
      },
      maxoutgopct: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      stoplosspct: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      discountpct: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      discountamt: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      numinstallment: {
        precision: 0,
      },
    },
    format_campaignrisk: {
      rate: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      rateloading: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
    },
    // format_campaigndeductible: {
    //   tsisipct: {
    //     decimal: ".",
    //     thousands: ",",
    //     precision: 2,
    //   },
    //   claimpct: {
    //     decimal: ".",
    //     thousands: ",",
    //     precision: 2,
    //   },
    //   amountmin: {
    //     decimal: ".",
    //     thousands: ",",
    //     precision: 4,
    //   },
    //   amountmax: {
    //     decimal: ".",
    //     thousands: ",",
    //     precision: 4,
    //   },
    // },
    format_campaignint: {
      limitofliabilityoc: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      tsioc: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      aggregateoc: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      limitofliability: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      tsi: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      aggregate: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      adjustablerate: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
    },
    format_campaignfeeduty: {
      amount: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
    },
    format_campaignsource: {
      feepct: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      discount: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      feeamt: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      tax: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      vat: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
    },
    format_campaignsor: {
      sharepct: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      shareamt: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      sharetsi: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      },
      grace: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
      wpc: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
      periodterm: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
      installment: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
      ujrohfee: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      ujrohcedant: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      refno: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
    },
    format_campaigndepreciation: {
      yearnumber: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
      pct: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
    },
    format_campaignrisklogic: {
      tsi: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
      period: {
        decimal: "",
        thousands: "",
        precision: 0,
      },
      rate: {
        decimal: ".",
        thousands: ",",
        precision: 4,
      }
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        sortable: false,
        value: "campaign_id",
      },
      { text: "Sub Class", value: "campaign_subclass" },
      { text: "Description", value: "campaign_desc" },
      { text: "Insurance", value: "campaign_insurance" },
      { text: "Effective Date", value: "campaign_effdate" },
      { text: "Policy Holder", value: "campaign_pholder" },
      { text: "Channel", value: "campaign_channel" },
      { text: "Insurance Type", value: "campaign_instype" },
      { text: "Risk Logic", value: "campaign_risklogicf" },
      { text: "Actived", value: "campaign_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    campaign_flag: [],
    campaign_paymentmethod: [],
    campaign_periodterm: ["0", "1", "3", "6", "12"],
    campaignrisk_rateunit: ["C", "M", "F"],
    campaignrisk_category: ["M", "A"],
    campaignint_category: ["M", "A"],
    campaignsource_type: ["S", "O"],
    campaignsor_type: ["L", "M", "F", "I", "O"],
    items: [],
    items_campaignrisk: [],
    loading: {
      page: true,
      list: false,
      listcampaignrisk: false,
      campaignriskdialog: false,
      // listcampaigndeductible: false,
      // campaigndeductibledialog: false,
      listcampaignint: false,
      campaignintdialog: false,
      listcampaignfeeduty: false,
      campaignfeedutydialog: false,
      listcampaignsource: false,
      campaignsourcedialog: false,
      listcampaigntc: false,
      campaigntcdialog: false,
      listcampaignsor: false,
      campaignsordialog: false,
      listcampaigndepreciation: false,
      campaigndepreciationdialog: false,
      listcampaignrisklogic: false,
      campaignrisklogicdialog: false,
    },
    btn_search: false,
    modal: {
      effdate: false,
      expdate: false,
      firstinstallment: false,
      sdate: false,
      edate: false,
      sorsdate: false,
      soredate: false,
      accepteddate: false,
      duedate: false,
      productdate: false,
    },
    submodul: 0,
    form: {
      // Campaign
      campaign_id: "",
      campaign_desc: "",
      campaign_subclass: "",
      campaign_subclass_desc: "",
      campaign_letterid: "",
      campaign_letter_desc: "",
      campaign_flag: "",
      campaign_risklogicf: false,
      campaign_actived: true,
      campaign_effdate: "",
      campaign_expdate: "",
      campaign_productdate: "",

      campaign_desc_rules: [(v) => !!v || "Please fill Description"],
      campaign_flag_rules: [(v) => !!v || "Please fill Flag"],
      campaign_effdate_rules: [(v) => !!v || "Please fill Effective Date"],
      campaign_expdate_rules: [(v) => !!v || "Please fill Expired Date"],

      campaign_id_readonly: true,
      campaign_discountpct_readonly: false,
      campaign_discountamt_readonly: false,
      campaign_accountbank_readonly: true,
      campaign_accountno_readonly: true,
      campaign_accountname_readonly: true,

      // Campaign General
      campaign_ujrohcedant: "",
      campaign_policytype: "",
      campaign_policytype_desc: "",
      campaign_instype: "",
      campaign_instype_desc: "",
      campaign_branch: "",
      campaign_branch_desc: "",
      campaign_costcenter: "",
      campaign_costcenter_desc: "",
      campaign_channel: "",
      campaign_channel_desc: "",
      campaign_segment: "",
      campaign_segment_desc: "",
      campaign_pholder: "",
      campaign_pholder_desc: "",
      campaign_wpc: "",
      campaign_graceperiod: "",
      campaign_mo: "",
      campaign_mo_desc: "",
      // campaign_maxoutgopct      : '',
      campaign_stoplosspct: "",

      campaign_ujrohcedant_rules: [(v) => !!v || "Please fill Ujroh Cedant"],
      campaign_policytype_rules: [(v) => !!v || "Please fill Policy Type"],
      campaign_subclass_rules: [(v) => !!v || "Please fill Sub Class"],
      campaign_instype_rules: [(v) => !!v || "Please fill Insurance Type"],
      campaign_branch_rules: [(v) => !!v || "Please fill Branch"],
      campaign_costcenter_rules: [(v) => !!v || "Please fill Cost Center"],
      campaign_channel_rules: [(v) => !!v || "Please fill Channel"],
      campaign_segment_rules: [(v) => !!v || "Please fill Segment"],
      campaign_pholder_rules: [(v) => !!v || "Please fill Policy Holder"],
      campaign_wpc_rules: [(v) => !!v || "Please fill WPC"],
      campaign_graceperiod_rules: [(v) => !!v || "Please fill Grace Period"],
      // campaign_maxoutgopct_rules  : [v => !!v || 'Please fill Max Out Go'],
      campaign_stoplosspct_rules: [(v) => !!v || "Please fill Stop Loss"],

      // Campaign Risk
      campaignrisk_campaignid: "",
      campaignrisk_riskcovid: "",
      campaignrisk_riskcovdesc: "",
      campaignrisk_desc: "",
      campaignrisk_rate: "",
      campaignrisk_rateunit: "",
      campaignrisk_rateloading: "",
      campaignrisk_category: "",
      campaignrisk_sdate: "",
      campaignrisk_edate: "",
      campaignrisk_riskcovid_par: "",

      campaignrisk_riskcovid_rules: [(v) => !!v || "Please fill ID"],
      campaignrisk_desc_rules: [(v) => !!v || "Please fill Description"],
      campaignrisk_rate_rules: [(v) => !!v || "Please fill Rate"],
      campaignrisk_rateunit_rules: [(v) => !!v || "Please fill Rate Unit"],
      campaignrisk_category_rules: [(v) => !!v || "Please fill Category"],
      ccampaignrisk_sdate_rules: [(v) => !!v || "Please fill S Date"],
      ccampaignrisk_edate_rules: [(v) => !!v || "Please fill E Date"],

      // Campaign Deductible
      // campaignded_campaignid: "",
      // campaignded_id: "",
      // campaignded_withflag: 0,
      // campaignded_ccy: "",
      // campaignded_forindemnity: "",
      // campaignded_tsisipct: "",
      // campaignded_tsisiflag: 0,
      // campaignded_claimpct: "",
      // campaignded_amountmin: "",
      // campaignded_amountmax: "",
      // campaignded_remarkseng: "",
      // campaignded_remarksind: "",
      // campaignded_totallossflag: 0,
      // campaignded_actived: "",
      // campaignded_id_par: "",

      // campaignded_campaignid_rules: [(v) => !!v || "Please fill ID"],
      // campaignded_id_rules: [(v) => !!v || "Please fill Deductible ID"],
      // campaignded_withflag_rules: [(v) => !!v || "Please fill With Flag"],
      // campaignded_ccy_rules: [(v) => !!v || "Please fill CCY"],
      // campaignded_forindemnity_rules: [
      //   (v) => !!v || "Please fill For Indemnity",
      // ],
      // campaignded_tsisipct_rules: [(v) => !!v || "Please fill TSI SI Pct"],
      // campaignded_tsisiflag_rules: [(v) => !!v || "Please fill TSI SI Flag"],
      // campaignded_claimpct_rules: [(v) => !!v || "Please fill Claim Pct"],
      // campaignded_amountmin_rules: [(v) => !!v || "Please fill Amount Min"],
      // campaignded_amountmax_rules: [(v) => !!v || "Please fill Amount Max"],
      // campaignded_remarkseng_rules: [(v) => !!v || "Please fill Remarks Eng"],
      // campaignded_remarksind_rules: [(v) => !!v || "Please fill Remarks Ind"],
      // campaignded_totallossflag_rules: [
      //   (v) => !!v || "Please fill Total Loss Flag",
      // ],

      // Campaign Interest
      currency_value_id: "IDR",
      currency_value_desc: "Rupiah",
      campaignint_campaignid: "",
      campaignint_id: "",
      campaignint_desc: "",
      campaignint_category: "",
      campaignint_remarks: "",
      campaignint_adjustable: 0,
      campaignint_currencyoc: "IDR",
      campaignint_currencyoc_desc: "",
      campaignint_currency: "IDR",
      campaignint_currency_desc: "",
      campaignint_limitofliabilityoc: "",
      campaignint_tsioc: "",
      campaignint_aggregateoc: "",
      campaignint_limitofliability: "",
      campaignint_tsi: "",
      campaignint_aggregate: "",
      campaignint_firstloss: 0,
      campaignint_id_par: "",
      campaignint_adjustablerate: "",

      campaignint_id_rules: [(v) => !!v || "Please fill Interest ID"],
      campaignint_category_rules: [(v) => !!v || "Please fill Category"],
      campaignint_remarks_rules: [(v) => !!v || "Please fill Remarks"],
      campaignint_currencyoc_rules: [(v) => !!v || "Please fill Currency OC"],
      campaignint_currency_rules: [(v) => !!v || "Please fill Currency"],
      campaignint_limitofliabilityoc_rules: [
        (v) => !!v || "Please fill Limit Of Liability OC",
      ],
      campaignint_tsioc_rules: [(v) => !!v || "Please fill Tsi OC"],
      campaignint_aggregateoc_rules: [(v) => !!v || "Please fill Aggregate OC"],
      campaignint_limitofliability_rules: [
        (v) => !!v || "Please fill Limit Of Liability",
      ],
      campaignint_tsi_rules: [(v) => !!v || "Please fill Tsi"],
      campaignint_aggregate_rules: [(v) => !!v || "Please fill Aggregate"],
      campaignint_firstloss_rules: [(v) => !!v || "Please fill First Loss"],
      campaignint_adjustablerate_rules: [
        (v) => !!v || "Please fill Adjustable Rate",
      ],

      // Campaign Fee & Duty
      campaignfeeduty_campaignid: "",
      campaignfeeduty_id: "",
      campaignfeeduty_desc: "",
      campaignfeeduty_payorid: "",
      campaignfeeduty_payor_desc: "",
      campaignfeeduty_amount: "",
      campaignfeeduty_remarks: "",

      campaignfeeduty_id_rules: [(v) => !!v || "Please fill Interest ID"],
      campaignfeeduty_payorid_rules: [(v) => !!v || "Please fill Payor ID"],
      campaignfeeduty_amount_rules: [(v) => !!v || "Please fill Amount"],
      campaignfeeduty_remarks_rules: [(v) => !!v || "Please fill Adjustable"],

      // Campaign Source
      campaignsource_campaignid: "",
      campaignsource_profileid: "",
      campaignsource_profile_desc: "",
      campaignsource_type: "",
      campaignsource_mainflag: 0,
      campaignsource_feepct: "",
      campaignsource_discount: "",
      campaignsource_netaftermemberflag: 0,
      campaignsource_grosscommflag: 0,
      campaignsource_memberfeeflag: 0,
      campaignsource_feeamt: "",
      campaignsource_tax: "",
      campaignsource_vat: "",
      campaignsource_profileid_par: "",
      campaignsource_type_par: "",

      campaignsource_profileid_rules: [(v) => !!v || "Please fill Profile ID"],
      campaignsource_type_rules: [(v) => !!v || "Please fill Type"],
      campaignsource_feepct_rules: [(v) => !!v || "Please fill Fee Percent"],
      campaignsource_discount_rules: [(v) => !!v || "Please fill Discount"],
      campaignsource_feeamt_rules: [(v) => !!v || "Please fill Fee Amount"],
      campaignsource_tax_rules: [(v) => !!v || "Please fill Tax"],
      campaignsource_vat_rules: [(v) => !!v || "Please fill Vat"],

      // Campaign Payor & Delivery (From Table Campaign)
      campaign_discountpct: "",
      campaign_discountamt: "",
      campaign_payorid: "",
      campaign_payor_desc: "",
      campaign_payoraddress: "",
      campaign_paymentmethod: "",
      campaign_accountno: "",
      campaign_accountbank: "",
      campaign_accountname: "",
      campaign_periodterm: "",
      campaign_numinstallment: "",
      campaign_firstinstallment: "",
      campaign_billingpolyear: false,
      campaign_installmentfeeduty: false,
      campaign_autorounding: false,
      campaign_deliveryaddress: "",
      campaign_deliveryzipcode: "",
      campaign_deliveryreceiptname: "",

      campaign_discountpct_rules: [(v) => !!v || "Please fill Discount %"],
      campaign_discountamt_rules: [(v) => !!v || "Please fill Discount Amount"],

      // Campaign Term & Condition
      campaigntc_campaignid: "",
      campaigntc_id: "",
      campaigntc_classid: "",
      campaigntc_shortdesc_eng: "",
      campaigntc_longdesc_eng: "",
      campaigntc_shortdesc_ind: "",
      campaigntc_longdesc_ind: "",
      campaigntc_id_par: "",
      campaigntc_classid_par: "",

      campaigntc_id_rules: [(v) => !!v || "Please fill Term & Condition ID"],
      campaigntc_shortdesc_eng_rules: [
        (v) => !!v || "Please fill Short Desc Eng",
      ],
      campaigntc_longdesc_eng_rules: [
        (v) => !!v || "Please fill Long Desc Eng",
      ],
      campaigntc_shortdesc_ind_rules: [
        (v) => !!v || "Please fill Short Desc Ind",
      ],
      campaigntc_longdesc_ind_rules: [
        (v) => !!v || "Please fill Long Desc Ind",
      ],

      // Campaign Spreading Of Risk
      campaignsor_campaignid: "",
      campaignsor_profileid: "",
      campaignsor_profile_desc: "",
      campaignsor_type: "",
      campaignsor_sdate: "",
      campaignsor_edate: "",
      campaignsor_leaderno: "",
      campaignsor_duedate: "",
      campaignsor_uw: "",
      campaignsor_uw_desc: "",
      campaignsor_acceptedby: "",
      campaignsor_accepteddate: "",
      campaignsor_sharepct: "",
      campaignsor_shareamt: "",
      campaignsor_sharetsi: "",
      campaignsor_periodterm: "",
      campaignsor_installment: "",
      campaignsor_ujrohfee: "",
      campaignsor_ujrohcedant: "",
      campaignsor_refno: "",
      campaignsor_wpc: "",
      campaignsor_grace: "",
      campaignsor_remarks: "",
      campaignsor_memo: "",
      // campaignsor_periodterm              : '',
      // campaignsor_recoverydue             : '',
      // campaignsor_firstloss               : '',
      // campaignsor_limit                   : '',
      // campaignsor_inexcess                : '',
      // campaignsor_commpct                 : '',
      // campaignsor_commamt                 : '',
      // campaignsor_overcommpct             : '',
      // campaignsor_overcommamt             : '',
      // campaignsor_originalrate            : '',
      // campaignsor_premadjamt              : '',
      // campaignsor_premadjpct              : '',
      // campaignsor_acqcostpct              : '',
      // campaignsor_profitpct               : '',
      // campaignsor_pcalc                   : '',
      // campaignsor_proratepct              : '',
      // campaignsor_refundpct               : '',
      // campaignsor_claimcoop               : '',
      // campaignsor_confirmno               : '',
      // campaignsor_warranty                : '',
      // campaignsor_jpslipno                : '',
      // campaignsor_jpsharepct              : '',
      // campaignsor_jpcommpct               : '',
      // campaignsor_asoriginalpolf          : '',
      // campaignsor_allownetrategreaterf    : '',
      // campaignsor_notapplydiscountf       : '',
      // campaignsor_onpremafteroverridingf  : '',
      // campaignsor_noaddrefundf            : '',
      // campaignsor_fullcoveragef           : '',
      // campaignsor_allowpayorduegreaterf   : '',
      // campaignsor_precedefaccessriskarrf  : '',

      campaignsor_profileid_par: "",

      campaignsor_profileid_rules: [(v) => !!v || "Please fill Profile Id"],
      campaignsor_type_rules: [(v) => !!v || "Please fill Type"],
      campaignsor_sharepct_rules: [(v) => !!v || "Please fill Share Pct"],
      campaignsor_shareamt_rules: [(v) => !!v || "Please fill Share Amt"],
      campaignsor_sharetsi_rules: [(v) => !!v || "Please fill Share Tsi"],
      campaignsor_acceptedby_rules: [(v) => !!v || "Please fill Accepted By"],
      campaignsor_accepteddate_rules: [
        (v) => !!v || "Please fill Accepted Date",
      ],
      campaignsor_remarks_rules: [(v) => !!v || "Please fill Remarks"],
      campaignsor_memo_rules: [(v) => !!v || "Please fill Memo"],
      campaignsor_uw_rules: [(v) => !!v || "Please fill Uw"],
      campaignsor_grace_rules: [(v) => !!v || "Please fill Grace"],
      campaignsor_wpc_rules: [(v) => !!v || "Please fill Wpc"],
      campaignsor_duedate_rules: [(v) => !!v || "Please fill Due Date"],
      campaignsor_periodterm_rules: [(v) => !!v || "Please fill Period Term"],
      campaignsor_installment_rules: [(v) => !!v || "Please fill Installment"],
      campaignsor_ujrohfee_rules: [(v) => !!v || "Please fill Ujroh Fee"],
      campaignsor_ujrohcedant_rules: [(v) => !!v || "Please fill Ujroh Cedant"],
      campaignsor_recoverydue_rules: [(v) => !!v || "Please fill Recovery Due"],

      // Campaign Depreciation
      campaigndepreciation_campaignid: "",
      campaigndepreciation_yearnumber: "",
      campaigndepreciation_pct: "",
      campaigndepreciation_actived: true,
      campaigndepreciation_yearnumber_par: "",

      campaigndepreciation_campaignid_rules: [
        (v) => !!v || "Please fill Profile Id",
      ],
      campaigndepreciation_yearnumber_rules: [(v) => !!v || "Please fill Type"],
      campaigndepreciation_pct_rules: [(v) => !!v || "Please fill Share Pct"],
      display_users: "",
      // Campaign Risk Logic
      campaignrisklogic_row           : '',
      campaignrisklogic_campaignid    : '',
      campaignrisklogic_riskcovid     : '',      
      campaignrisklogic_riskcovdesc   : '',
      campaignrisklogic_interestid    : '',      
      campaignrisklogic_interestdesc  : '',
      campaignrisklogic_tsimin        : '',
      campaignrisklogic_tsimax        : '',
      campaignrisklogic_periodmin     : '',
      campaignrisklogic_periodmax     : '',
      campaignrisklogic_periodunit    : '',
      campaignrisklogic_ratemin       : '',
      campaignrisklogic_ratemax       : '',
      campaignrisklogic_rateunit      : '',
      campaignrisklogic_actived       : true,

      campaignrisklogic_periodunit_option  : [
        {text: '-- Select --', value: ''},
				{text: 'Day', value: 'D'},
				{text: 'Month', value: 'M'},
				{text: 'Year', value: 'Y'}
      ],

      campaignrisklogic_rateunit_option  : [
        {text: '-- Select --', value: ''},
				{text: '%', value: 'C'},
				{text: '%o', value: 'M'},
				{text: 'F', value: 'F'}
      ],
    },
    gendet_fieldlabel_value       : {},
    gendet_fieldid_value          : {},
    gentab_detailid_value         : {},
    gentab_detaildesc_value       : {},
    campaignrisklogic_objectinfo  : [],

    // Data Table Campaign Risk
    campaignrisk: {
      headers: [
        { text: "Riskcov ID", value: "campaignrisk_riskcovid" },
        { text: "Description", value: "campaignrisk_desc" },
        { text: "Category", value: "campaignrisk_category" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Risk

    // Data Table Campaign Deductible
    // campaigndeductible: {
    //   headers: [
    //     { text: "Deductible ID", value: "campaignded_id" },
    //     { text: "CCY", value: "campaignded_ccy" },
    //     { text: "For Indemnity", value: "campaignded_forindemnity" },
    //     { text: "TSI SI %", value: "campaignded_tsisipct" },
    //     { text: "Claim %", value: "campaignded_claimpct" },
    //     { text: "Actions", value: "action", sortable: false },
    //   ],
    //   items: [],
    //   selected: [],
    //   search: "",
    //   searchbutton: false,
    // },
    // end: Data Table Campaign Deductible

    // Data Table Campaign Interest
    campaignint: {
      headers: [
        { text: "Interest ID", value: "campaignint_id" },
        { text: "Category", value: "campaignint_category" },
        { text: "Adjustable", value: "campaignint_adjustable" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Interest

    // Data Table Campaign Fee & Duty
    campaignfeeduty: {
      headers: [
        { text: "Fee & Duty ID", value: "campaignfeeduty_id" },
        { text: "Payor ID", value: "campaignfeeduty_payorid" },
        { text: "Amount", value: "campaignfeeduty_amount" },
        { text: "Remarks", value: "campaignfeeduty_remarks" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Fee & Duty

    // Data Table Campaign Source
    campaignsource: {
      headers: [
        { text: "Profile ID", value: "campaignsource_profileid" },
        { text: "Type", value: "campaignsource_type" },
        { text: "Main Flag", value: "campaignsource_mainflag" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Source

    // Data Table Campaign Installment
    campaigninstallment: {
      show: false,
      items: [],
      data: [
        { row: "1", date: "2022-03-25" },
        { row: "2", date: "2022-04-25" },
      ],
    },
    // end: Data Table Campaign Installment

    // Data Table Campaign Term & Condition
    campaigntc: {
      headers: [
        { text: "ID", value: "campaigntc_id" },
        { text: "Short Desc Eng", value: "campaigntc_shortdesc_eng" },
        { text: "Short Desc Ind", value: "campaigntc_shortdesc_ind" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Term & Condition

    // Data Table Campaign Spreading Of Risk
    campaignsor: {
      headers: [
        { text: "Profile ID", value: "campaignsor_profileid" },
        { text: "Type", value: "campaignsor_type" },
        { text: "Accepted Date", value: "campaignsor_accepteddate" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Spreading Of Risk

    // Data Table Campaign Depreciation
    campaigndepreciation: {
      headers: [
        { text: "Year Number", value: "campaigndepreciation_yearnumber" },
        { text: "Pct", value: "campaigndepreciation_pct" },
        { text: "Actived", value: "campaigndepreciation_actived" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Campaign Depreciation

    // Data Table Campaign Risk Logic
    campaignrisklogic: {
      headers: [
        { text: "Riskcov ID", value: "campaignrisklogic_riskcovid" },
        { text: "Interest ID", value: "campaignrisklogic_interestid" },
        { text: "TSI", value: "campaignrisklogic_tsimin" },
        { text: "Period", value: "campaignrisklogic_periodmin", align: "right" },
        { text: "Rate", value: "campaignrisklogic_ratemin", align: "right" },
        { text: "Actived", value: "campaignrisklogic_actived" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items         : [],
      selected      : [],
      search        : "",
      searchbutton  : false,
    },
    //Format Number
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 4
    },
    formatint: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 0
    },
    properties: {
      reverse: true
    },
    // end: Data Table Campaign Risk Logic
  }),
  created() {
    this.FirstLoad();
  },

  computed: {
    ItemsCampaignRisk() {
      for (let i = 0; i <= this.campaignrisk.items.length - 1; i++) {
        const campaignriskindex = this.campaignrisk.items[i];
        campaignriskindex.campaignrisk_row =
          this.campaignrisk.items[i].campaignrisk_campaignid +
          "|" +
          this.campaignrisk.items[i].campaignrisk_riskcovid +
          "|";
      }
      return this.campaignrisk.items;
    },

    // ItemsCampaignDeductible() {
    //   for (let i = 0; i <= this.campaigndeductible.items.length - 1; i++) {
    //     const campaigndeductibleindex = this.campaigndeductible.items[i];
    //     campaigndeductibleindex.campaigndeductible_row =
    //       this.campaigndeductible.items[i].campaignded_campaignid +
    //       "|" +
    //       this.campaigndeductible.items[i].campaignded_id +
    //       "|";
    //   }
    //   return this.campaigndeductible.items;
    // },

    ItemsCampaignInt() {
      for (let i = 0; i <= this.campaignint.items.length - 1; i++) {
        const campaignintindex = this.campaignint.items[i];
        campaignintindex.campaignint_row =
          this.campaignint.items[i].campaignint_campaignid +
          "|" +
          this.campaignint.items[i].campaignint_id +
          "|";
      }
      return this.campaignint.items;
    },

    ItemsCampaignFeeDuty() {
      for (let i = 0; i <= this.campaignfeeduty.items.length - 1; i++) {
        const campaignfeedutyindex = this.campaignfeeduty.items[i];
        campaignfeedutyindex.campaignfeeduty_row =
          this.campaignfeeduty.items[i].campaignfeeduty_campaignid +
          "|" +
          this.campaignfeeduty.items[i].campaignfeeduty_id +
          "|";
      }
      return this.campaignfeeduty.items;
    },

    ItemsCampaignSource() {
      for (let i = 0; i <= this.campaignsource.items.length - 1; i++) {
        const campaignsourceindex = this.campaignsource.items[i];
        campaignsourceindex.campaignsource_row =
          this.campaignsource.items[i].campaignsource_campaignid +
          "|" +
          this.campaignsource.items[i].campaignsource_profileid +
          "|" +
          this.campaignsource.items[i].campaignsource_type +
          "|";
      }
      return this.campaignsource.items;
    },

    ItemsCampaignTC() {
      for (let i = 0; i <= this.campaigntc.items.length - 1; i++) {
        const campaigntcindex = this.campaigntc.items[i];
        campaigntcindex.campaigntc_row =
          this.campaigntc.items[i].campaigntc_campaignid +
          "|" +
          this.campaigntc.items[i].campaigntc_id +
          "|" +
          this.campaigntc.items[i].campaigntc_classid +
          "|";
      }
      return this.campaigntc.items;
    },

    ItemsCampaignSOR() {
      for (let i = 0; i <= this.campaignsor.items.length - 1; i++) {
        const campaignsorindex = this.campaignsor.items[i];
        campaignsorindex.campaignsor_row =
          this.campaignsor.items[i].campaignsor_campaignid +
          "|" +
          this.campaignsor.items[i].campaignsor_profileid +
          "|";
      }
      return this.campaignsor.items;
    },

    ItemsCampaignDepreciation() {
      for (let i = 0; i <= this.campaigndepreciation.items.length - 1; i++) {
        const campaigndepreciationindex = this.campaigndepreciation.items[i];
        campaigndepreciationindex.campaigndepreciation_row =
          this.campaigndepreciation.items[i].campaigndepreciation_campaignid +
          "|" +
          this.campaigndepreciation.items[i].campaigndepreciation_profileid +
          "|";
      }
      return this.campaigndepreciation.items;
    },

    ItemsCampaignRiskLogic() {
      for (let i = 0; i <= this.campaignrisklogic.items.length - 1; i++) {
        const campaignrisklogicindex = this.campaignrisklogic.items[i];
        campaignrisklogicindex.campaignrisklogic_row = this.campaignrisklogic.items[i].campaignrisklogic_row
      }
      return this.campaignrisklogic.items;
    }
  },

  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
      this.GetListCode("CAMPAIGNFLAG");
      this.GetListCode("PAYMENTMETHOD");
    },

    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "campaign";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = priv[0].feedback;
            } else {
              this.access.read = priv[0].usersmenu_read;
              this.access.add = priv[0].usersmenu_add;
              this.access.edit = priv[0].usersmenu_edit;
              this.access.delete = priv[0].usersmenu_delete;
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                );
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },

    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },

    SelectAllPageRisk() {
      this.selected_campaignrisk =
        this.selected_campaignrisk === this.items_campaignrisk
          ? []
          : this.items_campaignrisk;
    },

    Check(value) {
      this.selected = value;
    },

    //Function for get all data from table Campaign
    List() {
      this.loading.list = true;
      let formdata = {
        campaign_id: "",
        campaign_desc: "",
        order_by: "campaign_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaign"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },

    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id, par1, par2) {
      if (flag === "write") {
        if (this.$refs.form_campaign.validate()) {
          this.$refs.tabgeneral.$el.click();
          this.$refs.tabpayordelivery.$el.click();
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.campaign_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "addcampaignrisk" || flag === "editcampaignrisk") {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaignrisk") {
        this.url_type = "deletecampaignrisk";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaignrisk_campaignid = this.form.campaign_id;
        this.campaignrisk_riskcovid = par1;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaignrisk") {
        this.url_type = "multideletecampaignrisk";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaignrisk.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      // } else if (
      //   flag === "addcampaigndeductible" ||
      //   flag === "editcampaigndeductible"
      // ) {
      //   this.url_type = this.url_type;
      //   this.confirm.dialog = true;
      //   this.confirm.title = "Save";
      //   setTimeout(function () {
      //     document.getElementById("dialog").focus();
      //   }, 500);
      // } else if (flag === "deletecampaigndeductible") {
      //   this.url_type = "deletecampaigndeductible";
      //   this.confirm.dialog = true;
      //   this.confirm.title = "Delete `" + par1 + "`";
      //   this.campaignded_campaignid = this.form.campaign_id;
      //   this.campaignded_id = par1;
      //   setTimeout(function () {
      //     document.getElementById("dialog").focus();
      //   }, 500);
      // } else if (flag === "multideletecampaigndeductible") {
      //   this.url_type = "multideletecampaigndeductible";
      //   this.confirm.dialog = true;
      //   this.confirm.title =
      //     "Delete `" + this.campaigndeductible.selected.length + "` data";
      //   setTimeout(function () {
      //     document.getElementById("dialog").focus();
      //   }, 500);
      } else if (flag === "addcampaignint" || flag === "editcampaignint") {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaignint") {
        this.url_type = "deletecampaignint";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaignint_campaignid = this.form.campaign_id;
        this.campaignint_id = par1;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaignint") {
        this.url_type = "multideletecampaignint";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaignint.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (
        flag === "addcampaignfeeduty" ||
        flag === "editcampaignfeeduty"
      ) {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaignfeeduty") {
        this.url_type = "deletecampaignfeeduty";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaignfeeduty_campaignid = this.form.campaign_id;
        this.campaignfeeduty_id = par1;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaignfeeduty") {
        this.url_type = "multideletecampaignfeeduty";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaignfeeduty.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (
        flag === "addcampaignsource" ||
        flag === "editcampaignsource"
      ) {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaignsource") {
        this.url_type = "deletecampaignsource";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaignsource_campaignid = this.form.campaign_id;
        this.campaignsource_profileid = par1;
        this.campaignsource_type = par2;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaignsource") {
        this.url_type = "multideletecampaignsource";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaignsource.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "addcampaigntc" || flag === "editcampaigntc") {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaigntc") {
        this.url_type = "deletecampaigntc";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaigntc_campaignid = this.form.campaign_id;
        this.campaigntc_id = par1;
        this.campaigntc_classid = par2;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaigntc") {
        this.url_type = "multideletecampaigntc";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaigntc.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "addcampaignsor" || flag === "editcampaignsor") {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaignsor") {
        this.url_type = "deletecampaignsor";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaignsor_campaignid = this.form.campaign_id;
        this.campaignsor_profileid = par1;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaignsor") {
        this.url_type = "multideletecampaignsor";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaignsor.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (
        flag === "addcampaigndepreciation" ||
        flag === "editcampaigndepreciation"
      ) {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaigndepreciation") {
        this.url_type = "deletecampaigndepreciation";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaigndepreciation_campaignid = this.form.campaign_id;
        this.campaigndepreciation_yearnumber = par1;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaigndepreciation") {
        this.url_type = "multideletecampaigndepreciation";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaigndepreciation.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (
        flag === "addcampaignrisklogic" ||
        flag === "editcampaignrisklogic"
      ) {
        this.url_type = this.url_type;
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletecampaignrisklogic") {
        this.url_type = "deletecampaignrisklogic";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + par1 + "`";
        this.campaignrisklogic_campaignid = this.form.campaign_id;
        this.campaignrisklogic_row = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletecampaignrisklogic") {
        this.url_type = "multideletecampaignrisklogic";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.campaignrisklogic.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },

    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.campaign_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (
        this.url_type === "addcampaignrisk" ||
        this.url_type === "editcampaignrisk"
      ) {
        this.WriteCampaignRisk();
      } else if (this.url_type === "deletecampaignrisk") {
        this.DeleteCampaignRisk(
          this.campaignrisk_campaignid,
          this.campaignrisk_riskcovid
        );
      } else if (this.url_type === "multideletecampaignrisk") {
        this.MultiProcessCampaignRisk();
      // } else if (
      //   this.url_type === "addcampaigndeductible" ||
      //   this.url_type === "editcampaigndeductible"
      // ) {
      //   this.WriteCampaignDeductible();
      // } else if (this.url_type === "deletecampaigndeductible") {
      //   this.DeleteCampaignDeductible(
      //     this.campaignded_campaignid,
      //     this.campaignded_id
      //   );
      // } else if (this.url_type === "multideletecampaigndeductible") {
      //   this.MultiProcessCampaignDeductible();
      } else if (
        this.url_type === "addcampaignint" ||
        this.url_type === "editcampaignint"
      ) {
        this.WriteCampaignInt();
      } else if (this.url_type === "deletecampaignint") {
        this.DeleteCampaignInt(
          this.campaignint_campaignid,
          this.campaignint_id
        );
      } else if (this.url_type === "multideletecampaignint") {
        this.MultiProcessCampaignInt();
      } else if (
        this.url_type === "addcampaignfeeduty" ||
        this.url_type === "editcampaignfeeduty"
      ) {
        this.WriteCampaignFeeDuty();
      } else if (this.url_type === "deletecampaignfeeduty") {
        this.DeleteCampaignFeeDuty(
          this.campaignfeeduty_campaignid,
          this.campaignfeeduty_id
        );
      } else if (this.url_type === "multideletecampaignfeeduty") {
        this.MultiProcessCampaignFeeDuty();
      } else if (
        this.url_type === "addcampaignsource" ||
        this.url_type === "editcampaignsource"
      ) {
        this.WriteCampaignSource();
      } else if (this.url_type === "deletecampaignsource") {
        this.DeleteCampaignSource(
          this.campaignsource_campaignid,
          this.campaignsource_profileid,
          this.campaignsource_type
        );
      } else if (this.url_type === "multideletecampaignsource") {
        this.MultiProcessCampaignSource();
      } else if (
        this.url_type === "addcampaigntc" ||
        this.url_type === "editcampaigntc"
      ) {
        this.WriteCampaignTC();
      } else if (this.url_type === "deletecampaigntc") {
        this.DeleteCampaignTC(
          this.campaigntc_campaignid,
          this.campaigntc_id,
          this.campaigntc_classid
        );
      } else if (this.url_type === "multideletecampaigntc") {
        this.MultiProcessCampaignTC();
      } else if (
        this.url_type === "addcampaignsor" ||
        this.url_type === "editcampaignsor"
      ) {
        this.WriteCampaignSOR();
      } else if (this.url_type === "deletecampaignsor") {
        this.DeleteCampaignSOR(
          this.campaignsor_campaignid,
          this.campaignsor_profileid
        );
      } else if (this.url_type === "multideletecampaignsor") {
        this.MultiProcessCampaignSOR();
      // } else if (
      //   this.url_type === "addcampaigndepreciation" ||
      //   this.url_type === "editcampaigndepreciation"
      // ) {
      //   this.WriteCampaignDepreciation();
      // } else if (this.url_type === "deletecampaigndepreciation") {
      //   this.DeleteCampaignDepreciation(
      //     this.campaigndepreciation_campaignid,
      //     this.campaigndepreciation_yearnumber
      //   );
      // } else if (this.url_type === "multideletecampaigndepreciation") {
      //   this.MultiProcessCampaignDepreciation();
      } else if (
        this.url_type === "addcampaignrisklogic" ||
        this.url_type === "editcampaignrisklogic"
      ) {
        this.WriteCampaignRiskLogic();
      } else if (this.url_type === "deletecampaignrisklogic") {
        this.DeleteCampaignRiskLogic(
          this.campaignrisklogic_row,
          this.campaignrisklogic_campaignid
        );
      } 
    },

    Close(flag) {
      if (flag === "attributes") {
        this.url_type = "edit";
        this.dialog.attributes = false;
      } else if (flag === "campaignrisk") {
        this.url_type = "edit";
        this.dialog.campaignrisk = false;
        this.ListCampaignRisk(this.form.campaign_id);
      // } else if (flag === "campaigndeductible") {
      //   this.url_type = "edit";
      //   this.dialog.campaigndeductible = false;
      //   this.ListCampaignDeductible(this.form.campaign_id);
      } else if (flag === "campaignint") {
        this.url_type = "edit";
        this.dialog.campaignint = false;
        this.ListCampaignInt(this.form.campaign_id);
      } else if (flag === "campaignfeeduty") {
        this.url_type = "edit";
        this.dialog.campaignfeeduty = false;
        this.ListCampaignFeeDuty(this.form.campaign_id);
      } else if (flag === "campaignsource") {
        this.url_type = "edit";
        this.dialog.campaignsource = false;
        this.ListCampaignSource(this.form.campaign_id);
      } else if (flag === "campaigntc") {
        this.url_type = "edit";
        this.dialog.campaigntc = false;
        this.ListCampaignTC(this.form.campaign_id);
      } else if (flag === "campaignsor") {
        this.url_type = "edit";
        this.dialog.campaignsor = false;
        this.ListCampaignSOR(this.form.campaign_id);
      // } else if (flag === "campaigndepreciation") {
      //   this.url_type = "edit";
      //   this.dialog.campaigndepreciation = false;
      //   this.ListCampaignDepreciation(this.form.campaign_id);
      } else if (flag === "campaignrisklogic") {
        this.url_type = "edit";
        this.dialog.campaignrisklogic = false;
        this.ListCampaignRiskLogic(this.form.campaign_id);
      } else if (flag === "confirm") {
        this.confirm.dialog = false;
        if (this.url_type !== "add") {
          this.url_type = "edit";
        }
      }
    },

    //Function to request insert data to table Campaign 'The API will validating your data, include the user who process the data' (go to APIWriteCampaign with url_type ="add")
    Add() {
      this.AddPayorDelivery();

      document.getElementById("tabgeneral").click();

      this.url_type = "add";
      this.form.campaign_id = "";
      this.form.campaign_desc = "";
      this.form.campaign_instype = "";
      this.form.campaign_subclass = "";
      this.form.campaign_subclass_desc = "";
      this.form.campaign_letterid = "";
      this.form.campaign_letter_desc = "";
      this.form.campaign_flag = "";
      this.form.campaign_risklogicf = false;
      this.form.campaign_effdate = "";
      this.form.campaign_expdate = "";
      this.form.campaign_actived = true;
      this.form.campaign_ujrohcedant = 0;
      this.form.campaign_discountpct = 0;
      this.form.campaign_discountamt = 0;
      this.form.campaign_policytype = "";
      this.form.campaign_policytype_desc = "";
      this.form.campaign_instype = "";
      this.form.campaign_instype_desc = "";
      this.form.campaign_branch = "";
      this.form.campaign_branch_desc = "";
      this.form.campaign_costcenter = "";
      this.form.campaign_costcenter_desc = "";
      this.form.campaign_channel = "";
      this.form.campaign_channel_desc = "";
      this.form.campaign_segment = "";
      this.form.campaign_segment_desc = "";
      this.form.campaign_pholder = "";
      this.form.campaign_pholder_desc = "";
      this.form.campaign_mo = "";
      this.form.campaign_mo_desc = "";
      this.form.campaign_wpc = "";
      this.form.campaign_graceperiod = 0;
      // this.form.campaign_maxoutgopct 	        = 0
      this.form.campaign_stoplosspct = 0;

      // this.$refs.campaign_ujrohcedant.$el.getElementsByTagName(
      //   "input"
      // )[0].value = 0;
      // this.$refs.campaign_wpc.$el.getElementsByTagName("input")[0].value = 0;
      // this.$refs.campaign_graceperiod.$el.getElementsByTagName(
      //   "input"
      // )[0].value = 0;
      // // this.$refs.campaign_maxoutgopct.$el.getElementsByTagName('input')[0].value  = 0
      // this.$refs.campaign_stoplosspct.$el.getElementsByTagName(
      //   "input"
      // )[0].value = 0;

      this.form.campaign_id_readonly = true;
      setTimeout(function () {
        document.getElementById("campaign_desc").focus();
      }, 500);
    },

    //Function to request update data to table Campaign 'The API will validating your data, include the user who process the data' (go to APIWriteCampaign with url_type ="edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        campaign_id: id,
        campaign_desc: "",
        order_by: "campaign_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaign"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.$refs.tabform.$el.click();
              this.url_type = "edit";
              this.form.campaign_id = feedback[0].campaign_id;
              this.form.campaign_desc = feedback[0].campaign_desc;
              this.form.campaign_subclass = feedback[0].campaign_subclass;
              this.form.campaign_subclass_desc =
                feedback[0].campaign_subclass_desc;
              this.form.campaign_letterid = feedback[0].campaign_letterid;
              this.form.campaign_letter_desc = feedback[0].campaign_letter_desc;
              this.form.campaign_flag = feedback[0].campaign_flag;
              this.form.campaign_risklogicf = this.$functions.TrueOrFalse(
                feedback[0].campaign_risklogicf
              );
              this.form.campaign_effdate = feedback[0].campaign_effdate;
              this.form.campaign_expdate = feedback[0].campaign_expdate;
              this.form.campaign_actived = this.$functions.TrueOrFalse(
                feedback[0].campaign_actived
              );
              this.form.campaign_ujrohcedant = feedback[0].campaign_ujrohcedant;
              this.form.campaign_policytype = feedback[0].campaign_policytype;
              this.form.campaign_policytype_desc =
                feedback[0].campaign_policytype_desc;
              this.form.campaign_instype = feedback[0].campaign_instype;
              this.form.campaign_instype_desc =
                feedback[0].campaign_instype_desc;
              this.form.campaign_branch = feedback[0].campaign_branch;
              this.form.campaign_branch_desc = feedback[0].campaign_branch_desc;
              this.form.campaign_costcenter = feedback[0].campaign_costcenter;
              this.form.campaign_costcenter_desc =
                feedback[0].campaign_costcenter_desc;
              this.form.campaign_channel = feedback[0].campaign_channel;
              this.form.campaign_channel_desc =
                feedback[0].campaign_channel_desc;
              this.form.campaign_segment = feedback[0].campaign_segment;
              this.form.campaign_segment_desc =
                feedback[0].campaign_segment_desc;
              this.form.campaign_pholder = feedback[0].campaign_pholder;
              this.form.campaign_pholder_desc =
                feedback[0].campaign_pholder_desc;
              this.form.campaign_mo = feedback[0].campaign_mo;
              this.form.campaign_mo_desc = feedback[0].campaign_mo_desc;
              this.form.campaign_wpc = feedback[0].campaign_wpc;
              this.form.campaign_graceperiod = feedback[0].campaign_graceperiod;
              // this.form.campaign_maxoutgopct 	        = feedback[0].campaign_maxoutgopct
              this.form.campaign_stoplosspct = feedback[0].campaign_stoplosspct;
              this.form.campaign_initial = feedback[0].campaign_initial;
              this.form.campaign_title = feedback[0].campaign_title;
              this.form.campaign_ujrohcedant = feedback[0].campaign_ujrohcedant;
              this.form.campaign_wpc = feedback[0].campaign_wpc;
              this.form.campaign_graceperiod = feedback[0].campaign_graceperiod;
              this.form.campaign_stoplosspct = feedback[0].campaign_stoplosspct;
              this.form.campaign_insurance = feedback[0].campaign_insurance;
              this.form.campaign_insurance_desc = feedback[0].campaign_insurance_desc;
              this.form.campaign_cdate = feedback[0].campaign_cdate;
              this.form.campaign_cuser = feedback[0].campaign_cuser;
              this.form.campaign_ldate = feedback[0].campaign_ldate;
              this.form.campaign_luser = feedback[0].campaign_luser;
              this.form.campaign_id_readonly = true;
              setTimeout(function () {
                document.getElementById("campaign_desc").focus();
              }, 500);

              document.getElementById("tabgeneral").click();

              this.campaignrisk.items = [];
              this.campaignint.items = [];
              this.campaignfeeduty.items = [];
              this.campaignsource.items = [];
              this.campaigntc.items = [];
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },

    Delete(id) {
      let formdata = {
        url_type: "delete",
        campaign_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaign"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        data_multi: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaign"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    Write() {
      let formdata = {
        url_type: this.url_type,
        campaign_id: this.form.campaign_id,
        campaign_desc: this.form.campaign_desc,
        campaign_effdate: this.form.campaign_effdate,
        campaign_expdate: this.form.campaign_expdate,
        campaign_flag: this.form.campaign_flag,
        campaign_letterid: this.form.campaign_letterid,
        campaign_ujrohcedant: this.form.campaign_ujrohcedant,
        campaign_discountpct: this.form.campaign_discountpct,
        campaign_discountamt: this.form.campaign_discountamt,
        campaign_policytype: this.form.campaign_policytype,
        campaign_subclass: this.form.campaign_subclass,
        campaign_instype: this.form.campaign_instype,
        campaign_branch: this.form.campaign_branch,
        campaign_costcenter: this.form.campaign_costcenter,
        campaign_channel: this.form.campaign_channel,
        campaign_segment: this.form.campaign_segment,
        campaign_pholder: this.form.campaign_pholder,
        campaign_mo: this.form.campaign_mo,
        campaign_wpc: this.form.campaign_wpc,
        campaign_graceperiod: this.form.campaign_graceperiod,
        // campaign_maxoutgopct          : this.form.campaign_maxoutgopct,
        campaign_stoplosspct: this.form.campaign_stoplosspct,
        campaign_payorid: this.form.campaign_payorid,
        campaign_payoraddress: this.form.campaign_payoraddress,
        campaign_paymentmethod: this.form.campaign_paymentmethod,
        campaign_accountno: this.form.campaign_accountno,
        campaign_accountbank: this.form.campaign_accountbank,
        campaign_accountname: this.form.campaign_accountname,
        campaign_periodterm: this.form.campaign_periodterm,
        campaign_numinstallment: this.form.campaign_numinstallment,
        campaign_firstinstallment: this.form.campaign_firstinstallment,
        campaign_billingpolyear: this.$functions.ActivedFlag(
          this.form.campaign_billingpolyear
        ),
        campaign_installmentfeeduty: this.$functions.ActivedFlag(
          this.form.campaign_installmentfeeduty
        ),
        campaign_autorounding: this.$functions.ActivedFlag(
          this.form.campaign_autorounding
        ),
        campaign_deliveryaddress: this.form.campaign_deliveryaddress,
        campaign_deliveryzipcode: this.form.campaign_deliveryzipcode,
        campaign_deliveryreceiptname: this.form.campaign_deliveryreceiptname,
        campaign_productdate: this.form.campaign_productdate,
        campaign_productno: this.form.campaign_productno,
        campaign_initial: this.form.campaign_initial,
        campaign_title: this.form.campaign_title,
        campaign_insurance: this.form.campaign_insurance,
        campaign_risklogicf: this.$functions.ActivedFlag(
          this.form.campaign_risklogicf
        ),
        campaign_actived: this.$functions.ActivedFlag(
          this.form.campaign_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaign"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.campaign_cuser = feedback[0].feedback_users_id;
                this.form.campaign_cdate = feedback[0].feedback_users_date;
              }
              this.form.campaign_luser = feedback[0].feedback_users_id;
              this.form.campaign_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
              this.form.campaign_id = feedback[0].feedback_campaign_id;
              this.$refs.tabgeneral.$el.click();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    // Function Campaign Risk
    SelectAllCampaignRisk() {
      this.campaignrisk.selected =
        this.campaignrisk.selected === this.campaignrisk.items
          ? []
          : this.campaignrisk.items;
    },

    ListCampaignRisk(id) {
      this.loading.listcampaignrisk = true;
      let formdata = {
        campaignrisk_campaignid: id,
        order_by: "campaignrisk_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignRisk"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaignrisk.items = feedback;
            }
          } else {
            this.campaignrisk.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaignrisk = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaignrisk = false;
        });
    },

    CheckCampaignRisk(value) {
      this.campaignrisk.selected = value;
    },

    SearchCampaignRisk() {
      this.campaignrisk.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaignrisk").focus();
      }, 500);
    },

    OpenCampaignRisk() {
      this.dialog.campaignrisk = true;
      this.AddCampaignRisk();
    },

    AddCampaignRisk() {
      this.url_type = "addcampaignrisk";
      this.form.campaignrisk_riskcovid = "";
      this.form.campaignrisk_riskcovdesc = "";
      this.form.campaignrisk_desc = "";
      this.form.campaignrisk_rate = "";
      this.form.campaignrisk_rateunit = "";
      this.form.campaignrisk_rateloading = "";
      this.form.campaignrisk_category = "";
      this.form.campaignrisk_sdate = this.form.campaign_effdate;
      this.form.campaignrisk_edate = this.form.campaign_expdate;

      document.getElementById("campaignrisk_riskcovid").value = "";

      this.flag.campaignrisk = true;
      this.dialog.campaignrisk = true;

      this.$refs.campaignrisk_rate.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignrisk_rateloading.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
    },

    WriteCampaignRisk() {
      let riskcovid;
      riskcovid = document.getElementById("campaignrisk_riskcovid").value;
      let formdata = {
        url_type: this.url_type,
        campaignrisk_campaignid: this.form.campaign_id,
        campaignrisk_riskcovid: riskcovid,
        campaignrisk_desc: this.form.campaignrisk_desc,
        campaignrisk_rate: this.form.campaignrisk_rate,
        campaignrisk_rateunit: this.form.campaignrisk_rateunit,
        campaignrisk_rateloading: this.form.campaignrisk_rateloading,
        campaignrisk_category: this.form.campaignrisk_category,
        campaignrisk_sdate: this.form.campaignrisk_sdate,
        campaignrisk_edate: this.form.campaignrisk_edate,
        campaignrisk_riskcovid_par: this.form.campaignrisk_riskcovid_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignRisk"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaignrisk") {
                this.form.campaign_cuser = feedback[0].feedback_users_id;
                this.form.campaign_cdate = feedback[0].feedback_users_date;
              }
              this.form.campaign_luser = feedback[0].feedback_users_id;
              this.form.campaign_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaignrisk";
              this.dialog.campaignrisk = false;
              this.ListCampaignRisk(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignRisk(id, riskcovid) {
      this.flag.campaignrisk = true;
      this.dialog.campaignrisk = true;
      this.loading.campaignriskdialog = true;
      let formdata = {
        campaignrisk_campaignid: id,
        campaignrisk_riskcovid: riskcovid,
        order_by: "campaignrisk_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignRisk"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaignrisk";

              this.form.campaignrisk_campaignid =
                feedback[0].campaignrisk_campaignid;
              this.form.campaignrisk_riskcovid =
                feedback[0].campaignrisk_riskcovid;
              this.form.campaignrisk_riskcovdesc =
                feedback[0].campaignrisk_riskcovdesc;
              this.form.campaignrisk_desc = feedback[0].campaignrisk_desc;
              this.form.campaignrisk_rate = feedback[0].campaignrisk_rate;
              this.form.campaignrisk_rateunit =
                feedback[0].campaignrisk_rateunit;
              this.form.campaignrisk_rateloading =
                feedback[0].campaignrisk_rateloading;
              this.form.campaignrisk_category =
                feedback[0].campaignrisk_category;
              this.form.campaignrisk_sdate = feedback[0].campaignrisk_sdate;
              this.form.campaignrisk_edate = feedback[0].campaignrisk_edate;

              this.$refs.campaignrisk_rate.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignrisk_rate;
              this.$refs.campaignrisk_rateloading.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignrisk_rateloading;

              this.form.campaignrisk_riskcovid_par =
                feedback[0].campaignrisk_riskcovid;

              this.form.campaignrisk_cdate = feedback[0].campaignrisk_cdate;
              this.form.campaignrisk_cuser = feedback[0].campaignrisk_cuser;
              this.form.campaignrisk_ldate = feedback[0].campaignrisk_ldate;
              this.form.campaignrisk_luser = feedback[0].campaignrisk_luser;
              this.dialog.campaignrisk = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.campaignriskdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.campaignriskdialog = false;
        });
    },

    DeleteCampaignRisk(id, riskcovid) {
      let formdata = {
        url_type: "deletecampaignrisk",
        campaignrisk_campaignid: id,
        campaignrisk_riskcovid: riskcovid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignRisk"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaignrisk.selected = [];
              this.ListCampaignRisk(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignRisk() {
      let multiData = JSON.stringify(this.campaignrisk.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignRisk"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaignrisk.selected = [];
            if (this.url_type === "multideletecampaignrisk") {
              this.ListCampaignRisk(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // end: Function Campaign Risk

    // Function Campaign Deductible
    // SelectAllCampaignDeductible() {
    //   this.campaigndeductible.selected =
    //     this.campaigndeductible.selected === this.campaigndeductible.items
    //       ? []
    //       : this.campaigndeductible.items;
    // },

    // ListCampaignDeductible(id) {
    //   this.loading.listcampaigndeductible = true;
    //   let formdata = {
    //     campaignded_campaignid: id,
    //     order_by: "campaignded_campaignid",
    //     order_type: "ASC",
    //     limit: this.limit,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiListCampaignDeductible"), param, {
    //       headers: {
    //         "Content-Type": "text/plain charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback !== "Y") {
    //           this.SnackBar(true, "error", feedback[0].feedback, 0);
    //         } else {
    //           this.campaigndeductible.items = feedback;
    //         }
    //       } else {
    //         this.campaigndeductible.items = feedback;
    //         this.SnackBar(true, "error", this.$functions.NoData(), 0);
    //       }
    //       this.loading.listcampaigndeductible = false;
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", e, 3000);
    //       this.loading.listcampaigndeductible = false;
    //     });
    // },

    // CheckCampaignDeductible(value) {
    //   this.campaigndeductible.selected = value;
    // },

    // SearchCampaignDeductible() {
    //   this.campaigndeductible.searchbutton = true;
    //   setTimeout(function () {
    //     document.getElementById("searchcampaigndeductible").focus();
    //   }, 500);
    // },

    // OpenCampaignDeductible() {
    //   this.dialog.campaigndeductible = true;
    //   this.AddCampaignDeductible();
    // },

    // AddCampaignDeductible() {
    //   this.url_type = "addcampaigndeductible";
    //   this.form.campaignded_id = "";
    //   this.form.campaignded_withflag = 0;
    //   this.form.campaignded_ccy = "";
    //   this.form.campaignded_forindemnity = "";
    //   this.form.campaignded_tsisipct = "";
    //   this.form.campaignded_tsisiflag = 0;
    //   this.form.campaignded_claimpct = "";
    //   this.form.campaignded_amountmin = "";
    //   this.form.campaignded_amountmax = "";
    //   this.form.campaignded_remarkseng = "";
    //   this.form.campaignded_remarksind = "";
    //   this.form.campaignded_totallossflag = 0;
    //   this.form.campaignded_actived = "";

    //   this.$refs.campaignded_tsisipct.$el.getElementsByTagName(
    //     "input"
    //   )[0].value = 0;
    //   this.$refs.campaignded_claimpct.$el.getElementsByTagName(
    //     "input"
    //   )[0].value = 0;
    //   this.$refs.campaignded_amountmin.$el.getElementsByTagName(
    //     "input"
    //   )[0].value = 0;
    //   this.$refs.campaignded_amountmax.$el.getElementsByTagName(
    //     "input"
    //   )[0].value = 0;

    //   this.flag.campaigndeductible = true;
    //   this.dialog.campaigndeductible = true;
    // },

    // WriteCampaignDeductible() {
    //   let deductible = document.getElementById("campaignded_id").value;
    //   let ccy = document.getElementById("campaignded_ccy").value;
    //   let forindemnity = document.getElementById(
    //     "campaignded_forindemnity"
    //   ).value;
    //   let withflag;
    //   let totallossflag;
    //   let tsisiflag;

    //   if (this.form.campaignded_withflag == 1) {
    //     withflag = "Y";
    //   } else {
    //     withflag = "N";
    //   }

    //   if (this.form.campaignded_totallossflag == 1) {
    //     totallossflag = "Y";
    //   } else {
    //     totallossflag = "N";
    //   }

    //   if (this.form.campaignded_tsisiflag == 0) {
    //     tsisiflag = "SI";
    //   } else {
    //     tsisiflag = "TSI";
    //   }

    //   let formdata = {
    //     url_type: this.url_type,
    //     campaignded_campaignid: this.form.campaign_id,
    //     campaignded_id: deductible,
    //     campaignded_withflag: withflag,
    //     campaignded_ccy: ccy,
    //     campaignded_forindemnity: forindemnity,
    //     campaignded_tsisipct: this.form.campaignded_tsisipct,
    //     campaignded_tsisiflag: tsisiflag,
    //     campaignded_claimpct: this.form.campaignded_claimpct,
    //     campaignded_amountmin: this.form.campaignded_amountmin,
    //     campaignded_amountmax: this.form.campaignded_amountmax,
    //     campaignded_remarkseng: this.form.campaignded_remarkseng,
    //     campaignded_remarksind: this.form.campaignded_remarksind,
    //     campaignded_totallossflag: totallossflag,
    //     campaignded_actived: this.$functions.ActivedFlag(
    //       this.form.campaignded_actived
    //     ),
    //     campaignded_id_par: this.form.campaignded_id_par,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiWriteCampaignDeductible"), param, {
    //       headers: {
    //         "Content-Type": "text/plain; charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback === "Y") {
    //           this.SnackBar(true, "success", "Save Success", 3000);
    //           this.confirm.dialog = false;
    //           this.confirm.text = "Ok";
    //           if (this.url_type === "addcampaigndeductible") {
    //             this.form.campaignded_cuser = feedback[0].feedback_users_id;
    //             this.form.campaignded_cdate = feedback[0].feedback_users_date;
    //           }
    //           this.form.campaignded_luser = feedback[0].feedback_users_id;
    //           this.form.campaignded_ldate = feedback[0].feedback_users_date;
    //           this.url_type = "editcampaigndeductible";
    //           this.dialog.campaigndeductible = false;
    //           this.ListCampaignDeductible(this.form.campaign_id);
    //         } else {
    //           this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //           this.remarks.dialog = true;
    //           this.remarks.feedback = feedback[0].feedback;
    //         }
    //       } else {
    //         this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //         this.remarks.dialog = true;
    //         this.remarks.feedback = feedback;
    //       }
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //     });
    // },

    // EditCampaignDeductible(id, deductible) {
    //   this.flag.campaigndeductible = true;
    //   this.dialog.campaigndeductible = true;
    //   this.loading.campaigndeductibledialog = true;
    //   let formdata = {
    //     campaignded_campaignid: id,
    //     campaignded_id: deductible,
    //     order_by: "campaignded_campaignid",
    //     order_type: "ASC",
    //     limit: this.limit,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiListCampaignDeductible"), param, {
    //       headers: {
    //         "Content-Type": "text/plain charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback === "Y") {
    //           this.url_type = "editcampaigndeductible";

    //           let tsisiflag;
    //           if (feedback[0].campaignded_tsisiflag == "SI") {
    //             tsisiflag = 0;
    //           } else {
    //             tsisiflag = 1;
    //           }

    //           this.form.campaignded_campaignid =
    //             feedback[0].campaignded_campaignid;
    //           this.form.campaignded_id = feedback[0].campaignded_id;
    //           this.form.campaignded_withflag = this.$functions.YesOrNo(
    //             feedback[0].campaignded_withflag
    //           );
    //           this.form.campaignded_ccy = feedback[0].campaignded_ccy;
    //           this.form.campaignded_forindemnity =
    //             feedback[0].campaignded_forindemnity;
    //           this.form.campaignded_tsisipct = feedback[0].campaignded_tsisipct;
    //           this.form.campaignded_tsisiflag = tsisiflag;
    //           this.form.campaignded_claimpct = feedback[0].campaignded_claimpct;
    //           this.form.campaignded_amountmin =
    //             feedback[0].campaignded_amountmin;
    //           this.form.campaignded_amountmax =
    //             feedback[0].campaignded_amountmax;
    //           this.form.campaignded_remarkseng =
    //             feedback[0].campaignded_remarkseng;
    //           this.form.campaignded_remarksind =
    //             feedback[0].campaignded_remarksind;
    //           this.form.campaignded_totallossflag = this.$functions.YesOrNo(
    //             feedback[0].campaignded_totallossflag
    //           );
    //           this.form.campaignded_actived = this.$functions.ActivedFlag(
    //             feedback[0].campaignded_actived
    //           );

    //           this.$refs.campaignded_tsisipct.$el.getElementsByTagName(
    //             "input"
    //           )[0].value = feedback[0].campaignded_tsisipct;
    //           this.$refs.campaignded_claimpct.$el.getElementsByTagName(
    //             "input"
    //           )[0].value = feedback[0].campaignded_claimpct;
    //           this.$refs.campaignded_amountmin.$el.getElementsByTagName(
    //             "input"
    //           )[0].value = feedback[0].campaignded_amountmin;
    //           this.$refs.campaignded_amountmax.$el.getElementsByTagName(
    //             "input"
    //           )[0].value = feedback[0].campaignded_amountmax;

    //           this.form.campaignded_id_par = feedback[0].campaignded_id;

    //           this.form.campaignded_cdate = feedback[0].campaignded_cdate;
    //           this.form.campaignded_cuser = feedback[0].campaignded_cuser;
    //           this.form.campaignded_ldate = feedback[0].campaignded_ldate;
    //           this.form.campaignded_luser = feedback[0].campaignded_luser;
    //           this.dialog.campaigndeductible = true;
    //         } else {
    //           this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //           this.remarks.dialog = true;
    //           this.remarks.feedback = feedback[0].feedback;
    //         }
    //       } else {
    //         this.SnackBar(true, "error", feedback, 0);
    //       }
    //       this.loading.campaigndeductibledialog = false;
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //       this.loading.campaigndeductibledialog = false;
    //     });
    // },

    // DeleteCampaignDeductible(id, deductible) {
    //   let formdata = {
    //     url_type: "deletecampaigndeductible",
    //     campaignded_campaignid: id,
    //     campaignded_id: deductible,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiWriteCampaignDeductible"), param, {
    //       headers: {
    //         "Content-Type": "text/plain; charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback === "Y") {
    //           this.SnackBar(true, "success", "Delete Success", 3000);
    //           this.confirm.dialog = false;
    //           this.confirm.text = "Ok";
    //           this.campaigndeductible.selected = [];
    //           this.ListCampaignDeductible(this.form.campaign_id);
    //         } else {
    //           this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //           this.remarks.dialog = true;
    //           this.remarks.feedback = feedback[0].feedback;
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //     });
    // },

    // MultiProcessCampaignDeductible() {
    //   let multiData = JSON.stringify(this.campaigndeductible.selected);
    //   let formdata = {
    //     url_type: this.url_type,
    //     multi_data: multiData,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiWriteCampaignDeductible"), param, {
    //       headers: {
    //         "Content-Type": "text/plain charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback[0].feedback === "Y") {
    //         let feed;
    //         if (feedback[0].feedback_failed == 0) {
    //           feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
    //         } else {
    //           this.remarks.feedback = feedback[0].feedback_failed_remarks;
    //           feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
    //           this.remarks.dialog = true;
    //         }
    //         this.SnackBar(true, "success", feed, 0);
    //         this.confirm.dialog = false;
    //         this.confirm.text = "Ok";
    //         this.campaigndeductible.selected = [];
    //         if (this.url_type === "multideletecampaigndeductible") {
    //           this.ListCampaignDeductible(this.form.campaign_id);
    //         }
    //       } else {
    //         this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //         this.remarks.dialog = true;
    //         this.remarks.feedback = feedback[0].feedback;
    //       }
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //     });
    // },
    // end: Function Campaign Deductible

    // Function Interest
    SelectAllCampaignInt() {
      this.campaignint.selected =
        this.campaignint.selected === this.campaignint.items
          ? []
          : this.campaignint.items;
    },

    ListCampaignInt(id) {
      this.loading.listcampaignint = true;
      let formdata = {
        campaignint_campaignid: id,
        order_by: "campaignint_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignInt"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaignint.items = feedback;
            }
          } else {
            this.campaignint.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaignint = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaignint = false;
        });
    },

    CheckCampaignInt(value) {
      this.campaignint.selected = value;
    },

    SearchCampaignInt() {
      this.campaignint.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaignint").focus();
      }, 500);
    },

    OpenCampaignInt() {
      this.dialog.campaignint = true;
      this.AddCampaignInt();
    },

    AddCampaignInt() {
      this.url_type = "addcampaignint";
      this.form.campaignint_campaignid = "";
      this.form.campaignint_id = "";
      this.form.campaignint_desc = "";
      this.form.campaignint_category = "";
      this.form.campaignint_remarks = "";
      this.form.campaignint_adjustable = 0;
      this.form.campaignint_limitofliabilityoc = "";
      this.form.campaignint_tsioc = "";
      this.form.campaignint_aggregateoc = "";
      this.form.campaignint_limitofliability = "";
      this.form.campaignint_tsi = "";
      this.form.campaignint_aggregate = "";
      this.form.campaignint_firstloss = 0;
      this.form.campaignint_adjustablerate = "";
      setTimeout(function () {
        let currency = "IDR";
        document.getElementById("campaignint_currency").value = currency;
        document.getElementById("campaignint_currencyoc").value = currency;
        this.form.campaignint_currency = currency;
        this.form.campaignint_currencyoc = currency;
        this.$refs.campaignint_limitofliabilityoc.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
        this.$refs.campaignint_tsioc.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
        this.$refs.campaignint_aggregateoc.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
        this.$refs.campaignint_limitofliability.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
        this.$refs.campaignint_tsi.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
        this.$refs.campaignint_aggregate.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
        this.$refs.campaignint_adjustablerate.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
      }, 500);
      this.flag.campaignint = true;
      this.dialog.campaignint = true;
    },

    WriteCampaignInt() {
      let intid;
      let currencyoc;
      let currency;
      intid = document.getElementById("campaignint_id").value;
      currencyoc = document.getElementById("campaignint_currencyoc").value;
      currency = document.getElementById("campaignint_currency").value;
      let adjustable;
      let firstloss;

      if (this.form.campaignint_adjustable == 1) {
        adjustable = "Y";
      } else {
        adjustable = "N";
      }

      if (this.form.campaignint_firstloss == 1) {
        firstloss = "Y";
      } else {
        firstloss = "N";
      }

      let formdata = {
        url_type: this.url_type,
        campaignint_campaignid: this.form.campaign_id,
        campaignint_id: intid,
        campaignint_category: this.form.campaignint_category,
        campaignint_remarks: this.form.campaignint_remarks,
        campaignint_adjustable: adjustable,
        campaignint_currencyoc: currencyoc,
        campaignint_currency: currency,
        campaignint_limitofliabilityoc:
          this.form.campaignint_limitofliabilityoc,
        campaignint_tsioc: this.form.campaignint_tsioc,
        campaignint_aggregateoc: this.form.campaignint_aggregateoc,
        campaignint_limitofliability: this.form.campaignint_limitofliability,
        campaignint_tsi: this.form.campaignint_tsi,
        campaignint_aggregate: this.form.campaignint_aggregate,
        campaignint_firstloss: firstloss,
        campaignint_adjustablerate: this.form.campaignint_adjustablerate,
        campaignint_id_par: this.form.campaignint_id_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignInt"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaignint") {
                this.form.campaign_cuser = feedback[0].feedback_users_id;
                this.form.campaign_cdate = feedback[0].feedback_users_date;
              }
              this.form.campaign_luser = feedback[0].feedback_users_id;
              this.form.campaign_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaignint";
              this.dialog.campaignint = false;
              this.ListCampaignInt(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignInt(id, intid) {
      this.flag.campaignint = true;
      this.dialog.campaignint = true;
      this.loading.campaignintdialog = true;
      let formdata = {
        campaignint_campaignid: id,
        campaignint_id: intid,
        order_by: "campaignint_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignInt"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaignint";

              this.form.campaignint_campaignid =
                feedback[0].campaignint_campaignid;
              this.form.campaignint_id = feedback[0].campaignint_id;
              this.form.campaignint_desc = feedback[0].campaignint_desc;
              this.form.campaignint_category = feedback[0].campaignint_category;
              this.form.campaignint_remarks = feedback[0].campaignint_remarks;
              this.form.campaignint_adjustable = this.$functions.YesOrNo(
                feedback[0].campaignint_adjustable
              );
              this.form.campaignint_currencyoc =
                feedback[0].campaignint_currencyoc;
              this.form.campaignint_currencyoc_desc =
                feedback[0].campaignint_currencyoc_desc;
              this.form.campaignint_currency = feedback[0].campaignint_currency;
              this.form.campaignint_currency_desc =
                feedback[0].campaignint_currency_desc;
              this.form.campaignint_limitofliabilityoc =
                feedback[0].campaignint_limitofliabilityoc;
              this.form.campaignint_tsioc = feedback[0].campaignint_tsioc;
              this.form.campaignint_aggregateoc =
                feedback[0].campaignint_aggregateoc;
              this.form.campaignint_limitofliability =
                feedback[0].campaignint_limitofliability;
              this.form.campaignint_tsi = feedback[0].campaignint_tsi;
              this.form.campaignint_aggregate =
                feedback[0].campaignint_aggregate;
              this.form.campaignint_firstloss = this.$functions.YesOrNo(
                feedback[0].campaignint_firstloss
              );

              this.$refs.campaignint_limitofliabilityoc.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_limitofliabilityoc;
              this.$refs.campaignint_tsioc.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_tsioc;
              this.$refs.campaignint_tsioc.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_tsioc;
              this.$refs.campaignint_aggregateoc.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_aggregateoc;
              this.$refs.campaignint_limitofliability.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_limitofliability;
              this.$refs.campaignint_tsi.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_tsi;
              this.$refs.campaignint_aggregate.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_aggregate;
              this.$refs.campaignint_adjustablerate.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignint_adjustablerate;

              this.form.campaignint_id_par = feedback[0].campaignint_id;

              this.form.campaignint_cdate = feedback[0].campaignint_cdate;
              this.form.campaignint_cuser = feedback[0].campaignint_cuser;
              this.form.campaignint_ldate = feedback[0].campaignint_ldate;
              this.form.campaignint_luser = feedback[0].campaignint_luser;
              this.dialog.campaignint = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.campaignintdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.campaignintdialog = false;
        });
    },

    DeleteCampaignInt(id, intid) {
      let formdata = {
        url_type: "deletecampaignint",
        campaignint_campaignid: id,
        campaignint_id: intid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignInt"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaignint.selected = [];
              this.ListCampaignInt(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignInt() {
      let multiData = JSON.stringify(this.campaignint.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignInt"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaignint.selected = [];
            if (this.url_type === "multideletecampaignint") {
              this.ListCampaignInt(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    LOLOnType() {
      let limitofliability;
      limitofliability = document.getElementById(
        "campaignint_limitofliability"
      ).value;
      this.form.campaignint_tsi = limitofliability;
      this.form.campaignint_aggregate = limitofliability;

      this.$refs.campaignint_tsi.$el.getElementsByTagName("input")[0].value =
        limitofliability;
      this.$refs.campaignint_aggregate.$el.getElementsByTagName(
        "input"
      )[0].value = limitofliability;
    },

    LOLOCOnType() {
      let limitofliabilityoc;
      limitofliabilityoc = this.form.campaignint_limitofliabilityoc;
      this.form.campaignint_tsioc = limitofliabilityoc;
      this.form.campaignint_aggregateoc = limitofliabilityoc;

      this.$refs.campaignint_tsioc.$el.getElementsByTagName("input")[0].value =
        limitofliabilityoc;
      this.$refs.campaignint_aggregateoc.$el.getElementsByTagName(
        "input"
      )[0].value = limitofliabilityoc;
    },
    // end: Function Interest

    // Function Fee & Duty
    SelectAllCampaignFeeDuty() {
      this.campaignfeeduty.selected =
        this.campaignfeeduty.selected === this.campaignfeeduty.items
          ? []
          : this.campaignfeeduty.items;
    },

    ListCampaignFeeDuty(id) {
      this.loading.listcampaignfeeduty = true;
      let formdata = {
        campaignfeeduty_campaignid: id,
        order_by: "campaignfeeduty_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignFeeDuty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaignfeeduty.items = feedback;
            }
          } else {
            this.campaignfeeduty.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaignfeeduty = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaignfeeduty = false;
        });
    },

    CheckCampaignFeeDuty(value) {
      this.campaignfeeduty.selected = value;
    },

    SearchCampaignFeeDuty() {
      this.campaignfeeduty.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaignfeeduty").focus();
      }, 500);
    },

    OpenCampaignFeeDuty() {
      this.dialog.campaignfeeduty = true;
      this.AddCampaignFeeDuty();
    },

    AddCampaignFeeDuty() {
      this.url_type = "addcampaignfeeduty";
      this.form.campaignfeeduty_campaignid = "";
      this.form.campaignfeeduty_id = "";
      this.form.campaignfeeduty_desc = "";
      this.form.campaignfeeduty_payorid = this.form.campaign_pholder;
      this.form.campaignfeeduty_payor_desc = this.form.campaign_pholder_desc;
      this.form.campaignfeeduty_amount = "";
      this.form.campaignfeeduty_remarks = "";

      this.flag.campaignfeeduty = true;
      this.dialog.campaignfeeduty = true;

      this.$refs.campaignfeeduty_amount.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
    },

    WriteCampaignFeeDuty() {
      let feedutyid;
      let payorid;
      feedutyid = document.getElementById("campaignfeeduty_id").value;
      payorid = document.getElementById("campaignfeeduty_payorid").value;
      let formdata = {
        url_type: this.url_type,
        campaignfeeduty_campaignid: this.form.campaign_id,
        campaignfeeduty_id: feedutyid,
        campaignfeeduty_payorid: payorid,
        campaignfeeduty_amount: String(this.form.campaignfeeduty_amount),
        campaignfeeduty_remarks: this.form.campaignfeeduty_remarks,
        campaignfeeduty_id_par: this.form.campaignfeeduty_id_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignFeeDuty"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaignfeeduty") {
                this.form.campaignfeeduty_cuser = feedback[0].feedback_users_id;
                this.form.campaignfeeduty_cdate =
                  feedback[0].feedback_users_date;
              }
              this.form.campaignfeeduty_luser = feedback[0].feedback_users_id;
              this.form.campaignfeeduty_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaignfeeduty";
              this.dialog.campaignfeeduty = false;
              this.ListCampaignFeeDuty(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignFeeDuty(id, feedutyid) {
      this.flag.campaignfeeduty = true;
      this.dialog.campaignfeeduty = true;
      this.loading.campaignfeedutydialog = true;
      let formdata = {
        campaignfeeduty_campaignid: id,
        campaignfeeduty_id: feedutyid,
        order_by: "campaignfeeduty_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignFeeDuty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaignfeeduty";

              this.form.campaignfeeduty_campaignid =
                feedback[0].campaignfeeduty_campaignid;
              this.form.campaignfeeduty_id = feedback[0].campaignfeeduty_id;
              this.form.campaignfeeduty_desc = feedback[0].campaignfeeduty_desc;
              this.form.campaignfeeduty_payorid =
                feedback[0].campaignfeeduty_payorid;
              this.form.campaignfeeduty_payor_desc =
                feedback[0].campaignfeeduty_payor_desc;
              this.form.campaignfeeduty_amount =
                feedback[0].campaignfeeduty_amount;
              this.form.campaignfeeduty_remarks =
                feedback[0].campaignfeeduty_remarks;

              this.form.campaignfeeduty_id_par = feedback[0].campaignfeeduty_id;

              this.form.campaignfeeduty_cdate =
                feedback[0].campaignfeeduty_cdate;
              this.form.campaignfeeduty_cuser =
                feedback[0].campaignfeeduty_cuser;
              this.form.campaignfeeduty_ldate =
                feedback[0].campaignfeeduty_ldate;
              this.form.campaignfeeduty_luser =
                feedback[0].campaignfeeduty_luser;
              this.dialog.campaignfeeduty = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.campaignfeedutydialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.campaignfeedutydialog = false;
        });
    },

    DeleteCampaignFeeDuty(id, feedutyid) {
      let formdata = {
        url_type: "deletecampaignfeeduty",
        campaignfeeduty_campaignid: id,
        campaignfeeduty_id: feedutyid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignFeeDuty"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaignfeeduty.selected = [];
              this.ListCampaignFeeDuty(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignFeeDuty() {
      let multiData = JSON.stringify(this.campaignfeeduty.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignFeeDuty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaignfeeduty.selected = [];
            if (this.url_type === "multideletecampaignfeeduty") {
              this.ListCampaignFeeDuty(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // end: Function Fee & Duty

    // Function Biz Source
    SelectAllCampaignSource() {
      this.campaignsource.selected =
        this.campaignsource.selected === this.campaignsource.items
          ? []
          : this.campaignsource.items;
    },

    ListCampaignSource(id) {
      this.loading.listcampaignsource = true;
      let formdata = {
        campaignsource_campaignid: id,
        order_by: "campaignsource_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignSource"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaignsource.items = feedback;
            }
          } else {
            this.campaignsource.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaignsource = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaignsource = false;
        });
    },

    CheckCampaignSource(value) {
      this.campaignsource.selected = value;
    },

    SearchCampaignSource() {
      this.campaignsource.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaignsource").focus();
      }, 500);
    },

    OpenCampaignSource() {
      this.dialog.campaignsource = true;
      this.AddCampaignSource();
    },

    AddCampaignSource() {
      this.url_type = "addcampaignsource";
      this.form.campaignsource_campaignid = "";
      this.form.campaignsource_profileid = "";
      this.form.campaignsource_profile_desc = "";
      this.form.campaignsource_type = "";
      this.form.campaignsource_mainflag = 0;
      this.form.campaignsource_feepct = "";
      this.form.campaignsource_discount = "";
      this.form.campaignsource_netaftermemberflag = 0;
      this.form.campaignsource_grosscommflag = 0;
      this.form.campaignsource_memberfeeflag = 0;
      this.form.campaignsource_feeamt = "";
      this.form.campaignsource_tax = "";
      this.form.campaignsource_vat = "";

      this.flag.campaignsource = true;
      this.dialog.campaignsource = true;

      this.$refs.campaignsource_feepct.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsource_discount.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsource_feeamt.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsource_tax.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsource_vat.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
    },

    WriteCampaignSource() {
      let profileid;
      let type;
      profileid = document.getElementById("campaignsource_profileid").value;
      type = document.getElementById("campaignsource_type").value;
      let mainflag;
      let netaftermemberflag;
      let grosscommflag;
      let memberfeeflag;

      if (this.form.campaignsource_mainflag == 1) {
        mainflag = "Y";
      } else {
        mainflag = "N";
      }
      if (this.form.campaignsource_netaftermemberflag == 1) {
        netaftermemberflag = "Y";
      } else {
        netaftermemberflag = "N";
      }
      if (this.form.campaignsource_grosscommflag == 1) {
        grosscommflag = "Y";
      } else {
        grosscommflag = "N";
      }

      if (this.form.campaignsource_memberfeeflag == 1) {
        memberfeeflag = "Y";
      } else {
        memberfeeflag = "N";
      }

      let formdata = {
        url_type: this.url_type,
        campaignsource_campaignid: this.form.campaign_id,
        campaignsource_profileid: profileid,
        campaignsource_type: type,
        campaignsource_mainflag: mainflag,
        campaignsource_feepct: this.form.campaignsource_feepct,
        campaignsource_discount: this.form.campaignsource_discount,
        campaignsource_netaftermemberflag: netaftermemberflag,
        campaignsource_grosscommflag: grosscommflag,
        campaignsource_memberfeeflag: memberfeeflag,
        campaignsource_feeamt: this.form.campaignsource_feeamt,
        campaignsource_tax: this.form.campaignsource_tax,
        campaignsource_vat: this.form.campaignsource_vat,
        campaignsource_profileid_par: this.form.campaignsource_profileid_par,
        campaignsource_type_par: this.form.campaignsource_type_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignSource"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaignsource") {
                this.form.campaignsource_cuser = feedback[0].feedback_users_id;
                this.form.campaignsource_cdate =
                  feedback[0].feedback_users_date;
              }
              this.form.campaignsource_luser = feedback[0].feedback_users_id;
              this.form.campaignsource_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaignsource";
              this.dialog.campaignsource = false;
              this.ListCampaignSource(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignSource(id, profileid, type) {
      this.flag.campaignsource = true;
      this.dialog.campaignsource = true;
      this.loading.campaignsourcedialog = true;
      let formdata = {
        campaignsource_campaignid: id,
        campaignsource_profileid: profileid,
        campaignsource_type: type,
        order_by: "campaignsource_profileid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignSource"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaignsource";

              this.form.campaignsource_campaignid =
                feedback[0].campaignsource_campaignid;
              this.form.campaignsource_profileid =
                feedback[0].campaignsource_profileid;
              this.form.campaignsource_profile_desc =
                feedback[0].campaignsource_profile_desc;
              this.form.campaignsource_type = feedback[0].campaignsource_type;
              this.form.campaignsource_mainflag = this.$functions.YesOrNo(
                feedback[0].campaignsource_mainflag
              );
              this.form.campaignsource_feepct =
                feedback[0].campaignsource_feepct;
              this.form.campaignsource_discount =
                feedback[0].campaignsource_discount;
              this.form.campaignsource_netaftermemberflag =
                this.$functions.YesOrNo(
                  feedback[0].campaignsource_netaftermemberflag
                );
              this.form.campaignsource_grosscommflag = this.$functions.YesOrNo(
                feedback[0].campaignsource_grosscommflag
              );
              this.form.campaignsource_memberfeeflag = this.$functions.YesOrNo(
                feedback[0].campaignsource_memberfeeflag
              );
              this.form.campaignsource_feeamt =
                feedback[0].campaignsource_feeamt;
              this.form.campaignsource_tax = feedback[0].campaignsource_tax;
              this.form.campaignsource_vat = feedback[0].campaignsource_vat;

              this.$refs.campaignsource_feepct.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsource_feepct;
              this.$refs.campaignsource_discount.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsource_discount;
              this.$refs.campaignsource_feeamt.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsource_feeamt;
              this.$refs.campaignsource_tax.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsource_tax;
              this.$refs.campaignsource_vat.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsource_vat;

              this.form.campaignsource_profileid_par =
                feedback[0].campaignsource_profileid;
              this.form.campaignsource_type_par =
                feedback[0].campaignsource_type;

              this.form.campaignsource_cdate = feedback[0].campaignsource_cdate;
              this.form.campaignsource_cuser = feedback[0].campaignsource_cuser;
              this.form.campaignsource_ldate = feedback[0].campaignsource_ldate;
              this.form.campaignsource_luser = feedback[0].campaignsource_luser;
              this.dialog.campaignsource = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.campaignsourcedialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.campaignsourcedialog = false;
        });
    },

    DeleteCampaignSource(id, profileid, type) {
      let formdata = {
        url_type: "deletecampaignsource",
        campaignsource_campaignid: id,
        campaignsource_profileid: profileid,
        campaignsource_type: type,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignSource"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaignsource.selected = [];
              this.ListCampaignSource(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignSource() {
      let multiData = JSON.stringify(this.campaignsource.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignSource"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaignsource.selected = [];
            if (this.url_type === "multideletecampaignsource") {
              this.ListCampaignSource(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // end: Function Biz Source

    // Function Payor & Delivery
    CheckPayorDelivery(id) {
      if (id != "") {
        this.ListPayorDelivery(id);
      } else {
        let payorid = document.getElementById("campaign_pholder").value;
        let payordesc = document.getElementById("campaign_pholder_desc").value;
        let data = this;
        setTimeout(function () {
          document.getElementById("campaign_payorid").value = payorid;
          document.getElementById("campaign_payor_desc").value = payordesc;
          data.form.campaign_payorid = payorid;
          data.form.campaign_payor_desc = payordesc;
        }, 500);
      }
    },

    AddPayorDelivery() {
      this.form.campaign_payorid = "";
      this.form.campaign_payor_desc = "";
      this.form.campaign_payoraddress = "";
      this.form.campaign_discountpct = "";
      this.form.campaign_discountamt = "";
      this.form.campaign_paymentmethod = "";
      this.form.campaign_accountno = "";
      this.form.campaign_accountbank = "";
      this.form.campaign_accountname = "";
      this.form.campaign_periodterm = "";
      this.form.campaign_numinstallment = "";
      this.form.campaign_firstinstallment = "";
      this.form.campaign_billingpolyear = false;
      this.form.campaign_installmentfeeduty = false;
      this.form.campaign_autorounding = false;
      this.form.campaign_deliveryaddress = "";
      this.form.campaign_deliveryzipcode = "";
      this.form.campaign_deliveryreceiptname = "";

      this.$refs.campaign_discountpct.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaign_discountamt.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaign_numinstallment.$el.getElementsByTagName(
        "input"
      )[0].value = 0;

      this.form.campaign_discountpct_readonly = false;
      this.form.campaign_discountamt_readonly = false;
    },

    ListPayorDelivery(id) {
      let formdata = {
        campaign_id: id,
        campaign_desc: "",
        order_by: "campaign_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignPayorDelivery"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.campaign_payorid = feedback[0].campaign_payorid;
              this.form.campaign_payor_desc = feedback[0].campaign_payor_desc;
              this.form.campaign_payoraddress =
                feedback[0].campaign_payoraddress;
              this.form.campaign_discountpct = feedback[0].campaign_discountpct;
              this.form.campaign_discountamt = feedback[0].campaign_discountamt;
              this.form.campaign_paymentmethod =
                feedback[0].campaign_paymentmethod;
              this.form.campaign_accountno = feedback[0].campaign_accountno;
              this.form.campaign_accountbank = feedback[0].campaign_accountbank;
              this.form.campaign_accountname = feedback[0].campaign_accountname;
              this.form.campaign_periodterm = feedback[0].campaign_periodterm;
              this.form.campaign_numinstallment =
                feedback[0].campaign_numinstallment;
              this.form.campaign_firstinstallment =
                feedback[0].campaign_firstinstallment;
              this.form.campaign_billingpolyear = this.$functions.TrueOrFalse(
                feedback[0].campaign_billingpolyear
              );
              this.form.campaign_installmentfeeduty =
                this.$functions.TrueOrFalse(
                  feedback[0].campaign_installmentfeeduty
                );
              this.form.campaign_autorounding = this.$functions.TrueOrFalse(
                feedback[0].campaign_autorounding
              );
              this.form.campaign_deliveryaddress =
                feedback[0].campaign_deliveryaddress;
              this.form.campaign_deliveryzipcode =
                feedback[0].campaign_deliveryzipcode;
              this.form.campaign_deliveryreceiptname =
                feedback[0].campaign_deliveryreceiptname;
              this.$refs.campaign_discountpct.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaign_discountpct;
              this.$refs.campaign_discountamt.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaign_discountamt;
              this.$refs.campaign_numinstallment.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaign_numinstallment;

              this.form.campaign_discountpct_readonly = false;
              this.form.campaign_discountamt_readonly = false;

              if (feedback[0].campaign_paymentmethod == "PM05") {
                this.form.campaign_accountbank_readonly = false;
                this.form.campaign_accountno_readonly = false;
                this.form.campaign_accountname_readonly = false;
              } else {
                this.form.campaign_accountbank_readonly = true;
                this.form.campaign_accountno_readonly = true;
                this.form.campaign_accountname_readonly = true;
              }
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    DiscountPctOnChange(value) {
      if (value == 0) {
        this.form.campaign_discountamt_readonly = false;
        this.form.campaign_discountpct = "";
      } else {
        this.form.campaign_discountpct_readonly = false;
        this.form.campaign_discountamt_readonly = true;
        this.form.campaign_discountamt = "";
        this.$refs.campaign_discountamt.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
      }
    },

    DiscountAmtOnChange(value) {
      if (value == 0) {
        this.form.campaign_discountpct_readonly = false;
        this.form.campaign_discountamt = "";
      } else {
        this.form.campaign_discountpct_readonly = true;
        this.form.campaign_discountamt_readonly = false;
        this.form.campaign_discountpct = "";
        this.$refs.campaign_discountpct.$el.getElementsByTagName(
          "input"
        )[0].value = 0;
      }
    },

    PaymentMethodOnChange(data) {
      if (data.value == "PM05") {
        this.form.campaign_accountbank_readonly = false;
        this.form.campaign_accountno_readonly = false;
        this.form.campaign_accountname_readonly = false;
        setTimeout(function () {
          document.getElementById("campaign_accountbank").focus();
        }, 500);
      } else {
        this.form.campaign_accountbank_readonly = true;
        this.form.campaign_accountno_readonly = true;
        this.form.campaign_accountname_readonly = true;

        this.form.campaign_accountbank = "";
        this.form.campaign_accountno = "";
        this.form.campaign_accountname = "";
      }
    },

    AutoRoundingOnChecked() {
      let periodterm = this.form.campaign_periodterm;
      let numinstallment = this.form.campaign_numinstallment;
      let firstinstallment = this.form.campaign_firstinstallment;
      let autorounding = this.form.campaign_autorounding;

      if (autorounding == true) {
        if (periodterm == "") {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = "Please Check Period Term";
        } else if (numinstallment == "" || numinstallment == 0) {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = "Please Check Num Installment";
        } else if (firstinstallment == "") {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = "Please Check First Installment";
        } else {
          this.CreateInstallment();
        }
      } else {
        this.RemoveInstallment();
      }
    },

    CreateInstallment() {
      this.campaigninstallment.show = true;

      let periodterm = this.form.campaign_periodterm;
      let numinstallment = this.form.campaign_numinstallment;
      let firstinstallment = this.form.campaign_firstinstallment;

      for (let i = 1; i <= numinstallment; i++) {
        let data = {};
        let date = this.$moment(firstinstallment, "YYYY-MM-DD");
        let countmonth = 0;

        for (let x = 0; x <= periodterm; x++) {
          countmonth = i * x;
        }

        data["row"] = i;
        data["date"] = date.add(countmonth, "months").format("YYYY-MM-DD");

        this.campaigninstallment.items.push(data);
      }
    },

    RemoveInstallment() {
      this.campaigninstallment.show = false;
      this.campaigninstallment.items = [];
    },
    // end: Function Payor & Delivery

    // Function Term & Condition
    SelectAllCampaignTC() {
      this.campaigntc.selected =
        this.campaigntc.selected === this.campaigntc.items
          ? []
          : this.campaigntc.items;
    },

    ListCampaignTC(id) {
      this.loading.listcampaigntc = true;
      let formdata = {
        campaigntc_campaignid: id,
        order_by: "campaigntc_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignTC"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaigntc.items = feedback;
            }
          } else {
            this.campaigntc.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaigntc = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaigntc = false;
        });
    },

    CheckCampaignTC(value) {
      this.campaigntc.selected = value;
    },

    SearchCampaignTC() {
      this.campaigntc.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaigntc").focus();
      }, 500);
    },

    OpenCampaignTC() {
      this.dialog.campaigntc = true;
      this.AddCampaignTC();
    },

    AddCampaignTC() {
      this.url_type = "addcampaigntc";
      this.form.campaigntc_campaignid = "";
      this.form.campaigntc_id = "";
      this.form.campaigntc_classid = "";
      this.form.campaigntc_shortdesc_eng = "";
      this.form.campaigntc_longdesc_eng = "";
      this.form.campaigntc_shortdesc_ind = "";
      this.form.campaigntc_longdesc_ind = "";

      this.flag.campaigntc = true;
      this.dialog.campaigntc = true;
    },

    WriteCampaignTC() {
      let formdata = {
        url_type: this.url_type,
        campaigntc_campaignid: this.form.campaign_id,
        campaigntc_id: this.form.campaigntc_id,
        campaigntc_classid: this.form.campaigntc_classid,
        campaigntc_shortdesc_eng: this.form.campaigntc_shortdesc_eng,
        campaigntc_longdesc_eng: this.form.campaigntc_longdesc_eng,
        campaigntc_shortdesc_ind: this.form.campaigntc_shortdesc_ind,
        campaigntc_longdesc_ind: this.form.campaigntc_longdesc_ind,
        campaigntc_id_par: this.form.campaigntc_id_par,
        campaigntc_classid_par: this.form.campaigntc_classid_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignTC"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaigntc") {
                this.form.campaign_cuser = feedback[0].feedback_users_id;
                this.form.campaign_cdate = feedback[0].feedback_users_date;
              }
              this.form.campaign_luser = feedback[0].feedback_users_id;
              this.form.campaign_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaigntc";
              this.dialog.campaigntc = false;
              this.ListCampaignTC(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignTC(id, tcid, classid) {
      this.flag.campaigntc = true;
      this.dialog.campaigntc = true;
      this.loading.campaigntcdialog = true;
      let formdata = {
        campaigntc_campaignid: id,
        campaigntc_id: tcid,
        campaigntc_classid: classid,
        order_by: "campaigntc_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignTC"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaigntc";

              this.form.campaigntc_campaignid =
                feedback[0].campaigntc_campaignid;
              this.form.campaigntc_id = feedback[0].campaigntc_id;
              this.form.campaigntc_classid = feedback[0].campaigntc_classid;
              this.form.campaigntc_shortdesc_eng =
                feedback[0].campaigntc_shortdesc_eng;
              this.form.campaigntc_longdesc_eng =
                feedback[0].campaigntc_longdesc_eng;
              this.form.campaigntc_shortdesc_ind =
                feedback[0].campaigntc_shortdesc_ind;
              this.form.campaigntc_longdesc_ind =
                feedback[0].campaigntc_longdesc_ind;

              // this.$refs.campaignfeeduty_amount.$el.getElementsByTagName('input')[0].value  = feedback[0].campaignfeeduty_amount

              this.form.campaigntc_id_par = feedback[0].campaigntc_id;
              this.form.campaigntc_classid_par = feedback[0].campaigntc_classid;

              this.form.campaigntc_cdate = feedback[0].campaigntc_cdate;
              this.form.campaigntc_cuser = feedback[0].campaigntc_cuser;
              this.form.campaigntc_ldate = feedback[0].campaigntc_ldate;
              this.form.campaigntc_luser = feedback[0].campaigntc_luser;
              this.dialog.campaigntc = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.campaigntcdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.campaigntcdialog = false;
        });
    },

    DeleteCampaignTC(id, tcid, classid) {
      let formdata = {
        url_type: "deletecampaigntc",
        campaigntc_campaignid: id,
        campaigntc_id: tcid,
        campaigntc_classid: classid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignTC"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaigntc.selected = [];
              this.ListCampaignTC(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignTC() {
      let multiData = JSON.stringify(this.campaigntc.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignTC"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaigntc.selected = [];
            if (this.url_type === "multideletecampaigntc") {
              this.ListCampaignTC(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // end: Function Term & Condition

    // Function Spreading Of Risk
    SelectAllCampaignSOR() {
      this.campaignsor.selected =
        this.campaignsor.selected === this.campaignsor.items
          ? []
          : this.campaignsor.items;
    },

    ListCampaignSOR(id) {
      this.loading.listcampaignsor = true;
      let formdata = {
        campaignsor_campaignid: id,
        order_by: "campaignsor_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignSOR"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaignsor.items = feedback;
            }
          } else {
            this.campaignsor.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaignsor = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaignsor = false;
        });
    },

    CheckCampaignSOR(value) {
      this.campaignsor.selected = value;
    },

    SearchCampaignSOR() {
      this.campaignsor.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaignsor").focus();
      }, 500);
    },

    OpenCampaignSOR() {
      this.dialog.campaignsor = true;
      this.AddCampaignSOR();
    },

    AddCampaignSOR() {
      this.url_type = "addcampaignsor";
      this.form.campaignsor_campaignid = "";
      this.form.campaignsor_profileid = "";
      this.form.campaignsor_profile_desc = "";
      this.form.campaignsor_type = "";
      this.form.campaignsor_sdate = "";
      this.form.campaignsor_edate = "";
      this.form.campaignsor_leaderno = "";
      this.form.campaignsor_duedate = "";
      this.form.campaignsor_uw = "";
      this.form.campaignsor_uw_desc = "";
      this.form.campaignsor_acceptedby = "";
      this.form.campaignsor_accepteddate = "";
      this.form.campaignsor_sharepct = 0;
      this.form.campaignsor_shareamt = 0;
      this.form.campaignsor_sharetsi = 0;
      this.form.campaignsor_installment = 0;
      this.form.campaignsor_ujrohfee = 0;
      this.form.campaignsor_ujrohcedant = 0;
      this.form.campaignsor_refno = "";
      this.form.campaignsor_wpc = 0;
      this.form.campaignsor_grace = 0;
      this.form.campaignsor_remarks = "";
      this.form.campaignsor_memo = "";
      // this.form.campaignsor_periodterm              = 0
      // this.form.campaignsor_recoverydue             = 0
      // this.form.campaignsor_firstloss               = 0
      // this.form.campaignsor_limit                   = 0
      // this.form.campaignsor_inexcess                = 0
      // this.form.campaignsor_commpct                 = 0
      // this.form.campaignsor_commamt                 = 0
      // this.form.campaignsor_overcommpct             = 0
      // this.form.campaignsor_overcommamt             = 0
      // this.form.campaignsor_originalrate            = 0
      // this.form.campaignsor_premadjamt              = 0
      // this.form.campaignsor_premadjpct              = 0
      // this.form.campaignsor_acqcostpct              = 0
      // this.form.campaignsor_profitpct               = 0
      // this.form.campaignsor_pcalc                   = ''
      // this.form.campaignsor_proratepct              = 0
      // this.form.campaignsor_refundpct               = 0
      // this.form.campaignsor_claimcoop               = 0
      // this.form.campaignsor_confirmno               = 0
      // this.form.campaignsor_warranty                = 0
      // this.form.campaignsor_jpslipno                = ''
      // this.form.campaignsor_jpsharepct              = 0
      // this.form.campaignsor_jpcommpct               = 0
      // this.form.campaignsor_asoriginalpolf          = ''
      // this.form.campaignsor_allownetrategreaterf    = ''
      // this.form.campaignsor_notapplydiscountf       = ''
      // this.form.campaignsor_onpremafteroverridingf  = ''
      // this.form.campaignsor_noaddrefundf            = ''
      // this.form.campaignsor_fullcoveragef           = ''
      // this.form.campaignsor_allowpayorduegreaterf   = ''
      // this.form.campaignsor_precedefaccessriskarrf  = ''

      this.$refs.campaignsor_sharepct.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsor_shareamt.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsor_sharetsi.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsor_installment.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsor_ujrohfee.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsor_ujrohcedant.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      this.$refs.campaignsor_wpc.$el.getElementsByTagName("input")[0].value = 0;
      this.$refs.campaignsor_grace.$el.getElementsByTagName(
        "input"
      )[0].value = 0;
      // this.$refs.campaignsor_periodterm.$el.getElementsByTagName('input')[0].value    = 0
      // this.$refs.campaignsor_recoverydue.$el.getElementsByTagName('input')[0].value   = 0
      // this.$refs.campaignsor_firstloss.$el.getElementsByTagName('input')[0].value     = 0
      // this.$refs.campaignsor_limit.$el.getElementsByTagName('input')[0].value         = 0
      // this.$refs.campaignsor_inexcess.$el.getElementsByTagName('input')[0].value      = 0
      // this.$refs.campaignsor_commpct.$el.getElementsByTagName('input')[0].value       = 0
      // this.$refs.campaignsor_commamt.$el.getElementsByTagName('input')[0].value       = 0
      // this.$refs.campaignsor_overcommpct.$el.getElementsByTagName('input')[0].value   = 0
      // this.$refs.campaignsor_overcommamt.$el.getElementsByTagName('input')[0].value   = 0
      // this.$refs.campaignsor_originalrate.$el.getElementsByTagName('input')[0].value  = 0
      // this.$refs.campaignsor_premadjamt.$el.getElementsByTagName('input')[0].value    = 0
      // this.$refs.campaignsor_premadjpct.$el.getElementsByTagName('input')[0].value    = 0
      // this.$refs.campaignsor_acqcostpct.$el.getElementsByTagName('input')[0].value    = 0
      // this.$refs.campaignsor_profitpct.$el.getElementsByTagName('input')[0].value     = 0
      // this.$refs.campaignsor_proratepct.$el.getElementsByTagName('input')[0].value    = 0
      // this.$refs.campaignsor_refundpct.$el.getElementsByTagName('input')[0].value     = 0
      // this.$refs.campaignsor_claimcoop.$el.getElementsByTagName('input')[0].value     = 0
      // this.$refs.campaignsor_confirmno.$el.getElementsByTagName('input')[0].value     = 0
      // this.$refs.campaignsor_warranty.$el.getElementsByTagName('input')[0].value      = 0
      // this.$refs.campaignsor_jpsharepct.$el.getElementsByTagName('input')[0].value    = 0
      // this.$refs.campaignsor_jpcommpct.$el.getElementsByTagName('input')[0].value     = 0

      this.flag.campaignsor = true;
      this.dialog.campaignsor = true;
    },

    WriteCampaignSOR() {
      let profileid = document.getElementById("campaignsor_profileid").value;
      let uw = document.getElementById("campaignsor_uw").value;

      let formdata = {
        url_type: this.url_type,
        campaignsor_campaignid: this.form.campaign_id,
        campaignsor_profileid: profileid,
        campaignsor_type: this.form.campaignsor_type,
        campaignsor_sdate: this.form.campaignsor_sdate,
        campaignsor_edate: this.form.campaignsor_edate,
        campaignsor_leaderno: this.form.campaignsor_leaderno,
        campaignsor_duedate: this.form.campaignsor_duedate,
        campaignsor_uw: uw,
        campaignsor_acceptedby: this.form.campaignsor_acceptedby,
        campaignsor_accepteddate: this.form.campaignsor_accepteddate,
        campaignsor_sharepct: this.form.campaignsor_sharepct,
        campaignsor_shareamt: this.form.campaignsor_shareamt,
        campaignsor_sharetsi: this.form.campaignsor_sharetsi,
        campaignsor_periodterm: this.form.campaignsor_periodterm,
        campaignsor_installment: this.form.campaignsor_installment,
        campaignsor_ujrohfee: this.form.campaignsor_ujrohfee,
        campaignsor_ujrohcedant: this.form.campaignsor_ujrohcedant,
        campaignsor_refno: this.form.campaignsor_refno,
        campaignsor_wpc: this.form.campaignsor_wpc,
        campaignsor_grace: this.form.campaignsor_grace,
        campaignsor_remarks: this.form.campaignsor_remarks,
        campaignsor_memo: this.form.campaignsor_memo,
        // campaignsor_recoverydue             : this.form.campaignsor_recoverydue,
        // campaignsor_firstloss               : this.form.campaignsor_firstloss,
        // campaignsor_limit                   : this.form.campaignsor_limit,
        // campaignsor_inexcess                : this.form.campaignsor_inexcess,
        // campaignsor_commpct                 : this.form.campaignsor_commpct,
        // campaignsor_commamt                 : this.form.campaignsor_commamt,
        // campaignsor_overcommpct             : this.form.campaignsor_overcommpct,
        // campaignsor_overcommamt             : this.form.campaignsor_overcommamt,
        // campaignsor_originalrate            : this.form.campaignsor_originalrate,
        // campaignsor_premadjamt              : this.form.campaignsor_premadjamt,
        // campaignsor_premadjpct              : this.form.campaignsor_premadjpct,
        // campaignsor_acqcostpct              : this.form.campaignsor_acqcostpct,
        // campaignsor_profitpct               : this.form.campaignsor_profitpct,
        // campaignsor_pcalc                   : this.form.campaignsor_pcalc,
        // campaignsor_proratepct              : this.form.campaignsor_proratepct,
        // campaignsor_refundpct               : this.form.campaignsor_refundpct,
        // campaignsor_claimcoop               : this.form.campaignsor_claimcoop,
        // campaignsor_confirmno               : this.form.campaignsor_confirmno,
        // campaignsor_warranty                : this.form.campaignsor_warranty,
        // campaignsor_jpslipno                : this.form.campaignsor_jpslipno,
        // campaignsor_jpsharepct              : this.form.campaignsor_jpsharepct,
        // campaignsor_jpcommpct               : this.form.campaignsor_jpcommpct,
        // campaignsor_asoriginalpolf          : this.form.campaignsor_asoriginalpolf,
        // campaignsor_allownetrategreaterf    : this.form.campaignsor_allownetrategreaterf,
        // campaignsor_notapplydiscountf       : this.form.campaignsor_notapplydiscountf,
        // campaignsor_onpremafteroverridingf  : this.form.campaignsor_onpremafteroverridingf,
        // campaignsor_noaddrefundf            : this.form.campaignsor_noaddrefundf,
        // campaignsor_fullcoveragef           : this.form.campaignsor_fullcoveragef,
        // campaignsor_allowpayorduegreaterf   : this.form.campaignsor_allowpayorduegreaterf,
        // campaignsor_precedefaccessriskarrf  : this.form.campaignsor_precedefaccessriskarrf,

        campaignsor_profileid_par: this.form.campaignsor_profileid_par,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignSOR"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaignsor") {
                this.form.campaignsor_cuser = feedback[0].feedback_users_id;
                this.form.campaignsor_cdate = feedback[0].feedback_users_date;
              }
              this.form.campaignsor_luser = feedback[0].feedback_users_id;
              this.form.campaignsor_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaignsor";
              this.dialog.campaignsor = false;
              this.ListCampaignSOR(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignSOR(id, profile_id) {
      this.flag.campaignsor = true;
      this.dialog.campaignsor = true;
      this.loading.campaignsordialog = true;
      let formdata = {
        campaignsor_campaignid: id,
        campaignsor_profileid: profile_id,
        order_by: "campaignsor_profileid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignSOR"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaignsor";

              this.form.campaignsor_campaignid =
                feedback[0].campaignsor_campaignid;
              this.form.campaignsor_profileid =
                feedback[0].campaignsor_profileid;
              this.form.campaignsor_profile_desc =
                feedback[0].campaignsor_profile_desc;
              this.form.campaignsor_type = feedback[0].campaignsor_type;
              this.form.campaignsor_sdate = feedback[0].campaignsor_sdate;
              this.form.campaignsor_edate = feedback[0].campaignsor_edate;
              this.form.campaignsor_leaderno = feedback[0].campaignsor_leaderno;
              this.form.campaignsor_duedate = feedback[0].campaignsor_duedate;
              this.form.campaignsor_uw = feedback[0].campaignsor_uw;
              this.form.campaignsor_uw_desc = feedback[0].campaignsor_uw_desc;
              this.form.campaignsor_acceptedby =
                feedback[0].campaignsor_acceptedby;
              this.form.campaignsor_accepteddate =
                feedback[0].campaignsor_accepteddate;
              this.form.campaignsor_sharepct = feedback[0].campaignsor_sharepct;
              this.form.campaignsor_shareamt = feedback[0].campaignsor_shareamt;
              this.form.campaignsor_sharetsi = feedback[0].campaignsor_sharetsi;
              this.form.campaignsor_periodterm =
                feedback[0].campaignsor_periodterm;
              this.form.campaignsor_installment =
                feedback[0].campaignsor_installment;
              this.form.campaignsor_ujrohfee = feedback[0].campaignsor_ujrohfee;
              this.form.campaignsor_ujrohcedant =
                feedback[0].campaignsor_ujrohcedant;
              this.form.campaignsor_refno = feedback[0].campaignsor_refno;
              this.form.campaignsor_wpc = feedback[0].campaignsor_wpc;
              this.form.campaignsor_grace = feedback[0].campaignsor_grace;
              this.form.campaignsor_remarks = feedback[0].campaignsor_remarks;
              this.form.campaignsor_memo = feedback[0].campaignsor_memo;
              // this.form.campaignsor_periodterm              = feedback[0].campaignsor_periodterm
              // this.form.campaignsor_recoverydue             = feedback[0].campaignsor_recoverydue
              // this.form.campaignsor_firstloss               = feedback[0].campaignsor_firstloss
              // this.form.campaignsor_limit                   = feedback[0].campaignsor_limit
              // this.form.campaignsor_inexcess                = feedback[0].campaignsor_inexcess
              // this.form.campaignsor_commpct                 = feedback[0].campaignsor_commpct
              // this.form.campaignsor_commamt                 = feedback[0].campaignsor_commamt
              // this.form.campaignsor_overcommpct             = feedback[0].campaignsor_overcommpct
              // this.form.campaignsor_overcommamt             = feedback[0].campaignsor_overcommamt
              // this.form.campaignsor_originalrate            = feedback[0].campaignsor_originalrate
              // this.form.campaignsor_premadjamt              = feedback[0].campaignsor_premadjamt
              // this.form.campaignsor_premadjpct              = feedback[0].campaignsor_premadjpct
              // this.form.campaignsor_acqcostpct              = feedback[0].campaignsor_acqcostpct
              // this.form.campaignsor_profitpct               = feedback[0].campaignsor_profitpct
              // this.form.campaignsor_pcalc                   = feedback[0].campaignsor_pcalc
              // this.form.campaignsor_proratepct              = feedback[0].campaignsor_proratepct
              // this.form.campaignsor_refundpct               = feedback[0].campaignsor_refundpct
              // this.form.campaignsor_claimcoop               = feedback[0].campaignsor_claimcoop
              // this.form.campaignsor_confirmno               = feedback[0].campaignsor_confirmno
              // this.form.campaignsor_warranty                = feedback[0].campaignsor_warranty
              // this.form.campaignsor_jpslipno                = feedback[0].campaignsor_jpslipno
              // this.form.campaignsor_jpsharepct              = feedback[0].campaignsor_jpsharepct
              // this.form.campaignsor_jpcommpct               = feedback[0].campaignsor_jpcommpct
              // this.form.campaignsor_asoriginalpolf          = feedback[0].campaignsor_asoriginalpolf
              // this.form.campaignsor_allownetrategreaterf    = feedback[0].campaignsor_allownetrategreaterf
              // this.form.campaignsor_notapplydiscountf       = feedback[0].campaignsor_notapplydiscountf
              // this.form.campaignsor_onpremafteroverridingf  = feedback[0].campaignsor_onpremafteroverridingf
              // this.form.campaignsor_noaddrefundf            = feedback[0].campaignsor_noaddrefundf
              // this.form.campaignsor_fullcoveragef           = feedback[0].campaignsor_fullcoveragef
              // this.form.campaignsor_allowpayorduegreaterf   = feedback[0].campaignsor_allowpayorduegreaterf
              // this.form.campaignsor_precedefaccessriskarrf  = feedback[0].campaignsor_precedefaccessriskarrf

              this.$refs.campaignsor_sharepct.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_sharepct;
              this.$refs.campaignsor_shareamt.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_shareamt;
              this.$refs.campaignsor_sharetsi.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_sharetsi;
              this.$refs.campaignsor_installment.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_installment;
              this.$refs.campaignsor_ujrohfee.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_ujrohfee;
              this.$refs.campaignsor_ujrohcedant.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_ujrohcedant;
              this.$refs.campaignsor_wpc.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_wpc;
              this.$refs.campaignsor_grace.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].campaignsor_grace;
              // this.$refs.campaignsor_periodterm.$el.getElementsByTagName('input')[0].value    = feedback[0].campaignsor_periodterm
              // this.$refs.campaignsor_recoverydue.$el.getElementsByTagName('input')[0].value   = feedback[0].campaignsor_recoverydue
              // this.$refs.campaignsor_firstloss.$el.getElementsByTagName('input')[0].value     = feedback[0].campaignsor_firstloss
              // this.$refs.campaignsor_limit.$el.getElementsByTagName('input')[0].value         = feedback[0].campaignsor_limit
              // this.$refs.campaignsor_inexcess.$el.getElementsByTagName('input')[0].value      = feedback[0].campaignsor_inexcess
              // this.$refs.campaignsor_commpct.$el.getElementsByTagName('input')[0].value       = feedback[0].campaignsor_commpct
              // this.$refs.campaignsor_commamt.$el.getElementsByTagName('input')[0].value       = feedback[0].campaignsor_commamt
              // this.$refs.campaignsor_overcommpct.$el.getElementsByTagName('input')[0].value   = feedback[0].campaignsor_overcommpct
              // this.$refs.campaignsor_overcommamt.$el.getElementsByTagName('input')[0].value   = feedback[0].campaignsor_overcommamt
              // this.$refs.campaignsor_originalrate.$el.getElementsByTagName('input')[0].value  = feedback[0].campaignsor_originalrate
              // this.$refs.campaignsor_premadjamt.$el.getElementsByTagName('input')[0].value    = feedback[0].campaignsor_premadjamt
              // this.$refs.campaignsor_premadjpct.$el.getElementsByTagName('input')[0].value    = feedback[0].campaignsor_premadjpct
              // this.$refs.campaignsor_acqcostpct.$el.getElementsByTagName('input')[0].value    = feedback[0].campaignsor_acqcostpct
              // this.$refs.campaignsor_profitpct.$el.getElementsByTagName('input')[0].value     = feedback[0].campaignsor_profitpct
              // this.$refs.campaignsor_proratepct.$el.getElementsByTagName('input')[0].value    = feedback[0].campaignsor_proratepct
              // this.$refs.campaignsor_refundpct.$el.getElementsByTagName('input')[0].value     = feedback[0].campaignsor_refundpct
              // this.$refs.campaignsor_claimcoop.$el.getElementsByTagName('input')[0].value     = feedback[0].campaignsor_claimcoop
              // this.$refs.campaignsor_confirmno.$el.getElementsByTagName('input')[0].value     = feedback[0].campaignsor_confirmno
              // this.$refs.campaignsor_warranty.$el.getElementsByTagName('input')[0].value      = feedback[0].campaignsor_warranty
              // this.$refs.campaignsor_jpsharepct.$el.getElementsByTagName('input')[0].value    = feedback[0].campaignsor_jpsharepct
              // this.$refs.campaignsor_jpcommpct.$el.getElementsByTagName('input')[0].value     = feedback[0].campaignsor_jpcommpct

              this.form.campaignsor_profileid_par =
                feedback[0].campaignsor_profileid;

              this.form.campaignsor_cdate = feedback[0].campaignsor_cdate;
              this.form.campaignsor_cuser = feedback[0].campaignsor_cuser;
              this.form.campaignsor_ldate = feedback[0].campaignsor_ldate;
              this.form.campaignsor_luser = feedback[0].campaignsor_luser;
              this.dialog.campaignsor_profileid = true;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.campaignsordialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.campaignsordialog = false;
        });
    },

    DeleteCampaignSOR(id, profile_id) {
      let formdata = {
        url_type: "deletecampaignsor",
        campaignsor_campaignid: id,
        campaignsor_profileid: profile_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignSOR"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaignsor.selected = [];
              this.ListCampaignSOR(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignSOR() {
      let multiData = JSON.stringify(this.campaignsor.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignSOR"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaignsor.selected = [];
            if (this.url_type === "multideletecampaignsor") {
              this.ListCampaignSOR(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // end: Function Spreading Of Risk

    // Function Campaign Depreciation
    // SelectAllCampaignDepreciation() {
    //   this.campaigndepreciation.selected =
    //     this.campaigndepreciation.selected === this.campaigndepreciation.items
    //       ? []
    //       : this.campaigndepreciation.items;
    // },

    // ListCampaignDepreciation(id) {
    //   this.loading.listcampaigndepreciation = true;
    //   let formdata = {
    //     campaigndepreciation_campaignid: id,
    //     order_by: "campaigndepreciation_campaignid",
    //     order_type: "ASC",
    //     limit: this.limit,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiListCampaignDepreciation"), param, {
    //       headers: {
    //         "Content-Type": "text/plain charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback !== "Y") {
    //           this.SnackBar(true, "error", feedback[0].feedback, 0);
    //         } else {
    //           this.campaigndepreciation.items = feedback;
    //         }
    //       } else {
    //         this.campaigndepreciation.items = feedback;
    //         this.SnackBar(true, "error", this.$functions.NoData(), 0);
    //       }
    //       this.loading.listcampaigndepreciation = false;
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", e, 3000);
    //       this.loading.listcampaigndepreciation = false;
    //     });
    // },

    // CheckCampaignDepreciation(value) {
    //   this.campaigndepreciation.selected = value;
    // },

    // SearchCampaignDepreciation() {
    //   this.campaigndepreciation.searchbutton = true;
    //   setTimeout(function () {
    //     document.getElementById("searchcampaigndepreciation").focus();
    //   }, 500);
    // },

    // OpenCampaignDepreciation() {
    //   this.dialog.campaigndepreciation = true;
    //   this.AddCampaignDepreciation();
    // },

    // AddCampaignDepreciation() {
    //   this.url_type = "addcampaigndepreciation";
    //   this.form.campaigndepreciation_campaignid = "";
    //   this.form.campaigndepreciation_yearnumber = 0;
    //   this.form.campaigndepreciation_pct = 0;
    //   this.form.campaigndepreciation_actived = true;

    //   this.flag.campaigndepreciation = true;
    //   this.dialog.campaigndepreciation = true;

    //   this.$refs.campaigndepreciation_yearnumber.$el.getElementsByTagName(
    //     "input"
    //   )[0].value = 0;
    //   this.$refs.campaigndepreciation_pct.$el.getElementsByTagName(
    //     "input"
    //   )[0].value = 0;
    // },

    // WriteCampaignDepreciation() {
    //   let formdata = {
    //     url_type: this.url_type,
    //     campaigndepreciation_campaignid: this.form.campaign_id,
    //     campaigndepreciation_yearnumber:
    //       this.form.campaigndepreciation_yearnumber,
    //     campaigndepreciation_pct: this.form.campaigndepreciation_pct,
    //     campaigndepreciation_actived: this.$functions.ActivedFlag(
    //       this.form.campaigndepreciation_actived
    //     ),
    //     campaigndepreciation_yearnumber_par:
    //       this.form.campaigndepreciation_yearnumber_par,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiWriteCampaignDepreciation"), param, {
    //       headers: {
    //         "Content-Type": "text/plain; charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback === "Y") {
    //           this.SnackBar(true, "success", "Save Success", 3000);
    //           this.confirm.dialog = false;
    //           this.confirm.text = "Ok";
    //           if (this.url_type === "addcampaigndepreciation") {
    //             this.form.campaigndepreciation_cuser =
    //               feedback[0].feedback_users_id;
    //             this.form.campaigndepreciation_cdate =
    //               feedback[0].feedback_users_date;
    //           }
    //           this.form.campaigndepreciation_luser =
    //             feedback[0].feedback_users_id;
    //           this.form.campaigndepreciation_ldate =
    //             feedback[0].feedback_users_date;
    //           this.url_type = "editcampaigndepreciation";
    //           this.dialog.campaigndepreciation = false;
    //           this.ListCampaignDepreciation(this.form.campaign_id);
    //         } else {
    //           this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //           this.remarks.dialog = true;
    //           this.remarks.feedback = feedback[0].feedback;
    //         }
    //       } else {
    //         this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //         this.remarks.dialog = true;
    //         this.remarks.feedback = feedback;
    //       }
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //     });
    // },

    // EditCampaignDepreciation(id, yearnumber) {
    //   this.flag.campaigndepreciation = true;
    //   this.dialog.campaigndepreciation = true;
    //   this.loading.campaigndepreciationdialog = true;
    //   let formdata = {
    //     campaigndepreciation_campaignid: id,
    //     campaigndepreciation_yearnumber: yearnumber,
    //     order_by: "campaigndepreciation_campaignid",
    //     order_type: "ASC",
    //     limit: this.limit,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiListCampaignDepreciation"), param, {
    //       headers: {
    //         "Content-Type": "text/plain charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback === "Y") {
    //           this.url_type = "editcampaigndepreciation";

    //           this.form.campaigndepreciation_campaignid =
    //             feedback[0].campaigndepreciation_campaignid;
    //           this.form.campaigndepreciation_yearnumber =
    //             feedback[0].campaigndepreciation_yearnumber;
    //           this.form.campaigndepreciation_pct =
    //             feedback[0].campaigndepreciation_pct;
    //           (this.form.campaigndepreciation_actived =
    //             this.$functions.TrueOrFalse(
    //               feedback[0].campaigndepreciation_actived
    //             )),
    //             (this.$refs.campaigndepreciation_yearnumber.$el.getElementsByTagName(
    //               "input"
    //             )[0].value = feedback[0].campaigndepreciation_yearnumber);
    //           this.$refs.campaigndepreciation_pct.$el.getElementsByTagName(
    //             "input"
    //           )[0].value = feedback[0].campaigndepreciation_pct;

    //           this.form.campaigndepreciation_yearnumber_par =
    //             feedback[0].campaigndepreciation_yearnumber;

    //           this.form.campaigndepreciation_cdate =
    //             feedback[0].campaigndepreciation_cdate;
    //           this.form.campaigndepreciation_cuser =
    //             feedback[0].campaigndepreciation_cuser;
    //           this.form.campaigndepreciation_ldate =
    //             feedback[0].campaigndepreciation_ldate;
    //           this.form.campaigndepreciation_luser =
    //             feedback[0].campaigndepreciation_luser;
    //           this.dialog.campaigndepreciation = true;
    //         } else {
    //           this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //           this.remarks.dialog = true;
    //           this.remarks.feedback = feedback[0].feedback;
    //         }
    //       } else {
    //         this.SnackBar(true, "error", feedback, 0);
    //       }
    //       this.loading.campaigndepreciationdialog = false;
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //       this.loading.campaigndepreciationdialog = false;
    //     });
    // },

    // DeleteCampaignDepreciation(id, yearnumber) {
    //   let formdata = {
    //     url_type: "deletecampaigndepreciation",
    //     campaigndepreciation_campaignid: id,
    //     campaigndepreciation_yearnumber: yearnumber,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiWriteCampaignDepreciation"), param, {
    //       headers: {
    //         "Content-Type": "text/plain; charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback.length > 0) {
    //         if (feedback[0].feedback === "Y") {
    //           this.SnackBar(true, "success", "Delete Success", 3000);
    //           this.confirm.dialog = false;
    //           this.confirm.text = "Ok";
    //           this.campaigndepreciation.selected = [];
    //           this.ListCampaignDepreciation(this.form.campaign_id);
    //         } else {
    //           this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //           this.remarks.dialog = true;
    //           this.remarks.feedback = feedback[0].feedback;
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //     });
    // },

    // MultiProcessCampaignDepreciation() {
    //   let multiData = JSON.stringify(this.campaigndepreciation.selected);
    //   let formdata = {
    //     url_type: this.url_type,
    //     multi_data: multiData,
    //   };
    //   let param = this.$functions.ParamPOST(formdata);
    //   this.$axios
    //     .post(this.$functions.UrlPOST("apiWriteCampaignDepreciation"), param, {
    //       headers: {
    //         "Content-Type": "text/plain charset=ISO-8859-1",
    //       },
    //     })
    //     .then((response) => {
    //       let feedback = response.data;
    //       if (feedback[0].feedback === "Y") {
    //         let feed;
    //         if (feedback[0].feedback_failed == 0) {
    //           feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
    //         } else {
    //           this.remarks.feedback = feedback[0].feedback_failed_remarks;
    //           feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
    //           this.remarks.dialog = true;
    //         }
    //         this.SnackBar(true, "success", feed, 0);
    //         this.confirm.dialog = false;
    //         this.confirm.text = "Ok";
    //         this.campaigndepreciation.selected = [];
    //         if (this.url_type === "multideletecampaigndepreciation") {
    //           this.ListCampaignDepreciation(this.form.campaign_id);
    //         }
    //       } else {
    //         this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //         this.remarks.dialog = true;
    //         this.remarks.feedback = feedback[0].feedback;
    //       }
    //     })
    //     .catch((e) => {
    //       this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
    //       this.remarks.dialog = true;
    //       this.remarks.feedback = e;
    //     });
    // },
    // end: Function Campaign Depreciation
    
    // Function Campaign RiskLogic
    SelectAllCampaignRiskLogic() {
      this.campaignrisklogic.selected =
        this.campaignrisklogic.selected === this.campaignrisklogic.items
          ? []
          : this.campaignrisklogic.items;
    },

    ListCampaignRiskLogic(id) {
      this.loading.listcampaignrisklogic = true;
      let formdata = {
        campaignrisklogic_campaignid: id,
        order_by: "campaignrisklogic_campaignid",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCampaignRiskLogic"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log(feedback)
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.campaignrisklogic.items = feedback;
            }
          } else {
            this.campaignrisklogic.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listcampaignrisklogic = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listcampaignrisklogic = false;
        });
    },

    CheckCampaignRiskLogic(value) {
      console.log('Value', value)
      this.campaignrisklogic.selected = value;
    },

    SearchCampaignRiskLogic() {
      this.campaignrisklogic.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcampaignrisklogic").focus();
      }, 500);
    },

    OpenCampaignRiskLogic() {
      this.dialog.campaignrisklogic         = true;

      this.campaignrisklogic_fieldlabel_value  = {}
      this.campaignrisklogic_fieldid_value     = {}
      this.campaignrisklogic_detailid_value    = {}
      this.campaignrisklogic_value             = {}
      this.DetailData()
      this.AddCampaignRiskLogic();
    },

    AddCampaignRiskLogic() {
      this.url_type                           = "addcampaignrisklogic";
      this.form.campaignrisklogic_riskcovid     = '';
      this.form.campaignrisklogic_riskcovdesc   = '';
      this.form.campaignrisklogic_interestid    = '';
      this.form.campaignrisklogic_interestdesc  = '';
      this.form.campaignrisklogic_tsimin        = 0;
      this.form.campaignrisklogic_tsimax        = 0;
      this.form.campaignrisklogic_periodmin     = 0;
      this.form.campaignrisklogic_periodmax     = 0;
      this.form.campaignrisklogic_ratemin       = 0;
      this.form.campaignrisklogic_ratemax       = 0;
      this.form.campaignrisklogic_periodunit    = '';
      this.form.campaignrisklogic_rateunit      = '';
      this.form.campaignrisklogic_actived       = true;

      // this.ClearDetailData()

      this.flag.campaignrisklogic   = true;
      this.dialog.campaignrisklogic = true;

      document.getElementById("campaignrisklogic_riskcovid").value  = "";
      document.getElementById("campaignrisklogic_interestid").value = "";
    },

    WriteCampaignRiskLogic() {
      let riskcov   = document.getElementById('campaignrisklogic_riskcovid').value;
      let interest  = document.getElementById('campaignrisklogic_interestid').value;
      this.campaignrisklogic_objectinfo = []      
			for (let i = 1; i <= 20; i ++) {
				let id            = document.getElementById('refgendet_fieldid_' + i)
				if (id !== null) {
					let idvalue       = id.value
					let valueid       = document.getElementById('gendet_fieldid_' + i).value
					let valuedesc     = document.getElementById('gendet_fielddesc_' + i).value
					this.campaignrisklogic_objectinfo.push({campaignrisklogic_fieldid: idvalue, campaignrisklogic_fieldvalueid: valueid, campaignrisklogic_fieldvaluedesc: valuedesc})
				}
			} 

      let formdata = {
        url_type                      : this.url_type,
        campaignrisklogic_row         : this.form.campaignrisklogic_row,
        campaignrisklogic_campaignid  : this.form.campaign_id,
        campaignrisklogic_riskcovid   : riskcov,
        campaignrisklogic_interestid  : interest,
        campaignrisklogic_tsimin      : String(this.form.campaignrisklogic_tsimin),
        campaignrisklogic_tsimax      : String(this.form.campaignrisklogic_tsimax),
        campaignrisklogic_periodmin   : String(this.form.campaignrisklogic_periodmin),
        campaignrisklogic_periodmax   : String(this.form.campaignrisklogic_periodmax),
        campaignrisklogic_ratemin     : String(this.form.campaignrisklogic_ratemin),
        campaignrisklogic_ratemax     : String(this.form.campaignrisklogic_ratemax),
        campaignrisklogic_periodunit  : this.form.campaignrisklogic_periodunit,
        campaignrisklogic_rateunit    : this.form.campaignrisklogic_rateunit,
        campaignrisklogic_actived     : this.$functions.ActivedFlag(this.form.campaignrisklogic_actived),
        campaignrisklogic_objectinfo  : JSON.stringify(this.campaignrisklogic_objectinfo)
      };
      console.log('Form', formdata)
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignRiskLogic"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addcampaignrisklogic") {
                this.form.campaignrisklogic_cuser = feedback[0].feedback_users_id;
                this.form.campaignrisklogic_cdate = feedback[0].feedback_users_date;
              }
              this.form.campaignrisklogic_luser = feedback[0].feedback_users_id;
              this.form.campaignrisklogic_ldate = feedback[0].feedback_users_date;
              this.url_type = "editcampaignrisklogic";
              this.dialog.campaignrisklogic = false;
              this.ListCampaignRiskLogic(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditCampaignRiskLogic(id, campaignid) {
      this.ClearDetailData()
			this.DetailData()
      this.flag.campaignrisklogic          = true
      this.dialog.campaignrisklogic        = true
      this.loading.campaignrisklogicdialog = true
      let formdata = {
        campaignrisklogic_row         : id,
        campaignrisklogic_campaignid  : campaignid,
        order_by                      : 'campaignrisklogic_row',
        order_type                    : 'ASC',
        limit                         : this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST('apiListCampaignRiskLogic'), param, {
          headers: {
            'Content-Type': 'text/plain charset=ISO-8859-1',
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editcampaignrisklogic";
              this.form.campaignrisklogic_row           = feedback[0].campaignrisklogic_row
              this.form.campaignrisklogic_campaignid    = feedback[0].campaignrisklogic_campaignid
              this.form.campaignrisklogic_riskcovid     = feedback[0].campaignrisklogic_riskcovid
              this.form.campaignrisklogic_riskcovdesc   = feedback[0].campaignrisklogic_riskcovdesc
              this.form.campaignrisklogic_interestid    = feedback[0].campaignrisklogic_interestid
              this.form.campaignrisklogic_interestdesc  = feedback[0].campaignrisklogic_interestdesc
              this.form.campaignrisklogic_tsimin        = feedback[0].campaignrisklogic_tsimin
              this.form.campaignrisklogic_tsimax        = feedback[0].campaignrisklogic_tsimax
              this.form.campaignrisklogic_periodmin     = feedback[0].campaignrisklogic_periodmin
              this.form.campaignrisklogic_periodmax     = feedback[0].campaignrisklogic_periodmax
              this.form.campaignrisklogic_ratemin       = feedback[0].campaignrisklogic_ratemin
              this.form.campaignrisklogic_ratemax       = feedback[0].campaignrisklogic_ratemax
              this.form.campaignrisklogic_periodunit    = feedback[0].campaignrisklogic_periodunit
              this.form.campaignrisklogic_rateunit      = feedback[0].campaignrisklogic_rateunit
              this.form.campaignrisklogic_actived       = this.$functions.TrueOrFalse(feedback[0].campaignrisklogic_actived)

              this.form.campaignrisklogic_cdate = feedback[0].campaignrisklogic_cdate
              this.form.campaignrisklogic_cuser = feedback[0].campaignrisklogic_cuser
              this.form.campaignrisklogic_ldate = feedback[0].campaignrisklogic_ldate
              this.form.campaignrisklogic_luser = feedback[0].campaignrisklogic_luser
              this.dialog.campaignrisklogic = true

              // Object Info
              for (let i = 1; i <= 20; i ++) {
                let field_valueid       = `campaignrisklogic_fieldvalueid_` + i
                let field_valuedesc     = `campaignrisklogic_fieldvaluedesc_` + i
                if (feedback[0][field_valueid] !== '') {
                  this.$set(this.gentab_detailid_value, i, feedback[0][field_valueid])
                  this.$set(this.gentab_detaildesc_value, i, feedback[0][field_valuedesc])
                } else {
                  this.$set(this.gentab_detailid_value, i, '')
                  this.$set(this.gentab_detaildesc_value, i, '')
                }
              }
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.campaignrisklogicdialog = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.campaignrisklogicdialog = false
        })
    },

    DeleteCampaignRiskLogic(id, campaignid) {
      let formdata = {
        url_type: "deletecampaignrisklogic",
        campaignrisklogic_row: id,
        campaignrisklogic_campaignid: campaignid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignRiskLogic"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.campaignrisklogic.selected = [];
              this.ListCampaignRiskLogic(this.form.campaign_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    MultiProcessCampaignRiskLogic() {
      let multiData = JSON.stringify(this.campaignrisklogic.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCampaignRiskLogic"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.campaignrisklogic.selected = [];
            if (this.url_type === "multideletecampaignrisklogic") {
              this.ListCampaignRiskLogic(this.form.campaign_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    DetailData () {
			let formdata = {
				subclass_id: this.form.tablelimit_subclassid,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListSubClassObjectInfo'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback 		 = response.data
				let checking 		 = false
				for (let i = 1; i <= 20; i ++) {
					let field_id    = `subclass_fieldid_` + i
					let field_label = `subclass_fieldlabel_` + i
					if (feedback[0][field_id] !== '') {
						this.$set(this.gendet_fieldid_value, i, feedback[0][field_id])
						this.$set(this.gendet_fieldlabel_value, i, feedback[0][field_label])
						if (feedback[0].subclass_fieldid_1 !== '') {
							checking = true
						}
					}
				}
				// this.DetailValue()
				this.attributes = checking
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
			})
		},

    ClearDetailData () {
			for (let i = 1; i <= 20; i ++) {
				if (document.getElementById('gendet_fielddesc_' + i) !== null) {
				    this.$set(this.gentab_detailid_value, i, '')
				    this.$set(this.gentab_detaildesc_value, i, '')
				}
			}
		},
    // end: Function Campaign Risk Logic

    SnackBar(dialog, color, text, timeout) {
      if (text !== "No Data Available") {
        this.snackbar = {
          dialog: dialog,
          color: color,
          text: text,
          timeout: timeout,
        };
        if (color === "error") {
          this.confirm.dialog = false;
          this.confirm.text = "Ok";
        }
      }
    },
    FormatDateFullName(date) {
      return this.$functions.FormatDateFullName(date);
    },
    GetSubClass(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|")
        this.form.campaign_subclass = splits[0]
        this.form.campaign_subclass_desc = splits[1]
        let ujroh = splits[3] * 1
        this.form.campaign_ujrohcedant = ujroh
        // this.$refs.campaign_ujrohcedant.$el.getElementsByTagName(
        //   "input"
        // )[0].value = ujroh.toFixed(2)
      }
    },
    GetBizSource(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|")
        let tax = splits[3] * 1
        let vat = splits[4] * 1
        this.form.campaignsource_profileid = splits[0]
        this.form.campaignsource_profile_desc = splits[1]
        this.form.campaignsource_tax = tax.toFixed(2)
        this.$refs.campaignsource_tax.$el.getElementsByTagName(
          "input"
        )[0].value = tax.toFixed(2)
        this.form.campaignsource_vat = vat.toFixed(2)
        // this.$refs.campaignsource_vat.$el.getElementsByTagName(
        //   "input"
        // )[0].value = vat.toFixed(2)
      }
    },
    GetPholder(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let names = splits[1];
        let address = splits[3];
        let zipcode = splits[4];
        this.form.campaign_deliveryreceiptname = names;
        this.form.campaign_payoraddress = address;
        this.form.campaign_deliveryaddress = address;
        this.form.campaign_deliveryzipcode = zipcode;
      }
    },
    GetDeductible(value) {
      this.loading.campaigndeductibledialog = true;
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let deductible_id = splits[0];

        // Call Back API Deductible
        let formdata = {
          deductible_id: deductible_id,
          order_by: "deductible_id",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListDeductible"), param, {
            headers: {
              "Content-Type": "text/plain charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.form.campaignded_withflag = this.$functions.YesOrNo(
                  feedback[0].deductible_withflag
                );
                this.form.campaignded_ccy = feedback[0].deductible_ccy;
                this.form.campaignded_forindemnity =
                  feedback[0].deductible_forindemnity;
                this.form.campaignded_tsisipct =
                  feedback[0].deductible_tsisipct;
                this.form.campaignded_tsisiflag =
                  feedback[0].deductible_tsisiflag;
                this.form.campaignded_claimpct =
                  feedback[0].deductible_claimpct;
                this.form.campaignded_amountmin =
                  feedback[0].deductible_amountmin;
                this.form.campaignded_amountmax =
                  feedback[0].deductible_amountmax;
                this.form.campaignded_remarkseng =
                  feedback[0].deductible_remarkseng;
                this.form.campaignded_remarksind =
                  feedback[0].deductible_remarksind;
                this.form.campaignded_totallossflag = this.$functions.YesOrNo(
                  feedback[0].deductible_totallossflag
                );
                this.form.campaignded_actived = this.$functions.YesOrNo(
                  feedback[0].deductible_actived
                );

                this.$refs.campaignded_tsisipct.$el.getElementsByTagName(
                  "input"
                )[0].value = feedback[0].deductible_tsisipct;
                this.$refs.campaignded_claimpct.$el.getElementsByTagName(
                  "input"
                )[0].value = feedback[0].deductible_claimpct;
                this.$refs.campaignded_amountmin.$el.getElementsByTagName(
                  "input"
                )[0].value = feedback[0].deductible_amountmin;
                this.$refs.campaignded_amountmax.$el.getElementsByTagName(
                  "input"
                )[0].value = feedback[0].deductible_amountmax;

                // this.dialog.campaigndeductible = true
              } else {
                this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
                this.remarks.dialog = true;
                this.remarks.feedback = feedback[0].feedback;
              }
            } else {
              this.SnackBar(true, "error", feedback, 0);
            }
            this.loading.campaigndeductibledialog = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = e;
            this.loading.campaigndeductibledialog = false;
          });
      }
    },
    GetTC(value) {
      this.loading.campaigntcdialog = true;
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        let classtc_id = splits[0];

        // Call Back API Class TC
        let formdata = {
          classtc_id: classtc_id,
          order_by: "classtc_id",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListClassTC"), param, {
            headers: {
              "Content-Type": "text/plain charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.form.campaigntc_id = feedback[0].classtc_id;
                this.form.campaigntc_classid = feedback[0].classtc_classid;
                this.form.campaigntc_shortdesc_eng =
                  feedback[0].classtc_shortdesc_eng;
                this.form.campaigntc_longdesc_eng =
                  feedback[0].classtc_longdesc_eng;
                this.form.campaigntc_shortdesc_ind =
                  feedback[0].classtc_shortdesc_ind;
                this.form.campaigntc_longdesc_ind =
                  feedback[0].classtc_longdesc_ind;
              } else {
                this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
                this.remarks.dialog = true;
                this.remarks.feedback = feedback[0].feedback;
              }
            } else {
              this.SnackBar(true, "error", feedback, 0);
            }
            this.loading.campaigntcdialog = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = e;
            this.loading.campaigntcdialog = false;
          });
      }
    },

    // Get Data Select Box From API List Code
    GetListCode(value) {
      let formdata = {
        code_group: value,
        order_by: "code_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListCode"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              if (value === "CAMPAIGNFLAG") {
                this.campaign_flag = feedback;
              } else if (value === "PAYMENTMETHOD") {
                this.campaign_paymentmethod = feedback;
              }
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    ShowUsers(id) {
      this.dialog.users = true;
      this.form.display_users = id;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>
