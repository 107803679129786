<template>
  <div id="app-attachment-detail">
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <div v-if="access.read === 0" class="text-center font-weight-black">
      {{ $vars.V("txt_no_access") }}
    </div>
    <div v-else>
      <v-card-title class="ml-n4">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          @click:append="btn_search = false"
          label="Search"
          single-line
          hide-details
          v-if="btn_search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        @input="Check($event)"
        item-key="attachment_id"
        class="elevation-1"
        :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        dense
        :loading="loading.detail"
        hide-default-header
        loading-text="Loading... Please wait"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
                class="secondary white--text"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.attachment_path="{ item }">
          <v-icon @click="$functions.OpenURL(item.attachment_path_url)"
            >download</v-icon
          >
        </template>
      </v-data-table>
    </div>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      absolute
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    modul: String,
    refno: String,
  },
  data: () => ({
    url_type: "add",
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },

    loading: {
      page: true,
      detail: true,
    },
    dialog: {
      detail: false,
    },
    properties: {
      reverse: true,
    },
    selected: [],
    items: [],
    btn_search: true,
    search: "",
    headers: [
      { text: "ID", value: "attachment_id" },
      { text: "FILE", value: "attachment_title", width: "70%" },
      { text: "Extention", value: "attachment_ext" },
      { text: "Path", value: "attachment_path" },
      //   { text: "Remarks", value: "attachment_remarks" },
      // { text: "Actions", value: "action", sortable: false },
    ],
    form: {
      attachment_id: "",
      attachment_modul: "",
      attachment_refno: "",
      attachment_title: "",
      attachment_title_rules: [(v) => !!v || "Please fill Document No"],
      attachment_remarks: "",
      attachment_remarks_rules: [(v) => !!v || "Please fill Remarks"],
      attachment_ext: "",
      attachment_path: "",
      attachment_path_url: "",
      attachment_path_name: "",
      attachment_type: "",
      attachment_actived: true,
    },
    detail: {
      attachmenttype: [],
    },
  }),
  created() {
    this.List(this.refno, this.modul);
  },
  methods: {
    Access() {
      let modul = "attachment";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },

    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.form.attachment_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      }
    },
    List(id, modul) {
      this.Access();
      this.items = [];
      if (id !== "") {
        this.loading.detail = true;
        let formdata = {
          attachment_refno: id,
          attachment_modul: modul,
          order_by: "attachment_refno",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListAttachment"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback !== "Y") {
                this.SnackBar(true, "error", feedback[0].feedback, 0);
              } else {
                this.items = feedback;
              }
            } else {
              this.items = feedback;
              // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.detail = false;
          })
          .catch((e) => {
            this.SnackBar(true, "error", e, 3000);
            this.loading.detail = false;
          });
      }
    },
  },
};
</script>
