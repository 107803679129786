<template>
  <div id="dashboard">
    <app-drawer_menu :drawer_menu_title="form.title"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.listpolicy"
    ></v-progress-linear>
    <app-policy-search
      v-if="$route.meta.module === 'quotation'"
      :dialog="quotationsearch.dialog"
      :search_title="quotationsearch.title"
      :src_id="quotationsearch.id"
      @close="quotationsearch.dialog = false"
      @clicked="GetSearch"
    ></app-policy-search>
    <v-container>
      <v-row>
        <v-col
          v-for="(card, index) in statusCards"
          :key="index"
          cols="12"
          sm="4"
        >
          <v-card
            :class="['rounded-lg', card.bgClass]"
            elevation="3"
            :style="{
              borderLeft: `4px solid ${card.borderColor}`,
              cursor: 'pointer',
            }"
            @click="ListPolicy(card.statustype, card.status)"
          >
            <v-card-text class="pa-10">
              <div class="d-flex justify-space-between align-center">
                <div>
                  <div class="text-subtitle-1 font-weight-medium mb-1">
                    {{ card.title }}
                  </div>
                  <div class="text-h4 font-weight-bold">{{ card.count }}</div>
                </div>
                <v-avatar :color="card.avatarColor" size="65">
                  <v-icon large :color="card.iconColor">{{ card.icon }}</v-icon>
                </v-avatar>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      item-key="policy_id"
      class="elevation-1 mt-10"
      :expanded.sync="expanded"
      :single-expand="true"
      @click:row="toggleExpand"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-tabs
            v-model="tabs"
            background-color="white"
            color="secondary"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            class="mt-5"
          >
            <v-tab id="tabreinsurance" href="#tab-reinsurance">
              Reinsurance
            </v-tab>
            <v-tab id="attachment" href="#tab-attachment"> Attachment </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs" class="mb-5">
            <v-tab-item value="tab-reinsurance">
              <app-reinsurance-detail :header-color="headerColor" />
            </v-tab-item>
            <v-tab-item value="tab-attachment">
              <app-attachment-detail
                modul="reinsattach"
                :value="true"
                :refno="form.ticket_id"
              />
            </v-tab-item>
          </v-tabs-items>
        </td>
      </template>
      <template v-slot:item.policy_cedant="{ item }">
        {{ item.policy_cedant }} - {{ item.policy_cedant_desc }}
      </template>
      <template v-slot:item.policy_cdate="{ item }">
        {{ $functions.FormatDateFull(item.policy_cdate) }}
      </template>
      <template v-slot:item.policy_sla="{ item }">
        {{ CalculateSLA(item.policy_deliverysenddate, item.policy_cdate) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-row class="align-center">
          <v-col class="d-flex align-center">
            <div
              :title="'Send'"
              :style="{
                cursor: access.edit === 0 ? 'not-allowed' : 'pointer',
              }"
              class="icon-wrapper icon-action v-icon v-icon--link"
            >
              <svg
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
                class="custom-svg"
              >
                <path
                  id="paper-plane-icon"
                  d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z
          M202.992,332.528v124.517l58.738-67.927L202.992,332.528z"
                ></path>
              </svg>
            </div>
            <span
              :title="'Confirm'"
              :style="{
                cursor: access.edit === 0 ? 'not-allowed' : 'pointer',
                fontSize: '25px',
                color: access.edit === 0 ? 'gray' : 'green',
              }"
              class="mdi mdi-check-circle ml-2 icon-action"
            ></span>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm.text === 'Ok' ? false : true"
          >
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProductionDashboard",
  data: () => ({
    tabs: null,
    loading: { listpolicy: false },
    form: { title: "Production" },
    confirm: { dialog: false, title: "", text: "Ok" },
    quotationsearch: { dialog: false, title: "", id: "" },
    access: { read: 0, add: 0, edit: 0, delete: 0 },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "policy_quotation_no",
      },
      { text: "Campaign", value: "policy_campaign_desc" },
      { text: "Sub Class", value: "policy_subclass_desc" },
      { text: "Cedant", value: "policy_cedant" },
      { text: "Transaction Date", value: "policy_cdate" },
      { text: "Marketing", value: "policy_mo_desc" },
      { text: "Days", value: "policy_sla" },
      { text: "Status", value: "policy_deliverystatusdesc" },
      { text: "Actions", value: "action", sortable: false },
    ],
    items: [],
    expanded: [],
    statusCards: [
      {
        title: "Unsend",
        count: "1",
        status: "W",
        statustype: "N",
        bgClass: "custom-bg-unsend",
        borderColor: "#f44336",
        avatarColor: "#fdd3d3a1",
        iconColor: "red",
        icon: "mdi-email-alert-outline",
      },
      {
        title: "Send",
        count: "2",
        status: "S",
        statustype: "N",
        bgClass: "custom-bg-send",
        borderColor: "#2196f3",
        avatarColor: "#d4effc",
        iconColor: "blue",
        icon: "mdi-email-check",
      },
      {
        title: "Confirm",
        count: "10",
        status: "C",
        statustype: "N",
        bgClass: "custom-bg-confirm",
        borderColor: "#4caf50",
        avatarColor: "#e1f1e1",
        iconColor: "green",
        icon: "mdi-check-decagram-outline",
      },
    ],
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
  }),
  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      this.Access();
    },
    Access() {
      const modul = "policy";
      const formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      const param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: { "Content-Type": "text/plain; charset=ISO-8859-1" },
        })
        .then((response) => {
          const priv = response.data;
          if (priv.length > 0) {
            Object.assign(this.access, {
              read: priv[0].usersmenu_read,
              add: priv[0].usersmenu_add,
              edit: priv[0].usersmenu_edit,
              delete: priv[0].usersmenu_delete,
            });
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          console.error(e);
          this.SnackBar(true, "error", e.message, 0);
          this.loading.page = false;
        });
    },
    GetSearch() {
      // Implement GetSearch method
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ListPolicy(policy_statustype, policy_status) {
      this.loading.listpolicy = true;
      let formdata = {
        policy_statustype: policy_statustype,
        policy_status: policy_status,
        order_by: "policy_row",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListPolicy"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              console.log(feedback[0]);
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.policy.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.listpolicy = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.listpolicy = false;
        });
    },
    CalculateSLA(deliveryDate, policyCDate) {
      console.log(deliveryDate);
      const today = new Date();
      const policyDate = new Date(policyCDate); // Parse policy_cdate
      let diffDays;

      // Check if deliveryDate is "1991-01-01"
      if (deliveryDate === "1900-01-01") {
        // If it's "1991-01-01", calculate the difference with today's date
        diffDays = Math.ceil((today - policyDate) / (1000 * 60 * 60 * 24));
      } else {
        // Otherwise, calculate the difference between deliveryDate and policyDate
        const deliverySendDate = new Date(deliveryDate); // Parse policy_deliverysenddate
        diffDays = Math.ceil(
          (deliverySendDate - policyDate) / (1000 * 60 * 60 * 24)
        );
      }
      return `${diffDays}`; // Return the difference in days
    },
    GetDataPolicy(policy_status) {
      // Simulated API call
      this.loading.page = true;
      setTimeout(() => {
        const data = Array.from({ length: 5 }, (_, i) => ({
          policy_id: Math.floor(1000 + Math.random() * 9000).toString(),
          policy_campaignid: `Campaign ${i + 1}`,
          policy_subclass: `Subclass ${i + 1}`,
          policy_cedant: `Cedant ${i + 1}`,
          policy_transaction_date: new Date(
            2023,
            Math.floor(Math.random() * 12),
            Math.floor(Math.random() * 28) + 1
          ).toLocaleDateString("id-ID", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }),
          policy_mo: `MO ${i + 1}`,
          policy_sla: Math.floor(1 + Math.random() * 6).toString(),
          policy_status:
            policy_status === "U"
              ? "Unsend"
              : policy_status === "S"
              ? "Send"
              : "Confirm",
          attachments: [
            { name: "Document 1", size: "1.2 MB", type: "PDF" },
            { name: "Document 2", size: "2.5 MB", type: "PDF" },
          ],
        }));
        this.items = data;
        this.loading.page = false;
      }, 1500);
    },
    toggleExpand(item) {
      const index = this.expanded.indexOf(item);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
  },
};
</script>

<style scoped>
.custom-bg-unsend {
  background-color: #fef2f2;
}

.custom-bg-send {
  background-color: #e6f7ff;
}

.custom-bg-confirm {
  background-color: #edf7ed;
}
.custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-svg {
  width: 15px;
  height: 20px;
  fill: rgb(255, 255, 255);
}
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; /* Sesuaikan ukuran sesuai kebutuhan */
  height: 20px; /* Sesuaikan ukuran sesuai kebutuhan */
  background-color: red;
  border-radius: 100%;
  padding: 2px;
}
.icon-action:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
</style>
