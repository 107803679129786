<template>
  <div>
      <v-dialog
          v-model="dialogsearch"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
      >
      <v-card>
          <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click.stop="SearchClose()">
              <v-icon>{{$vars.V('icon_back')}}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ search_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              outlined
              @click="SearchGet"
          >
              Search Data
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
          </v-btn>
          </v-toolbar>
          <v-container grid-list-md class="pa-12">
          <v-layout row wrap v-if="search_title === 'claim'">
              <template>
                <v-flex xs4>
                <v-text-field
                    v-model="claim.no"
                    id="claim_no"
                    prepend-inner-icon="list_alt"
                    label="Claim No"
                    autofocus
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
                <v-flex xs4>
                <v-text-field
                    v-model="claim.policyno"
                    id="claim_policyno"
                    prepend-inner-icon="list_alt"
                    label="Certificate No"
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
                <v-flex xs4>
                <v-text-field
                    v-model="claim.refno"
                    id="claim_refno"
                    prepend-inner-icon="list_alt"
                    label="Reference No"
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
                <v-flex xs12>
                <v-text-field
                    v-model="claim.reportname"
                    id="claim_reportname"
                    prepend-inner-icon="person"
                    label="Report Name"
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
              </template>
              <v-flex xs12>
              <v-data-table
                  :headers="header[search_title]"
                  :items="items[search_title]"
                  :loading="loading.claim"
                  loading-text="Loading... Please wait"
                  dense
                  item-key="dataid"
                  class="elevation-1 row-pointer"
              >
                  <template slot="headerCell" slot-scope="props">
                  <v-tooltip bottom>
                      <span slot="activator">
                      {{ props.header.text }}
                      </span>
                      <span>
                      {{ props.header.text }}
                      </span>
                  </v-tooltip>
                  </template>
                  <template v-slot:no-data> No Data Available </template>
                  <template v-slot:item.action="{ item }">
                    <small><u @click="CBGet(item.claim_row)" class="cursor">edit</u></small>
                  </template>
              </v-data-table>
              </v-flex>
          </v-layout>
          <v-layout row wrap v-else-if="search_title === 'policy'">
              <template>
                <v-flex xs4>
                <v-text-field
                    v-model="policy.no"
                    id="policy_no"
                    prepend-inner-icon="list_alt"
                    label="Certificate No"
                    autofocus
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
                <v-flex xs4>
                <v-text-field
                    v-model="policy.certificate"
                    id="policy_certificateno"
                    prepend-inner-icon="list_alt"
                    label="No"
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
                <v-flex xs4>
                <v-text-field
                    v-model="policy.refno"
                    id="policy_refno"
                    prepend-inner-icon="list_alt"
                    label="Reference No"
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
                <v-flex xs12>
                <v-text-field
                    v-model="policy.insured"
                    id="policy_insured"
                    prepend-inner-icon="person"
                    label="Insured Name"
                    @keyup.enter="SearchGet"
                >
                </v-text-field>
                </v-flex>
              </template>
              <v-flex xs12>
              <v-data-table
                  :headers="header[search_title]"
                  :items="items[search_title]"
                  :loading="loading.policy"
                  loading-text="Loading... Please wait"
                  dense
                  item-key="dataid"
                  class="elevation-1 row-pointer"
              >
                  <template slot="headerCell" slot-scope="props">
                  <v-tooltip bottom>
                      <span slot="activator">
                      {{ props.header.text }}
                      </span>
                      <span>
                      {{ props.header.text }}
                      </span>
                  </v-tooltip>
                  </template>

                  <template v-slot:no-data> No Data Available </template>
                  <template v-slot:item.action="{ item }">
                    <small><u @click="CBGet(item.policy_row)" class="cursor">edit</u></small>
                  </template>
                  <template v-slot:item.policy_campaign="{ item }">
                    {{item.policy_campaign}} - {{item.policy_campaign_desc}}
                  </template>
                  <template v-slot:item.policy_subclass="{ item }">
                      {{item.policy_subclass}} - {{item.policy_subclass_desc}}
                  </template>
              </v-data-table>
              </v-flex>
          </v-layout>
          </v-container>
      </v-card>
      </v-dialog>
      <v-snackbar
      :value	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
      style 	  ="z-index: 9999;"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
  dialog: Boolean,
  search_title: String,
  src_id: String,
},
data: () => ({
  limit: '1000',
  header: {
    policy: [
      { text: "Action", value: "action"},
      {
        text: "Row",
        value: "policy_row",
        align: "left",
      },
      { text: "Certificate No", value: "policy_no" },
      { text: "No", value: "policy_certificateno" },
      { text: "Reference No", value: "policy_refno" },
      { text: "Insured", value: "policy_longinsured" },
      { text: "Campaign", value: "policy_campaign" },
      { text: "Sub Class", value: "policy_subclass" },
    ],
    claim: [
      { text: "Action", value: "action"},
      {
        text: "Row",
        value: "claim_row",
        align: "left",
      },
      { text: "Claim No", value: "claim_no" },
      { text: "Certificate No", value: "claim_policyno" },
      { text: "Number No", value: "claim_certificateno" },
      { text: "Reference No", value: "claim_refno" },
      { text: "Insured", value: "claim_longinsured" },
    ],
  },
  items: [],
  loading: {
    policy: false,
    claim: false,
  },
  claim: {
    no: '',
    policyno: '',
    refno: '',
    reportname: '',
  },
  policy: {
    no: '',
    certificate: '',
    refno: '',
    insured: '',
  },
  snackbar: {
    dialog: false,
    color: 'success',
    text: '',
    timeout: 3000
  },
}),
//   created() {
  
//   },
computed: {
  dialogsearch: {
      get() {
          return this.dialog
      },
      set(value) {
          if (!value) {
              this.$emit('close')
          }
      }
  }
},
methods: {
  SearchClose() {
      this.dialogsearch = false
  },
  SearchGet() {
    if (this.search_title === 'policy') {
      this.loading.policy = true
      let formdata = {
        policy_no: this.policy.no,
        policy_certificateno: this.policy.certificate,
        policy_refno: this.policy.refno,
        policy_insured: this.policy.insured,
        order_by: "policy_row",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicy"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.policy = false
              this.items[this.search_title] = feedback
          } else {
             this.loading.policy = false
             this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.policy = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    } else if (this.search_title === 'claim') {
      this.loading.claim = true
      let formdata = {
        claim_no: this.claim.no,
        claim_policyno: this.claim.policyno,
        claim_refno: this.claim.refno,
        claim_reportname: this.claim.reportname,
        order_by: "claim_row",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListClaim"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.claim = false
              this.items[this.search_title] = feedback
          } else {
             this.loading.claim = false
             this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.claim = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    }
  },
  CBGet(value) {
      this.$emit(
      "clicked",
      value
    );
    this.SearchClose()
  },
  SnackBar(dialog, color, text, timeout) {
    this.snackbar = {
      dialog: dialog,
      color: color,
      text: text,
      timeout: timeout,
    }
    if (color === "error") {
      this.confirm.dialog = false
      this.confirm.text = "Ok"
    }
  },
},
};
</script>